(function() {

	'use strict';
	
	angular.module('FYN').controller('ListController', function($window, $rootScope, $state, $scope, $filter, $timeout, ListService) {


		$rootScope.pageTitle = 'Get Inspired!';
		$scope.activeCat = 'all';
		$scope.filtersActive = false;

		$scope.lists = {
			entries: null,
			total: null
		};

		$scope.mapView.on = false;



		if($state.is('inspiration-mobile')) {
			var mobileCatList = document.getElementById('catlist');
			mobileCatList.addEventListener('change', function() {
				var c = this.options[this.selectedIndex].value;
				$scope.listFilter(c);
			});
		}


		$scope.listFilter = function(cat, $event) {

			$scope.filtersActive = true;

			$event = $event || undefined;

			if($event) {
				angular.element('.catButton').removeClass('active');
				angular.element($event.currentTarget).closest('li').addClass('active');

			}


			
			if(cat === $scope.activeCat) { return false; }

			$scope.activeCat = cat;

			$scope.loading = true;
			if(cat == 'all') {
				$scope.filtersActive = false;
				$scope.inspiration = true;
				ListService.getLists().then(function(res) {
					$scope.lists.entries = res.data.lists;
					$scope.lists.total = res.data.total;
					if($scope.lists.entries.length === $scope.lists.total) {
						$scope.loadmoretext = false;
					}
					else {
						$scope.loadmoretext = 'Load More';
					}
					$scope.loading = false;
				});
			}
			else {
				$scope.inspiration = false;
				ListService.getListsByCategory(cat).then(function(res) {


					$scope.listCategory = res.data[0].list_category_slug;

					$scope.listDescription = res.data[0].list_category_description;
					$scope.lists.entries = res.data[0].lists;
					$scope.lists.total = res.data.total;
					if($scope.lists.entries.length === $scope.lists.total) {
						$scope.loadmoretext = false;
					}
					else {
						$scope.loadmoretext = 'Load More';	
					}
					$scope.loading = false;
				});				
			}
		}
		if($state.is('inspiration') || $state.is('inspiration-mobile')) {
			$scope.loading = true;
			$scope.inspiration = true;
			ListService.getLists().then(function(res) {
				$scope.lists.entries = res.data.lists;
				$scope.lists.total = res.data.total;
				if($scope.lists.entries.length === $scope.lists.total) {
					$scope.loadmoretext = false;
				}
				else {
					$scope.loadmoretext = 'Load More';	
				}			
				$scope.loading = false;
			});
		}
		if($state.is('inspiration-lists') || $state.is('inspiration-lists-mobile')) {
			$scope.loading = true;



			ListService.getListsByCategory($state.params.slug).then(function(res) {
				$scope.listCategory = res.data[0].list_category_slug;
				$scope.listDescription = res.data[0].list_category_description;
				$scope.lists.entries = res.data[0].lists;
				$scope.lists.total = res.data.total;
				if($scope.lists.entries.length === $scope.lists.total) {
					$scope.loadmoretext = false;
				}
				else {
					$scope.loadmoretext = 'Load More';	
				}
				$scope.loading = false;
			});
		}	


		var skip = 6;
		var take = 6;
		
		$scope.loadMore = function() {
			// Make sure we are not still trying to load the initial result set
			// or triggering the load again before it returns
			if($scope.loading || $scope.gettingLists) {
				return false;
			}
			if(skip >= $scope.lists.total) {
				return false;
			}

			$scope.gettingLists = true;
			ListService.getMoreLists(skip,take).then(function(response) {
				response.data.forEach(function(value) {
					$scope.lists.entries.push(value);
				});		
				skip += take;
				$scope.gettingLists = false;
			});
		}
	})
})();