(function() {
	'use strict';
	angular.module('FYN').controller('HomeController', function($timeout, $window, $location, $rootScope, $scope, $state) {

		$scope.mapView.on = false;
		$rootScope.mapView = false;
		$rootScope.pageTitle = 'Discover Your New Orleans';

		$scope.$on('$viewContentLoaded', function(event) {
			$window.ga('send', 'pageview', { page: $location.path() });
			//$window._gaq.push(['_trackPageView', $location.url()]);
		});



		// $timeout(function() {
		// 	scaleVideoContainer();
		// 	initBannerVideoSize('.video-container .poster img');
		// 	initBannerVideoSize('.video-container .filter');
		// 	initBannerVideoSize('.video-container video');
		// }, 100)
		//
		// angular.element(window).on('resize', function() {
		// 	scaleVideoContainer();
		// 	scaleBannerVideoSize('.video-container .poster img');
		// 	scaleBannerVideoSize('.video-container .filter');
		// 	scaleBannerVideoSize('.video-container video');
		// });
		//
		// function scaleVideoContainer() {
		// 	var height = angular.element(window).height() + 5;
		// 	var unitHeight = parseInt(height) + 'px';
		// 	angular.element('.homepage-hero-module').css('height',unitHeight);
		// }
		// function initBannerVideoSize(element){
		// 	angular.element(element).each(function(v){
		// 		angular.element(v).data('height', angular.element(v).height());
		// 		angular.element(v).data('width', angular.element(v).width());
		// 	});
		// 	scaleBannerVideoSize(element);
		// }
		// function scaleBannerVideoSize(element){
		// 	var windowWidth = angular.element(window).width(),
		// 		windowHeight = angular.element(window).height() + 5,
		// 		videoWidth,
		// 		videoHeight;
		//
		//     angular.element(element).each(function(v) {
		//         var videoAspectRatio = angular.element(v).data('height') / angular.element(v).data('width');
		//         angular.element(v).width(windowWidth);
		//         if(windowWidth < 1000) {
		// 			videoHeight = windowHeight;
		//             videoWidth = videoHeight / videoAspectRatio;
		//             angular.element(v).css({'margin-top' : 0, 'margin-left' : -(videoWidth - windowWidth) / 2 + 'px'});
		//             angular.element(v).width(videoWidth).height(videoHeight);
		//         }
		//         angular.element('.homepage-hero-module .video-container video').addClass('fadeIn animated');
		//
		//     });
		// }




	});

})();
