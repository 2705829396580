(function() {
	'use strict';
	angular.module('FYN').controller('ProfileController', function($scope,$state,$location,AuthService) {

		if(!AuthService.check()) {
			$location.path('/').search('login=true&ref=foo');
			
		}

	})
})();