(function() {
	'use strict';
	angular.module('FYN').controller('DataController', function($rootScope, $scope, $q, $timeout, $state, $location, $sce, $window, $auth, GeoLocationService, ResultsService, AccommodationsService, NightlifeService, AttractionService, ShoppingService, AmenitiesService, NeighborhoodService, ListService, AuthService) {



		// This is the main scope pipeline
		$scope.data = {
			locale: {},
			user: {},
			likes: {},
			neighborhoods: {},
			singleNeighborhood: {},
			feeds: {
				inspiration: {},
				instagram: {},
				tags: {}
			},
			categories: {},
			
			results: {},
			deals: {},
			markers: []
		};


		$scope.mapView = {};

		

		AmenitiesService.all().then(function(response) {
			$scope.data.amenities = response.data;
		});

		ResultsService.getCuisineTypes().then(function(response) {
			$scope.data.cuisineTypes = response.data;
		})

		ShoppingService.getShoppingTypes().then(function(response) {
			$scope.data.shoppingTypes = response.data;
		});

		AttractionService.getAttractionTypes().then(function(response) {
			$scope.data.attractionTypes = response.data;
		});

		NightlifeService.getNightlifeFeatures().then(function(response) {
			$scope.data.nightlifeFeatures = response.data;
		});

		AccommodationsService.getAccommodationTypes().then(function(response) {
			$scope.data.accommodationTypes = response.data;
		});





		
	


		$scope.clipListDescription = function(d) {

			var maxLength = 125;

			d = d || undefined;
			
			if(!d) { return };
			
			if(d.length < 125) { return d; }

			else { return d.substring(0,125)+'...';	}
			
		}





		$scope.geolocation = true;
		$scope.locationKnown = false;
		var ssl = location.protocol === 'https';
		if(ssl && navigator.geolocation) {
			var options = {
				enableHighAccuracy: false,
				timeout: 5000,
				maximumAge: 0
			};
			function success(pos) {
				var crd = pos.coords;
				$scope.$apply(function() {
					$scope.data.locale.latitude = crd.latitude;
					$scope.data.locale.longitude = crd.longitude;
					$scope.isLocal = true;
					$scope.locationKnown = true;
				});
			};
			function error(err) {
				console.warn('ERROR(' + err.code + '): ' + err.message);
			};
			navigator.geolocation.watchPosition(success, error, options);
		}




		NeighborhoodService.all().then(function(response) {
			$scope.data.neighborhoods = response.data;
		});


		ListService.getCategories().then(function(response) {	
			$scope.data.categories = response.data;
		})

		ListService.getLists().then(function(response) {
			
			$scope.data.feeds.inspiration = response.data.lists;
		});
		ListService.getTags().then(function(response) {
			$scope.data.feeds.tags = response.data;
		});


		$scope.isAuthenticated = function() {
			return $auth.isAuthenticated();
		}
		// Will prevent user and likes objects from getting nulled on refresh
		// Maybe localstorage?
		if($scope.isAuthenticated()) {
			AuthService.getLoggedInUser().then(function(res) {
				$scope.data.user = res.data.user;
				$scope.data.likes = res.data.likes;
			});
		}
		$rootScope.tableView = true;
	});
})();
