(function() {

	'use strict';

	angular.module('FYN').controller('NewsletterController', function($window,$scope,NewsletterService) {

		$scope.newsletter = {};
		$scope.buttontext = 'SIGN UP';
		$scope.error = false;
		$scope.zipcode = false;

		$scope.signupNoZipCode = function() {
			var email = $scope.newsletter.email;
			var zipcode = '';
			NewsletterService.signup(email, zipcode).then(function(res) {
				$window.ga('send','event','Newsletter','Signup');
				switch(res.data.status) {
					case 'showNewsletterThankYou':
					$scope.showNewsletterThankYou();
				}
			});
		}

		$scope.setZipCode = function() {
			var email = $scope.newsletter.email;
			var zipcode = $scope.newsletter.zipcode;
			var pattern = /^\d{5}$/;
			var message = {
				invalidZipCode: 'Please enter a 5 digit zip code'
			}
			if(!pattern.test(zipcode)) {
				$scope.error = true;
				$scope.errorMessage = message.invalidZipCode;
				return false;
			}
			NewsletterService.signup(email, zipcode).then(function(res) {
				// Send the event to Google Analytics
				$window.ga('send','event','Newsletter','Signup');

				switch(res.data.status) {
					case 'showNewsletterThankYou':
					$scope.showNewsletterThankYou();
				}
			});
		}


		$scope.showNewsletterInitial = true;
		$scope.showNewsletterThankYou = function() {
			$scope.showNewsletterInitial = false;
			$scope.newsletterZipCode = false;
			$scope.newsletterThankYou = true;
		}
		$scope.showNewsletterZipCode = function() {
			$scope.showNewsletterInitial = false;
			$scope.newsletterZipCode = true;
		}
		$scope.signup = function() {
			var email = $scope.newsletter.email;
			var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			var messages = {
				invalidEmail: 'PLEASE ENTER A VALID EMAIL ADDRESS',
				alreadySubscribed: 'THANK YOU FOR SUBSCRIBING',
				notSubscribed: 'YOU ARE IN THE SYSTEM BUT NOT YET SUBSCRIBED',
				unique: 'SUCCESSFUL ENTRY'
			};
			if(!pattern.test(email)) {
				$scope.error = true;
				$scope.errorMessage = messages.invalidEmail;
				return false;
			}
			else {
				$scope.error = false;
				$scope.showNewsletterZipCode();
				return false;

				$scope.buttontext = 'WORKING...';
				NewsletterService.signup(email).then(function(res) {
					$window.ga('send','event','Newsletter','Signup');
					switch(res.data.status) {
						case 'showNewsletterThankYou':
						$scope.showNewsletterThankYou();
						break;

						case 'showNewsletterZipCode':
						$scope.showNewsletterZipCode();
						break;
					}
				});
			}
		};
	})
})();
