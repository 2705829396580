(function() {
	angular.module('FYN').controller('InspirationController', function($window, $rootScope, $state, $filter, $location, $scope,$http,AuthService,ListService) {
		$rootScope.pageTitle = 'Discover Your New Orleans: Get inspiration from these featured journeys';
		$scope.mapView.on = false;
		$rootScope.mapView = false;
		$scope.lists = true;

		$scope.$on('$viewContentLoaded', function(event) {
			$window.ga('send', 'pageview', { page: $location.path() });
		});



		
		

		$scope.loading = true;
		ListService.getListsByCategory($state.params.slug).then(function(res) {
			$scope.foolists = res.data;
			$scope.loading = false;
		});





/*		ListService.getCategories().then(function(res) {
			$scope.listCategories = res.data;
		});
*/


		/*


		$scope.videoIds = [
			'#nightowlNolaVideo',
			'#familyNolaVideo',
			'#highlifeNolaVideo'
		];
		$scope.videoinfo = {
			family: {
				title: 'The Family NOLA',
				description: 'Discover a New Orleans thats fun for the whole family'
			},
			highlife: {
				title: 'The Highlife NOLA',
				description: 'Get a taste of a dressed-up New Orleans'
			},
			nightowl: {
				title: 'The Nightowl NOLA',
				description: 'See where the night takes you in New Orleans'
			}
		};


		var nightlifeIframe = angular.element('#nightowlNolaVideo')[0];
		var familyIframe = angular.element('#familyNolaVideo')[0];
		var highlifeIframe = angular.element('#highlifeNolaVideo')[0];

		$scope.nplayer = $f(nightlifeIframe);
		$scope.fplayer = $f(familyIframe);
		$scope.hplayer = $f(highlifeIframe);

		$scope.video = {};

		$scope.nplayer.addEvent('ready', function() {
			$scope.nplayer.addEvent('play', function(data) {
				$scope.$apply(function() {
					$scope.video.playing = true;	
				});
			});
			$scope.nplayer.addEvent('pause', function(data) {
				$scope.$apply(function() {
					$scope.video.playing = false;	
				})
			});
			$scope.nplayer.addEvent('finish', function(data) {
				$scope.$apply(function() {
					$scope.video.playing = false;	
				});
			});
		});	

		$scope.fplayer.addEvent('ready', function() {
			$scope.fplayer.addEvent('play', function(data) {
				$scope.$apply(function() {
					$scope.video.playing = true;	
				});
			});
			$scope.fplayer.addEvent('pause', function(data) {
				$scope.$apply(function() {
					$scope.video.playing = false;	
				})
			});
			$scope.fplayer.addEvent('finish', function(data) {
				$scope.$apply(function() {
					$scope.video.playing = false;	
				});
			});

		});		

		$scope.hplayer.addEvent('ready', function() {
			$scope.hplayer.addEvent('play', function(data) {
				$scope.$apply(function() {
					$scope.video.playing = true;	
				});
			});
			$scope.hplayer.addEvent('pause', function(data) {
				$scope.$apply(function() {
					$scope.video.playing = false;	
				})
			});
			$scope.hplayer.addEvent('finish', function(data) {
				$scope.$apply(function() {
					$scope.video.playing = false;	
				});
			});

		});	
		*/	

	});

})();