(function() {

	'use strict';
	angular.module('FYN').controller('UIController', function($anchorScroll,$rootScope,$scope,$auth,$timeout,$state,$location,$window,AuthService,NeighborhoodService,ResultsService,ListService,Upload,toastr) {

		//angular.element('body').bind('touchstart touchend', function() {});


		$scope.hostname = location.hostname;
		$scope.user = {
			status: null,
			likes: null,
			data: null
		};
		$scope.menu = {
			open: false
		}
		$scope.stay = {};
		$scope.eat = {
			restaurantName: ''
		};
		$scope.externalUrl = function(url) {
			window.open(url, '_blank');
		}
		$scope.back = function() { history.back(); }
		
		$scope.dropdownResponsive = [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5
				}
			},
			{
				breakpoint: 980,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			}
		];
		$scope.changeSearchButton;
		$scope.mobileSearchForm = false;
		$scope.toggleMobileSearch = function() {
			$scope.mobileSearchForm = !$scope.mobileSearchForm;
		}
		if($window.innerWidth < 992) {
			$scope.changeSearchButton = true;
		} else {
			$scope.changeSearchButton = false;
		}
		angular.element($window).bind('resize', function() {
			if($window.innerWidth < 992) {
				$scope.$apply(function() {
					$scope.changeSearchButton = true;
					$scope.showNavSearchError = false;
				})
			} else {
				$scope.$apply(function() {
					$scope.changeSearchButton = false;
					$scope.mobileSearchFormActive = false;
				})
			}
		});
		$scope.defaultPlaceholderValue = 'Name';

		$scope.clearInputOnFocus = function($event) {
			if($event.currentTarget.value === 'Name') {
				$event.currentTarget.value = ''
			}
			if($scope.restaurantName === 'Name') {
				$scope.restaurantName = '';
			}
		}

		$scope.returnValueInputOnBlur = function($event) {
			if($event.currentTarget.value === '') {
				$event.currentTarget.value = 'Name'
			} else {
				return false;
			}
			if($scope.restaurantName === '') {
				$scope.restaurantName = 'Name';
			} else {
				return false;
			}
		}

		$scope.setOverlay = function($event) {
			if($scope.overlay) {
				return false;
			} else {
				$scope.overlay = true;
			}
		}
		$scope.hideOverlay = function() {
			$scope.overlay = false;
		}
		$scope.overlay = false;
		$scope.toggleOverlay = function($event) {
			if($scope.overlay) {
				$scope.overlay = false;
			}
			else {
				$scope.overlay = true;
			}
		}
		$scope.scrollToTop = function() {
			$anchorScroll();
		}
		$scope.reset = {};
		$scope.state = {};

		$scope.neighborhoodPanel = false;
		$scope.getInspiredPanel = false;
		$scope.panels = {
			mapPanel: false
		}
		$scope.mapSubPanel = false;
		$scope.mapShowing = {};
		$scope.role = {};
		$scope.mobileNav = false;
		$scope.animateIn = false;
		$scope.forceClosePanels = function() {
			$scope.hideOverlay();
		}
		$scope.closeMobileNav = function() {
			if($scope.mobileNav) {
				$scope.toggleMobileNav();
			}
		}
		$scope.toggleMobileNav = function($event) {
			$scope.mobileNav = !$scope.mobileNav;
			angular.element('.toggle-nav').toggleClass('fa-bars fa-close');
			//angular.element('html').toggleClass('lock');
			$timeout(function() {
				$scope.animateIn = !$scope.animateIn;
			});
			$scope.menu.open = !$scope.menu.open;
		}


		$scope.goFromNav = function(state) {
			$state.go(state);
			$timeout(function() {
				$scope.toggleMobileNav();
			}, 250)
		}





		function preventDefault(e) {
			e.preventDefault();
			e.stopPropagation();
		}
		function stopPropagation(e) {
			e.stopPropagation();
		}



		$scope.lockContent = function() {

			angular.element('html').addClass('lock');

/*			body.addEventListener('touchmove', preventDefault, false);
			mobileListing.addEventListener('touchmove', stopPropagation, false);
*/			

		}

		$scope.unlockContent = function() {

/*			var body = document.body;
			var mobileListing = document.getElementById('mobileOverlay');
*/
			angular.element('html').removeClass('lock');
/*			body.removeEventListener('touchmove', preventDefault, false);
			mobileListing.removeEventListener('touchmove', stopPropagation, false);
*/		}

		$scope.toggleMainMapSubPanel = function() {
			$scope.showMainMapSubPanel = !$scope.showMainMapSubPanel;
		}
		$scope.showSubPanel = function(elem) {
			angular.element(elem).addClass('active');
		}
		$scope.hideSubPanel = function(elem) {
			$scope.enablePanel();
			angular.element(elem).removeClass('active');
			angular.element('.map-panel-items li').removeClass('active');
		}
		$scope.showChildPanel = function(elem) {
			angular.element(elem).addClass('active');
		}
		$scope.hideAllChildPanels = function() {
			angular.element('.child-panel').removeClass('active');
		}
		$scope.hideAllSubPanels = function() {
			angular.element('.child-panel').css('display','block');
		}
		$scope.makeSubPanelSelectionActive = function(elem) {
			angular.element('.map-panel-items li').removeClass('active');
			angular.element(elem).addClass('active');
		}
		$scope.toggleChildPanel = function(elem) {
			var el = angular.element(elem);
			el.toggleClass('active');
			if($scope.overlay) {
				$scope.hideOverlay();
			} else {
				$scope.setOverlay();
			}
		}
		$scope.clearMapSubMenu = function() {
			$scope.hideSubPanel('.panel-maps');
			$scope.hideAllChildPanels();
			$scope.hideOverlay();
		}
		$scope.disablePanel = function() {
			$('.map-panel-items li').each(function() {
				if( $(this).hasClass('active') ) {

				} else {
					$(this).css('pointer-events','none');
				}
			})
			$scope.panelDisabled = true;
		}
		$scope.enablePanel = function() {
			$('.map-panel-items li').each(function() {
				$(this).css('pointer-events','auto');
			});
			$scope.panelDisabled = false;
		}
		$scope.refineSearch = function() {
			$scope.setOverlay();
			var st = $state.current.name;
			$scope.showSubPanel('.panel-maps');
			$scope.makeSubPanelSelectionActive('.'+st+'MapItem');
			$scope.showChildPanel('.'+st);
			$scope.disablePanel();
			angular.element('.map-panel-items li.active').bind('mouseenter', function(e) {
				$scope.enablePanel();
			});
		}


/*		$scope.showMapSubPanel = function($event) {
			$scope.setOverlay($event);
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.customMapItem').addClass('active');
			$scope.mapSubPanel = true;

		}
		$scope.toggleMapSubPanel = function($event) {
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.customMapItem').addClass('active');
			$scope.mapSubPanel = !$scope.mapSubPanel;
		}
		$scope.removeActiveClass = function(cname) {
			angular.element(cname).removeClass('active');
		}
*/

/*		$scope.showStaySubPanel = function($event) {
			$scope.setOverlay($event);
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.stayMapItem').addClass('active');
			$scope.staySubPanel = true;
		}
		$scope.toggleStaySubPanel = function($event) {
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.stayMapItem').addClass('active');
			$scope.staySubPanel = !$scope.staySubPanel;
		}
*/

		$scope.showEatSubPanel = function($event) {
			$scope.setOverlay($event);
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.eatMapItem').addClass('active');
			$scope.eatSubPanel = true;
		}
		$scope.toggleEatSubPanel = function($event) {
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.eatMapItem').addClass('active');
			$scope.eatSubPanel = !$scope.eatSubPanel;
		}


		$scope.showDrinkSubPanel = function($event) {
			$scope.setOverlay($event);
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.drinkMapItem').addClass('active');
			$scope.drinkSubPanel = true;
		}
		$scope.toggleDrinkSubPanel = function($event) {

			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.drinkMapItem').addClass('active');
			$scope.drinkSubPanel = !$scope.drinkSubPanel;
		}


		$scope.showAttractionsSubPanel = function($event) {
			$scope.setOverlay($event);
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.attractionsMapItem').addClass('active');
			$scope.attractionsSubPanel = true;
		}
		$scope.toggleAttractionsSubPanel = function($event) {
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.attractionsMapItem').addClass('active');
			$scope.attractionsSubPanel = !$scope.attractionsSubPanel;
		}


		$scope.showShoppingSubPanel = function($event) {
			$scope.setOverlay($event);
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.shoppingMapItem').addClass('active');
			$scope.shoppingSubPanel = true;
		}
		$scope.toggleShoppingSubPanel = function($event) {
			angular.element('.map-panel-items li').removeClass('active');
			angular.element('.shoppingMapItem').addClass('active');
			$scope.shoppingSubPanel = !$scope.shoppingSubPanel;
		}


		$scope.toggleAmenitiesSelectList = function($event) {
			$event.stopPropagation();
			$scope.amenitiesSelectList = !$scope.amenitiesSelectList;
		}

		$scope.hideAmenitiesSelectList = function() {
			$scope.amenitiesSelectList = false;
		}


		$scope.cuisineTypeSelectList = false;
		$scope.toggleCuisineTypeSelectList = function() {
			$scope.cuisineTypeSelectList = !$scope.cuisineTypeSelectList;
		}
		$scope.hideCuisineTypeSelectList = function() {
			$scope.cuisineTypeSelectList = false;
		}





		$scope.hideMapSubPanel = function() {
			$scope.mapSubPanel = false;
		}
		$scope.hideStaySubPanel = function() {
			$scope.staySubPanel = false;
		}
		$scope.hideEatSubPanel = function() {
			$scope.eatSubPanel = false;
		}
		$scope.hideDrinkSubPanel = function() {
			$scope.drinkSubPanel = false;
		}
		$scope.hideAttractionsSubPanel = function() {
			$scope.attractionsSubPanel = false;
		}
		$scope.hideShoppingSubPanel = function() {
			$scope.shoppingSubPanel = false;
		}

		$scope.hideInspiredSubPanel = function() {
			$scope.inspiredSubPanel = false;
		}


		$scope.amenitiesArray = [];
		$scope.amenitiesPlaceholder = 'Any Amenities';

		$scope.setAmenities = function() {
			($scope.amenitiesArray.length ? $scope.amenities = $scope.amenitiesArray.join('-') : $scope.amenities = 'all-amenities');
		}



		$scope.addAmenity = function($event) {

			var value = $event.currentTarget.name;
			var index = $scope.amenitiesArray.indexOf(value)
			if($scope.amenitiesArray.indexOf(value) != -1) {
				$scope.amenitiesArray.splice(index, 1);
			}
			else {
				$scope.amenitiesArray.push(value)
			}

			if($scope.amenitiesArray.length) {
				$scope.amenitiesPlaceholder = $scope.amenitiesArray.length+' option(s) selected';
			}
			else {
				$scope.amenitiesPlaceholder = 'Any Amenities';
			}
			$scope.setAmenities();
		}





		$scope.cuisineTypesArray = [];
		$scope.cuisineTypesPlaceholder = 'Any';

		$scope.setCuisineTypes = function() {
			(!$scope.cuisineTypesArray.length ? $scope.eat.cuisineTypes = 'all-cuisine-types' : $scope.eat.cuisineTypes = $scope.cuisineTypesArray.join('-'));
		}
		$scope.addCuisineType = function($event) {
			var value = $event.currentTarget.name;
			var index = $scope.cuisineTypesArray.indexOf(value)
			if($scope.cuisineTypesArray.indexOf(value) != -1) {
				$scope.cuisineTypesArray.splice(index, 1);
			} else {
				$scope.cuisineTypesArray.push(value)
			}
			if($scope.cuisineTypesArray.length) {
				$scope.cuisineTypesPlaceholder = $scope.cuisineTypesArray.length+' option(s) selected';
			}
			else {
				$scope.cuisineTypesPlaceholder = 'Any';
			}
			$scope.setCuisineTypes();
		}


		$scope.amenities = 'all-amenities';
		$scope.createStayUrl = function($event) {
			if($event.currentTarget[0].value === 'Name') {
				var nameInputValue = '';
			}
			else {
				var nameInputValue = $event.currentTarget[0].value.trim();
			}
			var params = {
				//name: $event.currentTarget[0].value.trim(),
				name: nameInputValue,
				lodgingtype: $event.currentTarget[1].value.trim(),
				neighborhood: $event.currentTarget[2].value.trim(),
				amenities: $scope.amenities
			}
			$state.go('stay', {
				lodgingtype: params.lodgingtype,
				neighborhood: params.neighborhood,
				amenities: params.amenities,
				name: params.name
			});
		}
		$scope.createMobileStayUrl = function($event) {

			if($event.currentTarget[0].value === 'Name') {
				var nameInputValue = '';
			}
			else {
				var nameInputValue = $event.currentTarget[0].value.trim();
			}

			var params = {
				name: nameInputValue,
				lodgingtype: $event.currentTarget[1].value.trim(),
				neighborhood: $event.currentTarget[2].value.trim(),
				amenities: 'all-amenities'
			}
			$state.go('stay-mobile', {
				lodgingtype: params.lodgingtype,
				neighborhood: params.neighborhood,
				amenities: params.amenities,
				name: params.name
			});
		}




		$scope.eat.when = 'any-time';
		$scope.eat.attire = 'any-attire';
		$scope.eat.neighborhood = 'all-neighborhoods';
		$scope.eat.cuisineTypes = 'all-cuisine-types';
		$scope.eat.pricerange = 'all-price-ranges';


		$scope.notAnytime = [];
		$scope.setWhen = function($event) {
			$event.preventDefault();
			var when = $event.currentTarget.dataset.when;

			if(when === 'any-time') {
				$scope.eat.when = ['any-time'];
				$scope.notAnytime.length = 0;
				angular.element('.whenBtn').removeClass('active');
				angular.element($event.currentTarget).toggleClass('active');
			}

			else {
				angular.element('.anyTime').removeClass('active');
				angular.element($event.currentTarget).toggleClass('active');
				if($scope.notAnytime.indexOf(when) !=-1) {
					var index = $scope.notAnytime.indexOf(when);
					$scope.notAnytime.splice(index, 1);
				} else {
					$scope.notAnytime.push(when);
				}

				if($scope.notAnytime.length == 0) {
					angular.element('.anyTime').addClass('active');
					$scope.eat.when = ['any-time'];
				} else {
					$scope.eat.when = $scope.notAnytime;
				}
			}
			return false;
		}



		$scope.createEatUrl = function($event) {
			var pricerange = $scope.eat.pricerange ? $scope.eat.pricerange : 'all-price-ranges';
			if($scope.eat.restaurantName === 'Name') {
				$scope.eat.restaurantName = '';
			}

			var params = {
				name: $scope.eat.restaurantName,
				cuisinetype: $scope.eat.cuisineTypes,
				attire: $scope.eat.attire,
				neighborhood: $scope.eat.neighborhood,
				pricerange: $scope.eat.pricerange,
				when: $scope.eat.when.toString().replace(new RegExp(",", "g"),'+')
			}
			$state.go('eat', {
				neighborhood: params.neighborhood,
				cuisinetype: params.cuisinetype,
				attire: params.attire,
				pricerange: params.pricerange,
				when: params.when,
				name: params.name
			});
		}


		$scope.createMobileEatUrl = function($event) {
			var pricerange = $scope.eat.pricerange ? $scope.eat.pricerange : 'all-price-ranges';
			if($scope.eat.restaurantName === 'Name') {
				$scope.eat.restaurantName = '';
			}
			var params = {
				name: $scope.eat.restaurantName,
				cuisinetype: $scope.eat.cuisineTypes,
				attire: $scope.eat.attire,
				neighborhood: $scope.eat.neighborhood,
				pricerange: $scope.eat.pricerange,
				when: $scope.eat.when.toString().replace(new RegExp(",", "g"),'+')
			}
			$state.go('eat-mobile', {
				neighborhood: params.neighborhood,
				cuisinetype: params.cuisinetype,
				attire: params.attire,
				pricerange: params.pricerange,
				when: params.when,
				name: params.name
			});
		}



		$scope.createDrinkUrl = function($event) {
			if($event.currentTarget[0].value === 'Name') {
				var nameInputValue = '';
			}
			else {
				var nameInputValue = $event.currentTarget[0].value.trim();
			}

			var params = {
				name: nameInputValue,
				neighborhood: $event.currentTarget[1].value.trim(),
				features: $event.currentTarget[2].value.trim()
			}
			$state.go('drink', { neighborhood: params.neighborhood, features: params.features, name: params.name });
		}

		$scope.createMobileDrinkUrl = function($event) {
			var params = {
				name: $event.currentTarget[0].value.trim(),
				neighborhood: $event.currentTarget[1].value.trim(),
				features: $event.currentTarget[2].value.trim()
			}
			$state.go('drink-mobile', { neighborhood: params.neighborhood, features: params.features, name: params.name });
		}




		$scope.createAttractionsUrl = function($event) {
			if($event.currentTarget[0].value === 'Name') {
				var nameInputValue = '';
			}
			else {
				var nameInputValue = $event.currentTarget[0].value.trim();
			}

			var params = {
				name: nameInputValue,
				neighborhood: $event.currentTarget[1].value.trim(),
				features: $event.currentTarget[2].value.trim()
			}
			$state.go('attractions', { neighborhood: params.neighborhood, features: params.features, name: params.name });
		}
		$scope.createMobileAttractionsUrl = function($event) {
			if($event.currentTarget[0].value === 'Name') {
				var nameInputValue = '';
			}
			else {
				var nameInputValue = $event.currentTarget[0].value.trim();
			}

			var params = {
				name: nameInputValue,
				neighborhood: $event.currentTarget[1].value.trim(),
				features: $event.currentTarget[2].value.trim()
			}
			$state.go('attractions-mobile', { neighborhood: params.neighborhood, features: params.features, name: params.name });
		}




		$scope.createShoppingUrl = function($event) {
			if($event.currentTarget[0].value === 'Name') {
				var nameInputValue = '';
			}
			else {
				var nameInputValue = $event.currentTarget[0].value.trim();
			}
			var params = {
				name: nameInputValue,
				neighborhood: $event.currentTarget[1].value.trim(),
				shoppingtypes: $event.currentTarget[2].value.trim()
			}
			$state.go('shopping', { neighborhood: params.neighborhood, shoppingtypes: params.shoppingtypes, name: params.name });
		}
		$scope.createMobileShoppingUrl = function($event) {

			var params = {
				name: $event.currentTarget[0].value.trim(),
				neighborhood: $event.currentTarget[1].value.trim(),
				shoppingtypes: $event.currentTarget[2].value.trim()
			}
			$state.go('shopping-mobile', {
				neighborhood: params.neighborhood,
				shoppingtypes: params.shoppingtypes,
				name: params.name
			});
		}






		$scope.countActiveSelections = function() {
			var count = angular.element('.child-panel.custom .active').length;
			return parseInt(count);
		}

		$scope.countActiveMobileSelections = function() {
			var count = angular.element('.mapSubPanel li.active').length;
			return parseInt(count);
		}




		$scope.selectedTags = [];
		$scope.locationsFromTags = [];


		$scope.createCustomNola = function() {
			var tags = $scope.selectedTags.toString();
			var concatTags = tags.replace(new RegExp(',', 'g'), '+');
			if($state.is('map-form-custom')) {
				$state.go('map-custom-mobile', { tags: concatTags });
				$scope.selectedTags = [];
			}
			else {
				$state.go('map-custom', { tags: concatTags });
			}
		}

		$scope.clicks = 0;
		$scope.mapSubNavDisabled = true;



		$scope.toggleActive = function($event) {
			var elem = angular.element($event.currentTarget);
			if(elem.hasClass('active')) {
				var index = $scope.selectedTags.indexOf(elem.data('listslug').trim());
				$scope.selectedTags.splice(index, 1);
			}
			else {
				$scope.selectedTags.push(elem.data('listslug'));
			}
			angular.element($event.currentTarget).toggleClass('active');

			if($scope.countActiveSelections() >= 3 || $scope.countActiveMobileSelections() >=3) {
				$scope.mapSubNavDisabled = false;
			}
			else {
				$scope.mapSubNavDisabled = true;
			}
			$scope.countActiveSelections();
		}





		$scope.clearFilters = function(form) {
			switch(form) {
				case 'stay':
					document.querySelector('.stay-form-name').value = 'Name';
					document.querySelector('.stay-form-lodging-type').selectedIndex = 0;
					document.querySelector('.stay-form-neighborhood').selectedIndex = 0;
					$scope.amenitiesArray = [];
					$('.multipleSelectList ul li input[type=checkbox]').each(function(i,v) {

					})
					$scope.amenitiesPlaceholder = 'Any Amenities';
				break;

				case 'eat':
					document.querySelector('.eat-form-restaurant-name').value = 'Name';
					document.querySelector('.eat-form-style').selectedIndex = 0;
					document.querySelector('.eat-form-price-range').selectedIndex = 0;
					document.querySelector('.eat-form-neighborhood').selectedIndex = 0;
					$('.whenBtn').each(function(i,v) {
						$(this).removeClass('active');
					});
					$('.anyTime').addClass('active');

					angular.forEach(angular.element('.eat-form-cuisine-type'), function(value, index) {
						value.checked = false;
					});
					$scope.cuisineTypesPlaceholder = 'Any';

					$scope.eat.restaurantName = 'Name';
					$scope.eat.attire = 'any-attire';
					$scope.eat.pricerange = 'all-price-ranges';
					$scope.eat.cuisineTypes = 'all-cuisine-types';
					$scope.eat.neighborhood = 'all-neighborhoods';
					$scope.eat.when = 'any-time';
				break;

				case 'drink':
					document.querySelector('.drink-form-name').value = 'Name';
					document.querySelector('.drink-form-neighborhood').selectedIndex = 0;
					document.querySelector('.drink-form-features').selectedIndex = 0;
				break;

				case 'attractions':
					document.querySelector('.attractions-form-name').value = 'Name';
					document.querySelector('.attractions-form-neighborhood').selectedIndex = 0;
					document.querySelector('.attractions-form-features').selectedIndex = 0;
				break;

				case 'shopping':
					document.querySelector('.shopping-form-name').value = 'Name';
					document.querySelector('.shopping-form-neighborhood').selectedIndex = 0;
					document.querySelector('.shopping-form-features').selectedIndex = 0;
				break;
			}
		}











		$scope.clearAll = function() {
			angular.element('.koo').removeClass('active');
			$scope.mapSubNavDisabled = true;
		}


		// Should make a new way to do this
		$scope.closeAllModals = function() {
			$scope.loginDialog = false;
			$scope.setFixed();
			$scope.registerDialog = false;
			$scope.passwordDialog = false;
			$scope.termsDialog = false;
			$scope.privacyDialog = false;
			$scope.copyrightDialog = false;
			$scope.reset.resetDialog = false;
			$scope.handleDialog = false;
			$scope.categoryDialogActive = false;

			$scope.unlockContent();
		}

		$scope.openTermsDialog = function() {
			$scope.closeAllModals();
			$scope.termsDialog = true;
			$scope.lockContent();
		}
		$scope.openPrivacyDialog = function() {
			$scope.closeAllModals();
			$scope.privacyDialog = true;
			$scope.lockContent();
		}
		$scope.openCopyrightDialog = function() {
			$scope.closeAllModals();
			$scope.copyrightDialog = true;
			$scope.lockContent();
		}

		$scope.openPasswordModal = function() {
			$scope.closeAllModals();
			$scope.passwordDialog = true;
			$scope.lockContent();

		}
		$scope.openLoginModal = function() {
			$scope.closeAllModals();
			$scope.loginDialog = true;
			//$scope.lockContent();
		}
		$scope.openRegisterModal = function() {
			$scope.closeAllModals();
			$scope.registerDialog = true;
			$scope.lockContent();
		}
		$scope.openResetModal = function() {
			$scope.closeAllModals();
			$scope.resetDialog = true;
			$scope.lockContent();
		}

		$scope.openHandleDialog = function() {
			$scope.closeAllModals();
			$scope.handleDialog = true;
			$scope.lockContent();
		}



		$scope.goToState = function(state, params) {
			$scope.neighborhoodPanel = false;
			$state.go(state, params);
		}


		$scope.closePanel = function($event) {
			$scope.neighborhoodPanel = false;
		}

		$scope.closeOnClick = function($event) {
			angular.element($event.currentTarget).removeClass('active');
			$timeout(function() {
				angular.element($event.currentTarget).show();
			})
		}


		$scope.testToast = function() {
			toastr.success('THIS IS A TEST', {
				iconClass: 'toast-blue',
				timeOut: 60000
			});
		}




		$scope.waitThenClosePanel = function() {
			$timeout(function() {
				$scope.$apply(function() {
					$scope.neighborhoodPanel = false;
				})

			},500)
		}

		NeighborhoodService.all().then(function(res) {
			$scope.neighborhoods = res.data;
			$scope.dataLoaded = true;
		});

		ListService.getLists().then(function(res) {
			$scope.lists = res.data;
		});

		$scope.neighborhoodPanelOver = function() {
			$scope.neighborhoodPanel = true;
		}
		$scope.neighborhoodPanelOff = function() {
			$timeout(function() {
				$scope.$apply(function() {
					$scope.neighborhoodPanel = false;
				})

			});
		}






/*		$scope.imageFile = '';
		$scope.uploadPic = function (listid, file) {

			Upload.upload({
				url: '/api/upload',
				data: {
					file: file,
					listid: listid,
					'username': $scope.username
				}
			})
			.then(function (resp) {
				$scope.imageFile = file[0].name;
				toastr.success('Image Updated', {
					iconClass: 'toast-blue'
				});
			},
			// error
			function (resp) {
			},
			function (evt) {
				var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
			});
		};
*/

		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
			event.preventDefault();
			$scope.state.current = toState.name;
		});


		$scope.getCurrentState = function() {
			return $state.current;
		}



		var date = new Date();
		$scope.currentYear = date.getFullYear();


		$scope.panels = {};

		$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
			$scope.mobileSearchFormActive = false;
			$('.tms').removeClass('fa-close').addClass('fa-search');
			// do something
		});

		$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
				$state.current = toState;
			}
		);


		$scope.authGateway = function(event) {
			if(!AuthService.check()) {
				event.preventDefault();
				$scope.openLoginModal();
			}
			else {
				return;
			}
		}


		$scope.closeMobileSearch = function() {
			$scope.mobileSearchFormActive = false;
			$scope.mobileShowNavSearchError = false;
			$('.tms').removeClass('fa-close').addClass('fa-search');
		}




		$scope.toggleMobileSearchForm = function($event) {
			$('.tms').toggleClass('fa-search fa-close');
			$scope.mobileSearchFormActive = !$scope.mobileSearchFormActive;
		}



		$scope.searchterm = {};
		$scope.showNavSearchError = false;

		$scope.broadSearch = function(tablet) {

			var tablet = tablet || undefined;
			if(!$scope.searchterm.query) {
				if(tablet) {
					$scope.mobileShowNavSearchError = true;
				}
				else {
					$scope.showNavSearchError = true;
				}
				return false;
			}
			$scope.mobileSearchForm = false;
			$scope.showNavSearchError = false;
			$scope.mobileShowNavSearchError = false;
			$state.go('search', { term: $scope.searchterm.query });
		}


		$scope.mobileSearchError;



		$scope.toggleMobileSearchDialog = function() {
			$scope.mobileSearchActive = ! $scope.mobileSearchActive;
		}






		$scope.mobileSearch = function() {
			var term = $scope.searchterm.query;
			if(term) {
				$scope.toggleMobileSearchDialog();
				$scope.mobileSearchError = false;
				$state.go('search-mobile', { term: $scope.searchterm.query });
			}
			else { $scope.mobileSearchError = 'Please enter a term to search';	}
		}


		$scope.setDestination = function(destination) {
			$scope.destination = destination;
		}






		/*

		| Shitty fix for Android mobile soft keyboards getting in the way
		| of my fixed position elements.

		*/
		var ua = navigator.userAgent.toLowerCase();
		var isAndroid = ua.indexOf("android") > -1;
		$scope.setAbsolute = function($event) {
			if(isAndroid) {
				var elem = angular.element($event.currentTarget);
				$('.loginDialog, .registerDialog, .forgotDialog').css({
					'overflow' : 'scroll'
				});
			}
			else {
				return;
			}

		}
		$scope.setFixed = function() {
			if(isAndroid) {
				$('.loginDialog, .registerDialog, .forgotDialog').css({
					'overflow' : 'auto'
				});
			}
			else {
				return;
			}
		}







	});
})();
