(function() {

	'use strict';

	//angular.module('FYN').controller('NeighborhoodController', function($rootScope, $scope, $filter, $interval, $timeout, $state, NeighborhoodService,AdminService,leafletData,leafletBoundsHelpers) {
	angular.module('FYN').controller('NeighborhoodController', function(
	$rootScope,
	$scope,
	$filter,
	$interval,
	$timeout,
	$state,
	$window,
	$location,
	NeighborhoodService,
	AdminService
	//leafletData,
	//leafletBoundsHelpers
	){




		$scope.mapView.on = false;
		$rootScope.mapView = false;
		$scope.bricks;

		$scope.$on('$viewContentLoaded', function(event) {
			$window.ga('send', 'pageview', { page: $location.path() });
			//$window._gaq.push(['_trackPageView', $location.url()]);
		});



		if($state.is('singleNeighborhood') || $state.is('singleNeighborhood-mobile')) {

			//NeighborhoodService.banner($state.params.slug).then(function(response) {
				//$scope.headerimage = response.data[0].FYN_BannerImage;

				$scope.slug = $state.params.slug.replace(/-/g, '');
				$scope.newSlug = $state.params.slug;

				$scope.getCardData = function(slug) {
					NeighborhoodService.getCardData(slug).then(function(response) {
						response.data.forEach(function(value) {
							if(value.Category ==='stay') { $scope.stayCardData = value; }
							if(value.Category ==='eat') { $scope.eatCardData = value; }
							if(value.Category ==='drink') { $scope.drinkCardData = value; }
							if(value.Category ==='attractions') { $scope.attractionsCardData = value; }
							if(value.Category ==='shopping') { $scope.shoppingCardData = value; }
							if(value.Category ==='livemusic') { $scope.livemusicCardData = value; }
							if(value.Category ==='itineraries') { $scope.itinerariesCardData = value; }
							if(value.Category ==='annualevents') { $scope.annualeventsCardData = value; }
							if(value.Category ==='notablestreets') { $scope.notablestreetsCardData = value; }
							if(value.Category ==='history') { $scope.historyCardData = value; }
							if(value.Category ==='architecture') { $scope.architectureCardData = value; }
							if(value.Category ==='transportation') { $scope.transportationCardData = value; }
						});
					});
				};
				$scope.getCardData($scope.newSlug);
				$scope.getBrickImages = function(slug) {
					NeighborhoodService.getBrickImages(slug).then(function(response) {
						$scope.bricks = response.data;
					});
				};
				$scope.getBrickImages($scope.newSlug);
				$scope.loading = true;
				$scope.foo = true;

				NeighborhoodService.getListingCounts($state.params.slug).then(function(response) {
					$scope.listingCounts = response.data;
					$scope.$watch('data.neighborhoods', function(neighborhoods) {
						angular.forEach($scope.data.neighborhoods, function(v) {
							if(v.FYN_Slug === $state.params.slug) {
								$scope.response = v;
								$scope.loading = false;
								$timeout(function() {
									$rootScope.$broadcast('masonry.reload');
								}, 1000)
							}
						});
						$rootScope.pageTitle = 'Discover Your New Orleans: '+$scope.response.Neighborhood;
					});
				});
			//})
		}







		$scope.lightConnectedNeighborhoods = function() {

			angular.element('.connected').css({
				'opacity': 0.25,
				'cursor': 'pointer'
			});

			angular.element('.connected + text').css({
				'fill':'#ffffff'
			});

			angular.element('.connected + text tspan.lnw').css({
				'fill':'#ffffff'
			});
		}

		$scope.dimConnectedNeighborhoods = function() {

			angular.element('.connected').css({
				'opacity': 0,
				'cursor': 'pointer'
			});

			angular.element('.connected + text').css({
				'fill':'#444444'
			});

			angular.element('.connected + text tspan.lnw').css({
				'fill':'#444444'
			});
		}

		$scope.showNeighborhoodDialog = function(neighborhood) {


			//NeighborhoodService.banner(neighborhood).then(function(response) {

				//$scope.banner = response.data[0].FYN_BannerImage;
				$scope.banner = neighborhood;

				$scope.neighborhoodDialog = false;
				angular.forEach($scope.data.neighborhoods, function(v) {
					if(v.FYN_Slug === neighborhood) {
						$scope.dialogData = v;
						$scope.dialogLoaded = true;
					}
				});

			//})

		}

		$scope.closeDialog = function() {
			$scope.dialogLoaded = false;
		}


		// TESTING
		$scope.loadMoreMasonry = function() {
			var more = [
				{ src: '/img/neighborhoods/bricks/algiers-westbank/04.jpg' },
				{ src: '/img/neighborhoods/bricks/algiers-westbank/05.jpg' },
				{ src: '/img/neighborhoods/bricks/algiers-westbank/06.jpg' },
				{ src: '/img/neighborhoods/bricks/algiers-westbank/07.jpg' },
				{ src: '/img/neighborhoods/bricks/algiers-westbank/08.jpg' },
				{ src: '/img/neighborhoods/bricks/algiers-westbank/09.jpg' }
			];


			more.forEach(function(value) {
				$scope.bricks.push(value);
			});

			$timeout(function() {
				$rootScope.$broadcast('masonry.reload');
			}, 200);

		}



		$scope.loadLargeImage = function($event) {

			var largeImage = $event.currentTarget.src.split('/');
			var position = largeImage.length -1;
			largeImage.splice(position, 0, 'large')

			largeImage = largeImage.join("/");
			$scope.originalImage = $event.currentTarget.src;
			$scope.lightboxImage = largeImage;
			$scope.lightboxImageActive = true;
		}

		$scope.closeLightbox = function() {
			$scope.lightboxImage = undefined;
			$scope.lightboxImageActive = false;
		}


/*
		if($state.is('singleNeighborhood') || $state.is('singleNeighborhood-mobile')) {

		}
*/

	});
})();
