(function() {
	
	'use strict';

	angular.module('FYN').controller('AdminController', function($window, $rootScope, $scope, $filter, $compile, $auth, $timeout, $state, toastr, $stateParams, Upload, ResultsService, NeighborhoodService, AdminService, Slug, ListService) {

		// Check access rights
		var payload = $auth.getPayload();

		if(!payload || payload.role < 16) {			
			$state.go('home');
		}



		$scope.sendAlert = function() {
			alert('clicked');

		}



		$scope.isSuperAdmin = function() {

			return $auth.getPayload().role === 32;
		}

		
		$scope.searchUsers = function(userQuery) {
			$scope.noResultsFound = false;
			$scope.userSearchResults = false;
			$scope.alreadyAdmin = false;
			var aa = $filter('filter')($scope.admins, { email: userQuery });
			if(aa.length) {
				$scope.alreadyAdmin = true;
				return false;
			}
			else {
				AdminService.searchUser(userQuery).then(function(res) {
					if(res.data.length) {
						$scope.userSearchResults = true;
						$scope.userSearchReturnData = res.data;
					} else {
						$scope.searchUsersQuery = userQuery;
						$scope.userSearchResults = false;
						$scope.noResultsFound = true;
					}

				})
			}
		};
		$scope.translateRole = function(role) {
			switch(role) {
				case 16:
				return 'Admin'
				break;
				case 32:
				return 'Super Admin';
				break;
			}
		}
		$scope.checkIfMe = function(email) {
			if(email === $scope.user.user.email) {
				return true;
			}
		}
		$scope.makeAdmin = function($event, type) {
			var params = {
				email: $event.currentTarget[0].value,
				privs: $event.currentTarget[1].value
			}
			AdminService.addAdmin(params, type).then(function(res) {
				$scope.admins = res.data.admins;
				if(type === 'new') {
					$scope.toggleNewAdminDialog();
				}
				if(type === "edit") {
					$scope.closeEditAdminDialog();
				}
				$scope.userSearchResults = false;
				toastr.success('User role successfully updated.', {
					iconClass: 'toast-blue'
				});

			});
		}

		$scope.addAdmin = function(email) {
			AdminService.addAdmin(email).then(function(res) {
				$scope.admins = res.data.admins;
				$scope.toggleNewAdminDialog();
				$scope.userSearchResults = false;
			})
		}
		$scope.removeAdmin = function(email) {
			var c = confirm('Are you sure you want to remove this admin?');
			if(c) {
				AdminService.removeAdmin(email).then(function(res) {
					$scope.admins = res.data.admins;
					toastr.success('Admin removed successfully', {
						iconClass: 'toast-blue'
					})
				})
			}
			else {
				return false;
			}
		}


		$scope.toggleNewAdminDialog = function() {
			if($scope.newAdminDialog) {
				$scope.userSearchResults = false;
				$scope.noResultsFound = false;
			}
			$scope.newAdminDialog = ! $scope.newAdminDialog;
		}

		$scope.openEditAdminDialog = function($event) {

			var elem = angular.element($event.currentTarget);
			$scope.adminUserEmail = elem.closest('tr').find('td.email').text();
			$scope.adminUserRole = elem.closest('tr').find('td.role').text();

			$scope.editAdminDialog = true;
		}
		$scope.closeEditAdminDialog = function() {
			$scope.editAdminDialog = false;
		}



		$scope.updateCustomThumbUrl = function($event, listid) {
			var listid = $stateParams.id;
			var url = $event.currentTarget.value;
			AdminService.updateCustomThumbUrl(listid, url).then(function(response) {
				toastr.success('Thumbnail Source Updated', {
					iconClass: 'toast-blue'
				});
			})

		}



		$scope.uploadCuratedImage = function (slug, files) {
			Upload.upload({
				url: '/api/upload/curated',
				data: {
					file: files,
					slug: slug,
					'username': $scope.username
				}
			}).then(function(response) {
				if(response.data.error) {
					alert('Image already exists. Please rename the image or try another');
					//toastr.error('Image already exists. Please rename the image or try another');
				}
				else {
					$scope.curatedImages.push({
						filename: response.data.imageFile,
						NeighborhoodID: response.data.neighborhoodID,
						status: 1
					});
					toastr.success('Image successfully added.', {
						iconClass: 'toast-blue'
					});
				}
			}),
			function (response) {
				// eh....error
			},
			function (evt) {
				var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
			}
		}






		$scope.toggleNewCategoryDialog = function() {
			$scope.categoryDialogActive = !$scope.categoryDialogActive;
		}

		$scope.dismissEditCategoryDialog = function() {
			$scope.editCategoryDialogActive = !$scope.editCategoryDialogActive;

		}

		$scope.category = {};

		$scope.toggleCatStatus = function() {
			$scope.category.active = !$scope.category.active;
		}

		$scope.toggleEditCategoryDialog = function(id) {
			// Get the data for the category
			AdminService.getCategory(id).then(function(response) {
				
				$scope.updateCategoryId = response.data.id;
				$scope.category.active = response.data.active;
				$scope.categoryThumbnail = response.data.list_category_background;
				$scope.updateCategoryTitle = response.data.list_category_title;
				$scope.updateCategorySlug = response.data.list_category_slug;
				$scope.updateCategoryDescription = response.data.list_category_description;
				$scope.editCategoryDialogActive = !$scope.editCategoryDialogActive;

			});
		}


		$scope.toggleTabs = function($event, elementClass) {

			angular.element($event.currentTarget).closest('ul').find('li').removeClass('active');
			angular.element($event.currentTarget).closest('li').addClass('active');

			angular.element($event.currentTarget).closest('.row').find('.tab-pane').removeClass('active');
			angular.element($event.currentTarget).closest('.row').find('.tab-pane.'+elementClass).addClass('active');
		}

		if($state.is('users')) {
			$scope.loading = true;
			ResultsService.getAdmins().then(function(res) {
				$scope.admins = res.data;
				$scope.loading = false;
			});
		}



		if($state.is('walkingTours')) {

			ResultsService.getTours().then(function(res) {

				$scope.tours = res.data;

			})

		}





		if($state.is('tips')) {

			$scope.loading = true;
			ResultsService.getTips().then(function(res) {
				$scope.tips = res.data;
				$scope.loading = false;

			});

			$scope.tipMatch = function(lid, tid) {
				return lid === tid;
			}




			var tipContent;
			var tipControlsContent;

			$scope.openContentEdit = function($event) {
				tipContent = angular.element($event.currentTarget).closest('tr').find('td.tip-content').text();
				tipControlsContent = angular.element($event.currentTarget).closest('tr').find('td.tip-controls').html();

				var html = '<textarea class="form-control">'+tipContent+'</textarea><div class="text-right" style="margin-top: 5px;"><button class="button button-default button-small" ng-click="saveEditTip($event)">SAVE</button> <button ng-click="cancelEditTip($event)" class="button button-small">CANCEL</button></div>';
				var template = $compile(html)($scope);

				angular.element($event.currentTarget).closest('tr').find('td.tip-content').empty();
				angular.element($event.currentTarget).closest('tr').find('td.tip-content').html(template);
				angular.element($event.currentTarget).closest('tr').find('td.tip-controls').empty();
			}

			$scope.cancelEditTip = function($event) {
				tipContent = angular.element($event.currentTarget).closest('tr').find('.form-control').val();
				var template = $compile(tipControlsContent)($scope);
				angular.element($event.currentTarget).closest('tr').find('td.tip-controls').html(template);
				angular.element($event.currentTarget).closest('tr').find('td.tip-content').empty().text(tipContent);
			}
			$scope.saveEditTip = function($event) {
				var tipId = angular.element($event.currentTarget).closest('tr').data('tipid');
				tipContent = angular.element($event.currentTarget).closest('tr').find('.form-control').val();
				ResultsService.editTip(tipId, tipContent).then(function(res) {
					toastr.success('Tip updated', {
						iconClass: 'toast-blue'
					});
					$scope.cancelEditTip($event);
				});
			}
			$scope.deleteTip = function($event, $index) {
				var c = confirm('Are you sire you want to delete this tip? This action cannot be undone.');
				if(c) {
					var tipId = angular.element($event.currentTarget).closest('tr').data('tipid');
					ResultsService.deleteTip(tipId).then(function(res) {
						$scope.tips = res.data.tips;
						toastr.success(res.data.message, {
							iconClass: 'toast-blue'
						})
					})
				}
				return false;
			}
		}









		if($state.is('locations')) {
			var addMap;
			var addMarker;

			var editMap;
			var editMarker;

			$scope.audioFile;
			$scope.audioFileName;

			$scope.add = {};
			$scope.edit = {
				audio: undefined
			};


			$scope.uploadLocationAudio = function(listid, locationid, file) {

				Upload.upload({
					url: '/api/locationaudioupload',
					data: {
						file: file,
						locationid: $scope.edit.locationid,
						username: $scope.username
					}
				})
				.then(function(resp) {
					$scope.instanceAudio[0].filename = resp.data.filename;
					toastr.success('Audio Updated', {
						iconClass: 'toast-blue'
					});
				},
				// error
				function (resp) {
				},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});
			}



			$scope.showAddCustomLocationModal = function() {

				$scope.locationSaved = false;
				$scope.add = {};

				angular.element('.add-custom-location-modal').css('display','block');
				addMap = new google.maps.Map(document.getElementById('addLocationMap'), {
					center: { lat: 29.943164, lng: -90.108784 },
					zoom: 14
				});
				addMap.addListener('click', function(e) {
					var latlngObj = {
						lat: e.latLng.lat(),
						lng: e.latLng.lng()
					};
					if(addMarker) {
						addMarker.setPosition(latlngObj);
					}
					else {
						addMarker = new google.maps.Marker({
							position: latlngObj,
							map: addMap,
							title: 'New Custom Location',
							icon: '/img/marker-secondary.png'
						});
					}
					$scope.add.geoLatitude = latlngObj.lat;
					$scope.add.geoLongitude = latlngObj.lng;

				});
			};




			$scope.hideAddCustomLocationModal = function() {
				if($scope.locationSaved) {
					AdminService.getCustomLocations().then(function(response) {

						$scope.customlocations = response.data;
						angular.element('.add-custom-location-modal').css('display','none');
						toastr.success('Location Created', {
							iconClass: 'toast-blue'
						});
						
					})
				}
				else {
					angular.element('.add-custom-location-modal').css('display','none');	
				}				
			};







			$scope.showEditCustomLocationModal = function(locationID) {
				$scope.edit.locationid = locationID;

				// Reset so we do not get a flash of old content
				$scope.customlocation = undefined;
				$scope.edit.bannerImages = [];
				$scope.edit.thumbnailImage = undefined;

				AdminService.getCustomLocation(locationID).then(function(response) {

					console.log(response.data);


					$scope.customlocation = response.data[0];

					editMap = new google.maps.Map(document.getElementById('editLocationMap'), {
						center: {
							lat: parseFloat($scope.customlocation.GeoLatitude),
							lng: parseFloat($scope.customlocation.GeoLongitude)
						},
						zoom: 14
					});
					editMarker = new google.maps.Marker({
						position: {
							lat: parseFloat($scope.customlocation.GeoLatitude),
							lng: parseFloat($scope.customlocation.GeoLongitude)
						},
						map: editMap,
						title: $scope.customlocation.LocationName1,
						icon: '/img/marker-secondary.png'
					});

					editMap.addListener('click', function(e) {
						var latlngObj = {
							lat: e.latLng.lat(),
							lng: e.latLng.lng()
						};
						if(editMarker) {
							editMarker.setPosition(latlngObj);
							$scope.edit.geoLatitude = latlngObj.lat;
							$scope.edit.geoLongitude = latlngObj.lng;
						}
						else {
							editMarker = new google.maps.Marker({
								position: latlngObj,
								map: editMap,
								title: $scope.customlocation.LocationName1,
								icon: '/img/marker-secondary.png'
							});
						}
					});

					$scope.edit.locationName = $scope.customlocation.LocationName1;
					$scope.edit.geoLatitude = $scope.customlocation.GeoLatitude;
					$scope.edit.geoLongitude = $scope.customlocation.GeoLongitude;
					$scope.edit.description = $scope.customlocation.Description;
					$scope.edit.coLocation = $scope.customlocation.CorrespondingLocation;
					$scope.edit.thumbnailImage = $scope.customlocation.ThumbnailImage;

					response.data[0].all_media.forEach(function(value) {

						
						/*
						if(value.mediaTypeID === 1 && !listid) {
							$scope.edit.thumbnailImage = value.filename;
						}
						*/
						if(value.mediaTypeID === 2 && !value.listid) {
							$scope.edit.bannerImages.push(value.filename);
						}
						if(value.mediaTypeID === 3 && !value.listid) {
							$scope.edit.video = value.filename;
						}
						if(value.mediaTypeID === 4 && !value.listid) {
							$scope.edit.audio = value.filename;
						}
					});

					console.log($scope.edit.bannerImages);
				});
				angular.element('.edit-custom-location-modal').css('display','block');
			}



			$scope.hideEditCustomLocationModal = function() {
				editMap = null;
				angular.element('.edit-custom-location-modal').css('display','none');
				$scope.edit = [];
				$scope.add = [];

				AdminService.getCustomLocations().then(function(response) {
					$scope.customlocations = response.data;
				});
			}


			$scope.addCustomLocation = function() {

				$scope.locationSaved = true;


				AdminService.addCustomLocation($scope.add).then(function(res) {					
					//$scope.edit.locationid = res.data.location.LocationID;
/*					$scope.hideAddCustomLocationModal();
					toastr.success('Location Created', {
						iconClass: 'toast-blue'
					});
*/

				});
			}

			$scope.updateCustomLocation = function() {
				AdminService.updateCustomLocation($scope.edit).then(function(response) {
					AdminService.getCustomLocations().then(function(response) {
						$scope.customlocations = response.data;
						$scope.hideEditCustomLocationModal();
						toastr.success('Location Updated', {
							iconClass: 'toast-blue'
						});
					});
				});
			}

			$scope.deleteCustomLocation = function(locationid) {

				if(confirm('Are you sure you want to delete this entry? This action cannot be undone')) {
					AdminService.deleteCustomLocation(locationid).then(function(response) {
						toastr.success('Location Removed', {
							iconClass: 'toast-blue'
						});					
						AdminService.getCustomLocations().then(function(response) {
							$scope.customlocations = response.data;
						});
					})
				}
			}




			$scope.deleteBanner = function(filename) {
				var c = confirm('Are you sure you want to delete '+filename+'? This cannot action cannot be reversed.');

				if(c) {
					AdminService.deleteBanner(filename, $scope.edit.locationid).then(function(response) {
						AdminService.getBannerImages($scope.edit.locationid).then(function(response) {

							console.log(response);

							$scope.edit.bannerImages = [];
							$scope.add.bannerImages = [];
							response.data.forEach(function(value) {
								$scope.edit.bannerImages.push(value.filename);
								$scope.add.bannerImages.push(value.filename);
							});
						});
						toastr.success('Image Removed', {
							iconClass: 'toast-blue'
						});						
					});
				}
			}


			$scope.customlocationbanners = [];

			$scope.uploadCustomLocationBanner = function (file) {
				Upload.upload({
					url: '/api/upload/customlocation/banner',
					data: {
						locationid: $scope.edit.locationid,
						file: file,
						'username': $scope.username
					}
				})
				.then(function (resp) {
					$scope.edit.bannerImages = [];
					$scope.add.bannerImages = [];
					resp.data.imageFiles.forEach(function(value) {
						$scope.edit.bannerImages.push(value);
						$scope.add.bannerImages.push(value);
					});				

/*

					AdminService.getBannerImages($scope.edit.locationid).then(function(response) {
						response.data.forEach(function(value) {
							$scope.edit.bannerImages.push(value.filename);
							$scope.add.bannerImages.push(value.filename);
						});
					});					*/
					toastr.success('Image Updated', {
						iconClass: 'toast-blue'
					});
				},
				function (resp) {},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});
			};

			$scope.uploadCustomLocationThumbnail = function (file) {
				Upload.upload({
					url: '/api/add/customlocation/thumbnail',
					data: {
						file: file,
						locationid: $scope.edit.locationid,
						'username': $scope.username
					}
				})
				.then(function (resp) {
					$scope.add.thumbnailImage = resp.data.imageFiles;
					$scope.edit.thumbnailImage = resp.data.imageFiles;

/*					AdminService.getThumbnailImages($scope.edit.locationid).then(function(response) {

						$scope.edit.thumbnailImage = response.data[0].filename;
					});
*/					toastr.success('Image Updated', {
						iconClass: 'toast-blue'
					});
				},
				function (resp) {
				},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});
			};


			$scope.tinymceOptions = {
				theme: "modern",
				plugins: [
					"autolink lists link image charmap preview hr anchor pagebreak",
					"searchreplace wordcount visualblocks visualchars",
					"insertdatetime nonbreaking save contextmenu directionality",
					"paste textcolor"
				],
				toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
				image_advtab: true,
				setup: function(editor) {}
			};


			// Load the locations initially
			$scope.loading = true;
			AdminService.getCustomLocations().then(function(response) {
				console.log(response.data);
				$scope.customlocations = response.data;
				$scope.loading = false;
			});
		}






		if($state.is('categories')) {

			$scope.loading = true;

			$scope.categoryFilter = '';


			$scope.setTableView = function() {
				$rootScope.tableView = true;
				$rootScope.gridView = false;
			}
			$scope.setGridView = function() {
				$rootScope.gridView = true;
				$rootScope.tableView = false;
			}


			ResultsService.categories().then(function(res) {
				console.log(res.data);
				$scope.categories = res.data;
				$scope.loading = false;
			});

			$scope.createCategory = function(title,thumbnail,description) {
				var slug = Slug.slugify(title);
				AdminService.createCategory(title,thumbnail,slug,description).then(function(res) {
					//$scope.categories.categories.push(res.data);
					$scope.categories.categories = res.data;

					$scope.categoryDialogActive = false;
				});
			};

			$scope.deleteCategory = function(id, index) {
				var c = confirm('Are you sure you want to delete this category? You cannot undo this action');

				if(c) {
					AdminService.deleteCategory(id).then(function(response) {
						$scope.categories.categories.splice(index, 1);
						toastr.success('Category deleted', {
							iconClass: 'toast-blue'
						})
					})
				}
			}




			$scope.showCatStatus = function(c) {}


			/* UPDATE CATEGORY INFORMATION */

			$scope.updateCategory = function(id, status, thumbnail, title, description) {


				var slug = Slug.slugify(title);

				AdminService.updateCategory(id, status, thumbnail, title, slug, description).then(function(response) {

					$scope.toggleEditCategoryDialog();
					ResultsService.categories().then(function(res) {
						$scope.categories = res.data;
					});
				});
			}






			$scope.uploadCategoryThumbnail = function(id,file) {
				Upload.upload({
					url: '/api/upload/category/thumbnail',
					data: {
						id: id,
						file: file,
						'username': $scope.username
					}
				})
				.then(function (resp) {
					$scope.categoryThumbnail = resp.data.imageFile;
					toastr.success('Image Updated', {
						iconClass: 'toast-blue'
					});
				},
				function (resp) {
				},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});
			}

			$scope.categorySort = function($item,$partFrom,$partTo,$index) {
				if($scope.categoryFilter.length) {
					alert('List sorting is disabled while filters are active');
					$scope.categories.categories = $partFrom;
					return false;
				}
				AdminService.updateCategorySort($partTo).then(function(res) {
					toastr.success('Categories updated successfully!', {
						iconClass: 'toast-blue'
					});
					$scope.categories.categories = $partTo;
				})
			}


		}





		if($state.is('tags')) {
			$scope.tagbbq = '';
			$scope.loading = true;
			AdminService.tags().then(function(res) {
				$scope.tags = res.data;
				$scope.loading = false;
			});
			$scope.tagSort = function($item,$partFrom,$partTo,$index) {
				if($scope.tagbbq.length || $scope.tagbbq === undefined) {
					alert('Tag sorting is disabled while filters are active');
					return false;
				}
				AdminService.updateListSort($partTo).then(function(res) {
					toastr.success('Tags updated successfully!', {
						iconClass: 'toast-blue'
					});
					$scope.tags = $partTo;
				})
			}

			$scope.listDelete = function(listid, $index) {
				var c = confirm('Are you sure you want to delete this list?');
				if(!c) {
					return false;
				}
				else {
					ListService.deleteList(listid).then(function(res) {
						$scope.lists.length = 0;
						AdminService.tags().then(function(res) {
							$scope.tags = res.data;

						});
						toastr.success('List deleted', {
							iconClass: 'toast-blue'
						});
					});
				}
			}
		}







		if($state.is('lists')) {

			$scope.bbq = '';
			$scope.loading = true;

			//$rootScope.gridView = true;

			$scope.setTableView = function() {
				$rootScope.tableView = true;
				$rootScope.gridView = false;
			}
			$scope.setGridView = function() {
				$rootScope.gridView = true;
				$rootScope.tableView = false;
			}



			// Sort the list table
			$scope.bw = false;
			$scope.orderby = 'list_title';

			// Re-Order functionality
			$scope.reorderby = function(g) {
				$scope.lists.lists = $filter('orderBy')($scope.lists.lists, g, $scope.bw);
				$scope.bw = !$scope.bw;
			}

			// Get the lists
			AdminService.lists().then(function(res) {
				$scope.lists = res.data;
				$scope.loading = false;
			})

			$scope.listSort = function($item,$partFrom,$partTo,$index) {
				if($scope.bbq.length) {
					alert('List sorting is disabled while filters are active');
					$scope.lists.lists = $partFrom;
					return false;
				}
				AdminService.updateListSort($partTo).then(function(res) {
					toastr.success('Lists updated successfully!', {
						iconClass: 'toast-blue'
					});
					$scope.lists.lists = $partTo;
				})
			}




/*
			ResultsService.categories().then(function(res) {
				$scope.categories = res.data;
			});

*/

			$scope.listDelete = function(listid, $index) {
				var c = confirm('Are you sure you want to delete this list?');
				if(!c) {
					return false;
				}
				else {
					ListService.deleteList(listid).then(function(res) {
						$scope.lists.length = 0;
						AdminService.lists().then(function(res) {
							$scope.lists = res.data;

						});
						toastr.success('List deleted', {
							iconClass: 'toast-blue'
						});
					})
				}
			}
		}

		if($state.is('lists-new')) {
			$scope.listImage = '';
			$scope.listCategory;
			$scope.formErrors = false;
			ResultsService.categories().then(function(res) {
				$scope.categories = res.data;
			});

			$scope.createList = function() {
				var data = {
					title: $scope.listTitle,
					slug: Slug.slugify($scope.listTitle),
					category: $scope.listCategory || undefined,
					description: $scope.listDescription
				}
				var slug = Slug.slugify($scope.listTitle);

				$scope.createListErrorObject = {};

				if(!data.title) {
					$scope.createListErrorObject.titleError = 'Please provide a list title';
				}
				if(!data.category) {
					$scope.createListErrorObject.categoryError = 'Please choose a category';
				}
				if($scope.createListErrorObject.titleError || $scope.createListErrorObject.categoryError) {
					return false;
				}


				ListService.createList(data.title, data.slug, data.category, data.description).then(function(res) {
					$state.go( 'lists-edit', { id: res.data.list.id } );
				})
			}

		}


		$scope.returnFallbackImage = function(elem) {
			elem.onerror = '';
			elem.src = '/img/fluer-default.png';
		}



		/* ======= EDIT LIST ====== */

		if($state.is('lists-edit')) {

			$scope.imageFile = false;
			$scope.audioFile = false;
			$scope.audioFileName;
			$scope.dropdownCats = {};


			$scope.uploadLocationAudio = function(listid, locationid, file) {


				Upload.upload({
					url: '/api/locationaudioupload',
					data: {
						file: file,
						listid: listid,
						locationid: locationid,
						username: $scope.username
					}
				})
				.then(function(resp) {
					console.log(resp.data);
					$scope.argh = resp.data.filename;
					$scope.instanceAudio[0].filename = resp.data.filename;
					
					toastr.success('Audio Updated', {
						iconClass: 'toast-blue'
					});
				},
				// error
				function (resp) {
				},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});
			


			}

			$scope.uploadAudio = function(listid, file) {
				$scope.audioFileName = '';
				Upload.upload({
					url: '/api/audioupload',
					data: {
						file: file,
						listid: $state.params.id,
						username: $scope.username
					}
				})
				.then(function(resp) {
					$scope.audioFileName = resp.config.data.file.name;
					toastr.success('Audio Updated', {
						iconClass: 'toast-blue'
					});
				},
				// error
				function (resp) {
				},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});
			}
			$scope.uploadPic = function (listid, file) {
				Upload.upload({
					url: '/api/upload',
					data: {
						file: file,
						listid: listid,
						'username': $scope.username
					}
				})
				.then(function (resp) {
					$scope.imageFile = file[0].name;
					toastr.success('Image Updated', {
						iconClass: 'toast-blue'
					});
				},
				// error
				function (resp) {
				},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});
			};
			$scope.uploadMobilePic = function (listid, file) {
				Upload.upload({
					url: '/api/upload/mobile',
					data: {
						file: file,
						listid: listid,
						'username': $scope.username
					}
				})
				.then(function (resp) {
					$scope.mobileImageFile = file[0].name;
					toastr.success('Image Updated', {
						iconClass: 'toast-blue'
					});
				},
				// error
				function (resp) {
				},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});
			};


			$scope.loading = true;
			$scope.isTour = false;
			$scope.listImage = '';

			var listid = $stateParams.id;


			$scope.loadContextModal = function(locationid, listid) {

				var contextMap;
				var contextMarker;

				AdminService.getContext(locationid, listid).then(function(res) {

					console.log(res.data);
	
					$scope.openInstance = res.data.location;
					$scope.media = res.data.media;
					$scope.instanceAudio = [];



					$scope.media.forEach(function(v) {

						if(v.mediaTypeID === 4) {
							$scope.instanceAudio.push(v.filename);
						}
					});

					

					$scope.openInstanceBanners = $filter('filter')($scope.media, { mediaTypeID: 2 });

					$scope.openInstanceAudio = $filter('filter')($scope.media, { mediaTypeID: 4 }) || undefined;
					
					if($scope.instanceAudio.length) {
						$scope.argh = $scope.openInstanceAudio[0].filename;
					}

					//console.log($scope.argh);

					//$scope.instanceAudio = $filter('filter')($scope.media, { mediaTypeID: 4 }) || undefined;

					//$scope.argh = $scope.instanceAudio[0].filename;

					//$scope.instanceAudio = $scope.instanceAudio[0];


					contextMap = new google.maps.Map(document.getElementById('addContextMap'), {
						center: {
							lat: parseFloat($scope.openInstance.AltGeoLatitude),
							lng: parseFloat($scope.openInstance.AltGeoLongitude)
						},
						zoom: 14
					});
					contextMarker = new google.maps.Marker({
						position: {
							lat: parseFloat($scope.openInstance.AltGeoLatitude),
							lng: parseFloat($scope.openInstance.AltGeoLongitude)					
						},
						map: contextMap,
						title: 'New Custom Location',
						icon: '/img/marker-secondary.png'
					});



					contextMap.addListener('click', function(e) {
						var latlngObj = {
							lat: e.latLng.lat(),
							lng: e.latLng.lng()
						};
						contextMarker.setPosition(latlngObj);

						$scope.$apply(function() {
							$scope.openInstance.AltGeoLatitude = e.latLng.lat();
							$scope.openInstance.AltGeoLongitude = e.latLng.lng();
						})
					});
					angular.element('.addInstanceForm').show();
					google.maps.event.trigger(contextMap, 'resize');
					/*
					$timeout(function() {
						contextMap.setCenter(contextMap.getCenter());	
					},100);
					*/
				});
			}




			$scope.uploadInstanceThumbnail = function($files) {
				Upload.upload({
					url: '/api/upload/instance/thumbnail',
					data: {
						file: $files,
						locationid: $scope.openInstance.location_id,
						listid: listid
					}
				})
				.then(function (resp) {
					
					AdminService.getInstanceThumbnail($scope.openInstance.location_id, listid).then(function(response) {


						var thumbnail = response.data.AltThumbnail;


						$scope.openInstance.AltThumbnail = thumbnail;

						console.log($scope.openInstance.AltThumbnail);
					});
					toastr.success('Image Updated', {
						iconClass: 'toast-blue'
					});
				},
				function (resp) {
				},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});
			}




/*			$scope.uploadInstanceThumbnail = function($files) {
				Upload.upload({
					url: '/api/upload/customlocation/thumbnail',
					data: {
						file: $files,
						locationid: $scope.openInstance.location_id,
						'username': $scope.username
					}
				})
				.then(function (resp) {
					
					AdminService.getThumbnailImages($scope.openInstance.location_id).then(function(response) {
						var file = response.data[0];

						$scope.openInstance.AltThumbnail = '/uploads/customlocations/thumbnails/'+file.filename;
					});
					toastr.success('Image Updated', {
						iconClass: 'toast-blue'
					});
				},
				function (resp) {
				},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});
			};
*/


			$scope.removeInstanceBanner = function($event, $index) {
				
				var c = confirm('Do you really want to delete this item?');
				
				if(c) {
					var filename = angular.element($event.target).closest('div').find('img').attr('src');
					var locationid = $scope.openInstance.location_id;
					var listid = $scope.openInstance.list_id;
					var obj = {
						filename: filename.replace('/uploads/customlocations/banners/',''),
						locationid: locationid,
						listid: listid
					}
					AdminService.removeInstanceBanner(obj).then(function(res) {
						console.log(res.data);
						if(res.data.file_removed) {
							$scope.openInstanceBanners.splice($index, 1);
							toastr.success('Image Removed', {
								iconClass: 'toast-blue'
							});							
						}
					});
				}
			}

			$scope.uploadInstanceBanner = function($files) {
				Upload.upload({
					url: '/api/upload/customlocation/banner',
					data: {
						file: $files,
						listid: listid,
						locationid: $scope.openInstance.location_id,
						'username': $scope.username
					}
				})
				.then(function (resp) {
					AdminService.getBannerImages($scope.openInstance.location_id, $scope.openInstance.list_id).then(function(response) {
						$scope.openInstanceBanners = response.data;
					});
					toastr.success('Image Updated', {
						iconClass: 'toast-blue'
					});
				},
				function (resp) {
				},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				});



			}






			// Get the list
			AdminService.adminList(listid).then(function(res) {
				if(res.data.list.category.list_category_slug == 'tours') {
					$scope.isTour = true;
				}
				$scope.list = res.data.list;
				$scope.listStatus = $scope.list.active;
				$scope.locations = res.data.locations;
				$scope.listCategories = res.data.listCategories;
				$scope.listContent = $filter('orderBy')($scope.locations, 'sort');
				$scope.thisListCategory = $scope.list.category.list_category_title;
				$scope.listid = listid;
				$scope.audioFileName = $scope.list.audio;				
				$scope.loading = false;
				$scope.imageFile = $scope.list.list_image;
				$scope.mobileImageFile = $scope.list.list_mobile_image;
			});

			$scope.categoryEdit;


			$scope.closeAddInstanceForm = function() {
				//contextMap = null;
				angular.element('.addInstanceForm').hide();
			}



			$scope.toggleClass = function($event) {
				angular.element($event.currentTarget).toggleClass('fa-edit fa-close');
				if($event.currentTarget.classList[1] === 'fa-close') {
					$scope.categoryEdit = true;
				}
				else {
					$scope.categoryEdit = false;
				}
			}



			$scope.setCategory = function(newcat, newcattitle) {

				$scope.thisListCategory = newcattitle;
				$scope.categoryEdit = false;
				angular.element('h4 .fa').toggleClass('fa-edit fa-close');

				ListService.setCategory(newcat, listid).then(function(res) {
					toastr.success('Category successfully updated to '+newcattitle+'!', {
						iconClass: 'toast-blue'
					})

				});


			}







			$scope.showControls = function() {
				$scope.moo = true;
			}
			$scope.updateTitle = function($event) {
				var title = $event.currentTarget.value.trim();
				var slug = Slug.slugify(title);
				ListService.updateTitle(listid,title,slug).then(function(res) {
					toastr.success('Title Updated', {
						iconClass: 'toast-blue'
					});
				})
			};
			$scope.updateTourDuration = function($event) {
				var tourDuration = $event.currentTarget.value.trim();

				ListService.updateTourDuration(listid,tourDuration).then(function(res) {
					toastr.success('Tour Duration Updated', {
						iconClass: 'toast-blue'
					});
				});
			}

			$scope.updateTourDistance = function($event) {
				var tourDistance = $event.currentTarget.value.trim();

				ListService.updateTourDistance(listid,tourDistance).then(function(res) {
					toastr.success('Tour Distance Updated', {
						iconClass: 'toast-blue'
					});
				});
			}
			$scope.updateVimeoId = function($event) {
				var vimeoid = $event.currentTarget.value.trim();
				//var slug = Slug.slugify(title);
				ListService.updateVimeoId(listid,vimeoid).then(function(res) {
					toastr.success('Vimeo ID Updated', {
						iconClass: 'toast-blue'
					});
				})
			};
			$scope.updateDescription = function($event) {

				var text = $event.currentTarget.value.trim();
				//var text = $event.currentTarget.textContent.trim();
				ListService.updateDescription(listid, text).then(function(res) {
					toastr.success('Content Updated!', {
						iconClass: 'toast-blue'
					});

				})
			}
			$scope.includeFeed = true;
			$scope.updateFeed = function($event) {
				$scope.listStatus = !$scope.listStatus;
				var listid = $stateParams.id;
				ListService.setActive(listid, $event.currentTarget.checked).then(function(res) {
				});
			}





			/* ===== LOCATION SEARCH ===== */
			$scope.query = function(squery) {
				$scope.queryloading = true;
				var params = {
					latitude: undefined,
					longitude: undefined
				}
				AdminService.search(squery).then(function(res) {
					$scope.queryresults = res.data.results;
					console.log($scope.queryresults);
					$scope.thumbnail = $filter('filter')($scope.queryresults[0].all_media, { mediaTypeID: 1 });
					angular.forEach($scope.queryresults, function(v1,k1) {
						angular.forEach($scope.listContent, function(v2, k2) {
							if(v1.LocationID === v2.location_id) {
								v1.duplicate = true;
							}
						})
					});
					$scope.queryloading = false;
				});
			};




			$scope.delete = function($index) {
				$scope.listid = $stateParams.id;
				$scope.entry = $scope.list.listContent[$index];
				var c = confirm('Are you sure you want to delete '+$scope.entry['AlphaSortingName']);
				if(!c) { return; }
				AdminService.deleteListEntry($scope.listid,$scope.entry).then(function(res) {
					$scope.list.listContent.splice($index, 1);
					toastr.success('Entry deleted', {
						iconClass: 'toast-blue'
					});
				});
			};



			$scope.deleteLocationFromList = function($index,$event) {
				var locationid = angular.element($event.currentTarget).closest('li').data('locationid');
				var listid = $stateParams.id;

				if( !confirm('Are you sure you want to delete this entry?') ) {
					return false;
				}
				AdminService.deleteLocationFromList(listid, locationid).then(function(response) {
					$scope.listContent.splice($index, 1);
					toastr.success('Location removed', {
						iconClass: 'toast-blue'
					});
				});
			}
			$scope.checkAgainstCurrentList = function(title) {
				return $filter('filter')($scope.listContent, title);
			}




			$scope.addLocationInstance = function() {

				AdminService.addLocationInstance($scope.openInstance).then(function(res) {



					AdminService.adminList(listid).then(function(res) {

						
						$scope.listContent = res.data.locations;
						angular.element('.addInstanceForm').hide();	
					})
					
				});				
			}

			




			// When a list is added to, removed, from, or sorted
			$scope.boo = function($item,$partFrom,$partTo,$index) {
				// Add the item to the list content table
				$scope.locations = $partTo;

				//console.log($item);
				//return false;

				$partTo.forEach(function(value) {
					// Check for Dupes
					if(value.LocationName1 === $item.LocationName1) {
						return false;
					}
				});				
				$scope.listid = $stateParams.id;
				$scope.updatedList = $partTo;
				//console.log($scope.updatedList);

				AdminService.updateListEntries($scope.listid, $scope.updatedList, $item).then(function(res) {
					console.log(res.data);
					$scope.listContent = res.data.list;
					toastr.success('List Updated', {
						iconClass: 'toast-blue'
					});
				});
			}
		}








		if($state.is('neighborhoods')) {
			$scope.loading = true;
			NeighborhoodService.all().then(function(res) {
				$scope.neighborhoods = res.data;
				$scope.loading = false;
			});
		}
		if($state.is('neighborhoods-edit')) {
			$scope.slug = $state.params.slug;
			//$scope.banner = $scope.slug+'.jpg';
			$scope.tinymceOptions = {
				theme: "modern",
				plugins: [
					"autolink lists link image charmap preview hr anchor pagebreak",
					"searchreplace wordcount visualblocks visualchars",
					"insertdatetime nonbreaking save contextmenu directionality",
					"paste textcolor"
				],
				toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
				image_advtab: true
			};
			AdminService.editNeighborhood($scope.slug).then(function(response) {
				$scope.neighborhood = response.data;
				$scope.detailPageDescription = $scope.neighborhood[0].FYN_DetailPageDescription;
				$scope.overviewTeaser = $scope.neighborhood[0].FYN_OverviewTeaser;
				$scope.banner = response.data[0].FYN_BannerImage;
				$scope.getCardData($scope.slug);
			});
			$scope.getCardData = function(slug) {
				AdminService.getCardData(slug).then(function(response) {
					response.data.forEach(function(value) {
						if(value.Category === 'stay') { $scope.stayCardData = value; }
						if(value.Category === 'eat') { $scope.eatCardData = value; }
						if(value.Category === 'drink') { $scope.drinkCardData = value; }
						if(value.Category === 'attractions') { $scope.attractionsCardData = value; }
						if(value.Category === 'shopping') { $scope.shoppingCardData = value; }
						if(value.Category === 'livemusic') { $scope.livemusicCardData = value; }
						if(value.Category === 'itineraries') { $scope.itinerariesCardData = value; }
						if(value.Category === 'annualevents') { $scope.annualeventsCardData = value; }
						if(value.Category === 'notablestreets') { $scope.notablestreetsCardData = value; }
						if(value.Category === 'history') { $scope.historyCardData = value; }
						if(value.Category === 'architecture') { $scope.architectureCardData = value; }
						if(value.Category === 'transportation') { $scope.transportationCardData = value; }
					});
				});
			};
			$scope.getCardData($scope.slug);
			$scope.updateDetailPageDescription = function(slug, data, showToast) {
				AdminService.updateDetailPageDescription(slug, data).then(function(response) {
					if(showToast) {
						toastr.success('Content Updated!', {
							iconClass: 'toast-blue'
						})
					}
				})
			};
			$scope.updateOverviewTeaser = function(slug, data, showToast) {
				AdminService.updateOverviewTeaser(slug, data).then(function(response) {
					if(showToast) {
						toastr.success('Content Updated!', {
							iconClass: 'toast-blue'
						})
					}
				})
			};
			// NEEDED?
			$scope.$watch('banner', function() {
			});
			$scope.$watch('slug', function() {
			});
			$scope.updateNeighborhoodBanner = function(file, slug) {
				Upload.upload({
					url: '/api/neighborhoods/'+slug+'/banner/update',
					data: {
						file: file,
						slug: slug
					}
				})
				.then(function(response) {
					$scope.randomNumber = Math.floor(Math.random() * 20);
					$timeout(function() {
						$scope.$apply(function() {
							//$scope.banner = file[0].name+'?lastmod='+$scope.randomNumber;
							$scope.banner = response.data+'?lastmod='+$scope.randomNumber;

						});
					}, 100);
					toastr.success('Image Updated', {
						iconClass: 'toast-blue'
					});
				},
				function (resp) {},
				function (evt) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);

				});
			};
			$scope.updateNeighborhoodCardImage = function(file, slug, category) {
				Upload.upload({
					url: '/api/neighborhoods/'+slug+'/cardImage/update',
					data: {
						file: file,
						slug: slug,
						category: category
					}
				}).then(function(response) {
					$scope.randomNumber = Math.floor(Math.random() * 20);
					angular.element('.'+category+'CardImage').attr('src','/img/neighborhoods/cards/'+slug+'/'+category+'.jpg?lastmod='+$scope.randomNumber); // Meh
					toastr.success('Image Updated', {
						iconClass: 'toast-blue'
					});
				})
			}
			$scope.updateCardData = function(slug, tab, card, data, showToast) {
				AdminService.updateCardData(slug, tab, card, data).then(function(response) {
					if(showToast) {
						toastr.success('Content Updated!', {
							iconClass: 'toast-blue'
						})
					}
				});
			}
			$scope.getCuratedImages = function(slug) {
				AdminService.getCuratedImages(slug).then(function(response) {
					$scope.curatedImages = response.data;
				})
			}
			$scope.getCuratedImages($scope.slug);
			$scope.enableCuratedImage = function($index,neighborhoodID, filename) {
				AdminService.enableCuratedImage(neighborhoodID,filename).then(function(response) {
					$scope.curatedImages[$index].status = 1;
					toastr.success('Image Enabled', {
						iconClass: 'toast-blue'
					})
				});
			}
			$scope.disableCuratedImage = function($index,neighborhoodID, filename) {
				AdminService.disableCuratedImage(neighborhoodID,filename).then(function(response) {
					$scope.curatedImages[$index].status = 0;
					toastr.success('Image Disabled', {
						iconClass: 'toast-blue'
					});
				});
			}
			$scope.deleteCuratedImage = function($index, neighborhoodID, filename) {
				var c = confirm('Are you sure yu want to delete '+filename+'? This action cannot be undone.');
				if(c) {
					AdminService.deleteCuratedImage(neighborhoodID, filename).then(function(response) {
						$scope.curatedImages.splice($index, 1);
						toastr.success(response.data.success, {
							iconClass: 'toast-blue'
						});

					});
				}
			}
			$scope.updateAll = function() {
				$scope.updateDetailPageDescription($scope.slug, $scope.detailPageDescription, false);
				$scope.updateOverviewTeaser($scope.slug, $scope.overviewTeaser, false);
				$scope.updateCardData($scope.slug, 'primary', 'stay', $scope.stayCardData.Description, false);
				$scope.updateCardData($scope.slug, 'alternate', 'stay', $scope.stayCardData.AlternateDescription, false);
				$scope.updateCardData($scope.slug, 'primary', 'eat', $scope.eatCardData.Description, false);
				$scope.updateCardData($scope.slug, 'alternate', 'eat', $scope.eatCardData.AlternateDescription, false);
				$scope.updateCardData($scope.slug, 'primary', 'drink', $scope.drinkCardData.Description, false);
				$scope.updateCardData($scope.slug, 'alternate', 'drink', $scope.drinkCardData.AlternateDescription, false);
				$scope.updateCardData($scope.slug, 'primary', 'attractions', $scope.attractionsCardData.Description, false);
				$scope.updateCardData($scope.slug, 'alternate', 'attractions', $scope.attractionsCardData.AlternateDescription, false);
				$scope.updateCardData($scope.slug, 'primary', 'shopping', $scope.shoppingCardData.Description, false);
				$scope.updateCardData($scope.slug, 'alternate', 'shopping', $scope.shoppingCardData.AlternateDescription, false);
				$scope.updateCardData($scope.slug, 'primary', 'livemusic', $scope.livemusicCardData.Description, false);
				$scope.updateCardData($scope.slug, 'alternate', 'livemusic', $scope.livemusicCardData.AlternateDescription, false);
				$scope.updateCardData($scope.slug, 'primary', 'history', $scope.historyCardData.Description, false);
				$scope.updateCardData($scope.slug, 'primary', 'architecture', $scope.architectureCardData.Description, false);
				$scope.updateCardData($scope.slug, 'primary', 'transportation', $scope.transportationCardData.Description, false);
				$scope.updateCardData($scope.slug, 'primary', 'notablestreets', $scope.notablestreetsCardData.Description, false);
				$scope.updateCardData($scope.slug, 'primary', 'annualevents', $scope.annualeventsCardData.Description, false);
				$scope.updateCardData($scope.slug, 'primary', 'itineraries', $scope.itinerariesCardData.Description, false);
				$timeout(function() {
					toastr.success('All Content Updated', {
						iconClass: 'toast-blue'
					})
				}, 250);
			}
		}
	});
})();