
angular.module('FYN').controller('AuthController', function($scope, $auth, $state, $window, $location, $timeout,AuthService,ResultsService,NewsletterService,toastr) {


	$scope.error = {
		register: {
			message: null,
			email: null
		}
	};

	$scope.info = {};

	$scope.success = false;
	$scope.user = {};
	$scope.auth = {
		register: {
			email: null,
			password: null,
			confirmpassword: null,
			handle: null,
			zipcode: null,
			newsletter: null
		}
	};
	$scope.stepOne = true;
	$scope.stepTwo = false;

	$scope.loginButtonText = 'Let\'s Go!';
	$scope.registerButtonText = 'Continue';
	$scope.passwordResetButtonText = 'Submit';

	// Check for auth on every view
	$scope.check = function() {
		return AuthService.check();
	}

	$scope.isAdmin = function() {
		return AuthService.isAdmin();
	}

	$scope.like = function() {
		if(!$scope.check()) {
			$scope.openLoginModal();
			return false;
		}
		//alert('liking');
	}







	if($scope.check()) {
		AuthService.getLoggedInUser().then(function(res) {
			$scope.user = res.data;
		});

		$scope.getUserId = function() {
			return $scope.user.id;
		}

	}









	$scope.getLoggedInUser = function() {
		AuthService.getLoggedInUser().then(function(res) {
			$scope.user = res.data;
		});
	}




	$scope.resetLoginForm = function() {

		$scope.loginButtonText = 'Let\'s Go!';
		$scope.error = {};
		$scope.auth = {};

	}



	$scope.socialLogin = function(provider) {

		AuthService.socialLogin(provider).then(function(res) {

			$window.ga('send','event','Auth','Login');

			$scope.data.user = res.data.user;
			$scope.data.likes = res.data.likes;


			console.log($scope.data.likes);

			$scope.resetLoginForm();
			$scope.closeAllModals();
			// Need to present user with modal so they can enter their handle
			if(res.data.initial) {
				$scope.openHandleDialog();
			}
			else {
				if($scope.data.user.handle) {
					toastr.info('Welcome back '+$scope.data.user.handle+'!', {
						iconClass: 'toast-blue'
					});
				}
				else {
					toastr.info('Welcome back!',  {
						iconClass: 'toast-blue'
					});
				}
			}
		})
		.catch(function(response) {
			switch(response.statusText) {
				case 'Unauthorized':
				$scope.loginButtonText = 'Try again';
				$scope.error.message = response.data.message;
				break;
			}
		});
	}


	$scope.socialRegisterStepTwo = function($event) {
		var credentials = {
			userid: $scope.data.user.id,
			email: $scope.data.user.email,
			handle: $event.currentTarget[0].value,
			newsletter: $event.currentTarget[1].checked,
			zipcode: $event.currentTarget[2].value
		};
		AuthService.addHandle(credentials).then(function(res) {
			$scope.closeAllModals();
			toastr.info('Welcome '+credentials.handle+'!', {
				iconClass: 'toast-blue'
			});
		})
	}


	$scope.resetPassword = function($event) {
		$scope.passwordResetButtonText = 'Working...';
		var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var email = $event.currentTarget[0].value;
		var validEmail = pattern.test(email);

		if(!validEmail) {
			$scope.passwordResetButtonText = 'Submit';
			$scope.notValidEmail = true;
			return false;
		}

		AuthService.resetPassword(email).then(function(res) {
			$scope.passwordResetButtonText = 'Submit';
			$scope.notfound = false;
			$scope.notValidEmail = false;
			$scope.success = true;

		}).catch(function(response) {
			$scope.passwordResetButtonText = 'Submit';
			$scope.notfound = true;

		})
	}


	$scope.passwordmismatch = false;
	$scope.passwordresetsuccess = false;

	$scope.completeResetPassword = function($event) {
		var password = $event.currentTarget[0].value;
		var confirmpassword = $event.currentTarget[1].value;
		var passwordmismatch = password !== confirmpassword;
		if(passwordmismatch) {
			$scope.passwordmismatch = true;
			return false;
		}
		else {
			var credentials = {
				email: $state.params.email,
				verifytoken: $state.params.verifytoken,
				verifyid: $state.params.verifyid,
				password: password,
				confirmpassword: confirmpassword
			}
			AuthService.completeResetPassword(credentials).then(function(res) {
				$scope.passwordresetsuccess = true;
				$timeout(function() {
					$scope.closeAllModals();
				}, 4000)

			});
		}

	}




	$scope.login = function($event, mobile) {
		var mobile = mobile || undefined;
		$scope.errors = false;
		var credentials = {
			username: $event.currentTarget[0].value,
			password: $event.currentTarget[1].value
		};
		var messages = {
			invalidEmail: 'Please enter a valid email address',
			emptyPassword: 'Please enter your password',
			bothEmpty: 'All fields are required'
		}
		var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		var nousername = !pattern.test(credentials.username) || !credentials.username.length;
		var nopassword = !credentials.password || credentials.password.length < 8;
		if(nousername && nopassword) {
			$scope.errors = true;
			$scope.error.message = messages.bothEmpty;
			$scope.usernameError = true;
			$scope.passwordError  = true;
			return false;
		}
		if(nousername) {
			$scope.errors = true;
			$scope.error.message = messages.invalidEmail;
			$scope.usernameError = true;
			return false;
		}
		if(nopassword) {
			$scope.errors = true;
			$scope.error.message = messages.emptyPassword;
			$scope.passwordError = true;
			return false;
		}
		$scope.loginButtonText = 'Checking...';
		AuthService.login(credentials.username, credentials.password).then(function(response) {
			if(response.status === 200) {

				$window.ga('send','event','Auth','Login');

				$scope.data.user = response.data.user;
				$scope.data.likes = response.data.likes;
				if($scope.data.user.fyn_role === 32) {
					$scope.role.admin = true;
				}
				$scope.resetLoginForm();
				$scope.errors = false;
				$scope.working = false;
				$scope.unlockContent();
				if(!mobile) {
					$scope.closeAllModals();
				}
				else {
					$scope.loginDialog = false;
				}
				$scope.usernameError = false;
				$scope.passwordError  = false;
				if($scope.destination) {
					$state.go($scope.destination).then(function(foo) {
						$scope.destination = undefined;
					});
				}
				if($scope.data.user.handle) {
					toastr.success('Welcome back ' + $scope.data.user.handle + '!', {
						iconClass: 'toast-blue'
					});
				}
				else {
					toastr.success('Welcome back!', {
						iconClass: 'toast-blue'
					});
				}
			}
		})
		.catch(function(response) {
			switch(response.statusText) {
				case 'Unauthorized':
				$scope.errors = true;
				$scope.error.message = response.data.message;
				$scope.loginButtonText = 'Try again';
				break;
			}
		});
	}


	if($state.is('passwordresetverify') || $state.is('passwordresetverify-mobile')) {
		$scope.reset.resetDialog = true;
	}




	if($state.is('verify')) {
		var credentials = {
			id: $state.params.id,
			username: $state.params.username,
			token: $state.params.token
		};
		AuthService.verify(credentials).then(function(response) {
		})
	}


	$scope.registerStepOne = function() {

		var credentials = {
			email: 				$scope.auth.register.email,
			password: 			$scope.auth.register.password,
			confirmpassword: 	$scope.auth.register.confirmpassword,
			//handle: 			$scope.auth.register.handle,
			newsletter: 		$scope.auth.register.newsletter,
			zipcode: 			$scope.auth.register.zipcode
		}

		var messages = {
			invalidEmail: 			'Please enter a valid email address',
			emptyEmail: 			'Email address fields cannot be blank',
			passwordLengthError: 	'Password must be at least 8 characters',
			passwordmismatch: 		'Passwords must match'
		}


		var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		var invalidemail = !pattern.test(credentials.email) || credentials.email === null || credentials.email === undefined;
		var passwordLength = credentials.password === null || credentials.password.length < 8;
		var passwordmismatch = credentials.password !== credentials.confirmpassword;



		if(invalidemail) {
			$scope.errors = true;
			$scope.error.register.message = messages.invalidEmail;
			$scope.registerButtonText = 'Try Again';
			return false;
		}

		if(passwordLength) {
			$scope.errors = true;
			$scope.error.register.message = messages.passwordLengthError;
			$scope.registerButtonText = 'Try Again';
			return false;
		}

		if(passwordmismatch) {
			$scope.errors = true;
			$scope.error.register.message = messages.passwordmismatch;
			$scope.registerButtonText = 'Try Again';
			return false;
		}
		$scope.auth.register.nonfyn = false;
		$scope.registerButtonText = 'Checking...';


		AuthService.checkExisting(credentials.email).then(function(res) {

			if(res.data.existing.length !== 0 && res.data.existing[0].fyn_role !== null) {
				$scope.auth.register.nonFynUser = true;
				$scope.errors = true;
				$scope.error.register.message = 'That email is already in use. Please use another.';
				$scope.registerButtonText = 'Try Again';
				return false;
			}
			if(res.data.existing.length !== 0 && res.data.existing[0].fyn_role == null) {
				// user is in the system but not as an FYN user
				$scope.auth.register.nonfyn = true;
			}
			// Move on to step two.
			$scope.errors = false;
			$scope.message=  true;
			$scope.info.message = 'Optional Zip Code and Newsletter Signup';
			$scope.stepOne = false;
			$scope.stepTwo = true;
			$scope.registerButtonText = 'Register';
			return;
		});
	}

	$scope.registerStepTwo = function() {
		var registerObj = $scope.auth.register;

		AuthService.register(registerObj.nonfyn, registerObj.email, registerObj.password, registerObj.handle, registerObj.zipcode, registerObj.newsletter).then(function(response) {
			$window.ga('send','event','Auth','Register');

			if(response.status === 200) {
				$scope.check();
				$scope.loginButtonText = 'Let\'s Go!';
				$scope.success = true;
				$timeout(function() {
					$scope.toggleRegisterDialog();
					$scope.success = false;
				}, 7500)
			}
		})
		.catch(function(response) {
			$scope.error.message = response.data.message;
		});
	}



	$scope.protectedStates = [
		'profile',
		'admin',
		'lists',
		'lists-new',
		'lists-edit',
		'locations',
		'mynola',
		'mynola-mobile'
	];
	var segment = location.pathname.split('/')[1];


	$scope.logout = function(mobile) {
		var mobile = mobile || undefined;
		AuthService.logout();
		$scope.role.admin = false;
		$scope.data.user = {};
		$scope.data.likes = {};

		if(segment === 'admin' || segment === 'mynola') {
			$state.go('home');
		}
		$scope.protectedStates.forEach(function(value) {
			if($state.is(value)) {
				if(mobile) {
					$state.go('home-mobile');
				}
				else {
					$state.go('home');
				}

			}
		});
		toastr.info('You are now logged out.', {
			iconClass: 'toast-blue'
		});
	}
});
