angular.module('FYN').controller('MapController', function(
$rootScope,
$window,
$anchorScroll,
$sce,
$interval,
$filter,
$auth,
$scope,
$compile,
$http,
$timeout,
$state,
$location,
AuthService,
ResultsService,
toastr,
MyNolaService,
MapService,
GeoLocationService) {




	if(Modernizr.touchevents) {
		$scope.touch = true;
		$scope.notTouch = false;

	}

	$scope.$on('$viewContentLoaded', function(event) {
		$window.ga('send', 'pageview', { page: $location.path() });
		//$window._gaq.push(['_trackPageView', $location.url()]);
	});



	$scope.loadThumbnailDesktop = function($event, $index) {
		var src = $event.target.src;
		angular.element('.stage-thumbs img').removeClass('active');
		angular.element($event.target).removeClass('active');
		angular.element('.stage').css('background-image','url('+src+')');


	}

    $scope.loadThumbnail = function($event, $index) {

    	//var src = $event.target.src;
    	var idx = $index + 1;
/*    	angular.element('.stage-thumbs img').removeClass('active');
    	angular.element($event.target).addClass('active');
*/    	angular.element('.swiper-pagination span:nth-child('+idx+')').trigger('click');
    		
    }


	$scope.getSlideIndex = function($index, direction) {

		if(direction === 'left') {
			// decrement
		}
		if(direction === 'right') {
			// increment

		}

		var idx = $index + 1;
		angular.element('.stage-thumbs img').removeClass('active');
		angular.element('.stage-thumbs img:nth-child('+$index+')').addClass('active');

	}


	


	$scope.swipeLeft = function($event, lid) {

		sliderPosition = 0;
		var locationsArr = [];
		$scope.locations.forEach(function(v) {
			locationsArr.push(v.LocationID);
		});
		var current = locationsArr.indexOf(lid)+1;
		var next = locationsArr[locationsArr.indexOf(lid)+1];
		var end = locationsArr.length;
		if(current === end) {
			return false;
		}
		audio.pause();
		$scope.audioPaused = true;
	
		var el = angular.element($event.currentTarget).closest('.mapDialog');

		el.css('transform','translateX(-100%)');
		
		setTimeout(function() {
			el.css('opacity',0);
		},150);
		setTimeout(function() {
			el.css('transform','translateX(100%)');
		}, 300);
		setTimeout(function() {
			$scope.ml = false;
			$scope.tourLocationBanners = [];
			var listid = $scope.locations[0].list_id;

			ResultsService.getTourLocation(next, listid).then(function(response) {
				$scope.tourLocation = response.data[0];
				$scope.altDescription = $sce.trustAsHtml($scope.tourLocation.AltDescription);			
				
				ResultsService.getTourMedia($scope.tourLocation.list_id, $scope.tourLocation.location_id).then(function(res) {
					$scope.tourLocationBanners = $filter('filter')(res.data, { mediaTypeID: 2 });
					$scope.numBanners = $scope.tourLocationBanners.length -1;				
					if($scope.numBanners > 0) {
						$scope.showArrows = true;
					}
					else {
						$scope.showArrows = false;
					}
					$scope.tourLocationAudio = $filter('filter')(res.data, { mediaTypeID: 4 });

					if($scope.tourLocationAudio.length) {
						$scope.tla = $sce.trustAsResourceUrl('/uploads/audio/'+$scope.tourLocationAudio[0].filename);
						audio.setAttribute('src', $scope.tla);
					}
					$scope.foo = true;
					$scope.resultsLoaded = true;
					$timeout(function() {
						el.css('opacity',1)
					}, 100);

					$timeout(function() {
						//$scope.ml = true;
						$scope.contentLoaded = true;
						$scope.gotoSlide(0);
					}, 100);
					$timeout(function() {
						$scope.tourLocationIndex = $scope.tourLocationIndex + 1;
						el.css('transform','translateX(0)');
					}, 100);		
				});
			});
		}, 450);
		return false;		
	}


	$scope.swipeRight = function($event, lid) {

		sliderPosition = 0;
		var locationsArr = [];
		$scope.locations.forEach(function(v) {
			locationsArr.push(v.LocationID);
		});
		var current = locationsArr.indexOf(lid)+1;
		var next = locationsArr[locationsArr.indexOf(lid)-1];

		if($scope.tourLocationIndex == 1) {
			return false;

		}

		audio.pause();
		$scope.audioPaused = true;
		/*
		if($scope.hasAudio) {
			progressbar.setAttribute('value', 0);		
		}
		*/
		
		var el = angular.element($event.currentTarget).closest('.mapDialog');

		el.css('transform','translateX(100%)');
		
		setTimeout(function() {
			el.css('opacity',0);
		},150);
		setTimeout(function() {
			el.css('transform','translateX(-100%)');
		}, 300);
		setTimeout(function() {

			$scope.ml = false;
			$scope.tourLocationBanners = [];

			var listid = $scope.locations[0].list_id;


			ResultsService.getTourLocation(next, listid).then(function(response) {

				$scope.tourLocation = response.data[0];
				$scope.altDescription = $sce.trustAsHtml($scope.tourLocation.AltDescription);			
				
				ResultsService.getTourMedia($scope.tourLocation.list_id, $scope.tourLocation.location_id).then(function(res) {
					$scope.tourLocationBanners = $filter('filter')(res.data, { mediaTypeID: 2 });
					$scope.numBanners = $scope.tourLocationBanners.length -1;				
					if($scope.numBanners > 0) {
						$scope.showArrows = true;
					}
					else {
						$scope.showArrows = false;
					}
					$scope.tourLocationAudio = $filter('filter')(res.data, { mediaTypeID: 4 });

					if($scope.tourLocationAudio.length) {
						$scope.tla = $sce.trustAsResourceUrl('/uploads/audio/'+$scope.tourLocationAudio[0].filename);
						audio.setAttribute('src', $scope.tla);
					}

					$scope.foo = true;
					$scope.resultsLoaded = true;
					$timeout(function() {
						el.css('opacity',1)
				
					}, 100);

					$timeout(function() {
						//$scope.ml = true;
						$scope.contentLoaded = true;
						$scope.gotoSlide(0);						
					}, 100);
					$timeout(function() {
						$scope.tourLocationIndex = $scope.tourLocationIndex - 1;

						el.css('transform','translateX(0)')
					}, 100);		
				});
			});
		}, 450);
		return false;








		/*
		sliderPosition = 0;
		angular.element($event.currentTarget).closest('.mapDialog').addClass('fadedR');
		$scope.ml = false;
		var next = $scope.locations[$scope.currentLoadedIndex - 1].LocationID;
		$scope.tourLocationBanners = [];		
		var listid = $scope.locations[0].list_id;
		ResultsService.getTourLocation(next, listid).then(function(response) {
			$scope.tourLocation = response.data[0];
			$scope.altDescription = $sce.trustAsHtml($scope.tourLocation.AltDescription);
			ResultsService.getTourMedia($scope.tourLocation.list_id, $scope.tourLocation.location_id).then(function(res) {
				$scope.tourLocationBanners = $filter('filter')(res.data, { mediaTypeID: 2 });

				$scope.numBanners = $scope.tourLocationBanners.length -1;
				
				if($scope.numBanners > 0) {
					$scope.showArrows = true;
				}
				else {
					$scope.showArrows = false;
				}

				$scope.tourLocationAudio = $filter('filter')(res.data, { mediaTypeID: 4 });
				$scope.tla = $sce.trustAsResourceUrl('/uploads/audio/'+$scope.tourLocationAudio[0].filename);
				audio.setAttribute('src', $scope.tla);
				$scope.foo = true;
				$scope.resultsLoaded = true;
				$scope.contentLoaded = true;
				$timeout(function() {
					$scope.ml = true;	
				})				
				angular.element('.mapDialog').removeClass('fadedR');				
				$scope.currentLoadedIndex = $scope.currentLoadedIndex - 1;
			});
		});
		*/
	}



	$scope.goToLocation = function(lid) {
		$window.location.href = '/location/'+lid;
	}

	if($state.is('location')) {
		var lid = $state.params.locationid;

		ResultsService.getLocation(lid).then(function(res) {



    		ResultsService.getLocationSlides(lid).then(function(response) {



    			$scope.slides = response.data;

    			
    			$scope.location = res.data.location;

				$scope.desktopMarker = new google.maps.Marker({
					position: new google.maps.LatLng($scope.location.GeoLatitude, $scope.location.GeoLongitude),
					icon: $scope.mapMarkerUrl,
					//index: i,
					//zIndex: i,
					//customLocation: v.CustomLocation,
					customLocationImage: '/img/nool/map_squares/'+$scope.location.LocationID+'.jpg',
					title: $scope.location.AlphaSortingName,
					locationID: $scope.location.LocationID,
					map: $scope.desktopMap,
				});

				$scope.desktopMarkers.push($scope.desktopMarker);
				var bounds = new google.maps.LatLngBounds();

				$scope.desktopMarkers.forEach(function(v,i) {
					if($scope.desktopMarkers[i].getVisible()) {
						bounds.extend( $scope.desktopMarkers[i].getPosition() );
					}
				})
				$scope.desktopMap.fitBounds(bounds);
				$scope.mouseoverMarker();
				
/*				if(i === ($collection.length - 1)) {
					$scope.removeOverlay = true;
				}
*/

    			$scope.mapDialog = true;
    			$scope.mapDialogContent = true;
    			$scope.resultsloaded = true;

    		});

		})

	}




	var locs = []; // ?


	$rootScope.mapView = true;

	$scope.desktopMapElement = document.getElementById('dMap');

	$scope.desktopMap = new google.maps.Map($scope.desktopMapElement, {
		center: {
			lat: 29.925775,
			lng: -90.0978125
		},
		styles: [
			{
				"featureType":"landscape",
				"stylers": [
					{"hue":"#FFBB00"},
					{"saturation":43.400000000000006},
					{"lightness":37.599999999999994},
					{"gamma":1}
				]
			},
			{
				"featureType":"road.highway",
				"stylers":[
					{"hue":"#FFC200"},
					{"saturation":-61.8},
					{"lightness":45.599999999999994},
					{"gamma":1}
				]
			},
			{
				"featureType":"road.arterial",
				"stylers":[
					{"hue":"#FF0300"},
					{"saturation":-100},
					{"lightness":51.19999999999999},
					{"gamma":1}
				]
			},
			{
				"featureType":"road.local",
				"stylers":[
					{"hue":"#FF0300"},
					{"saturation":-100},
					{"lightness":52},
					{"gamma":1}
				]
			},
			{
				"featureType":"water",
				"stylers":[
					{"hue":"#0078FF"},
					{"saturation":-13.200000000000003},
					{"lightness":2.4000000000000057},
					{"gamma":1}
				]
			},
			{
				"featureType":"poi",
				"elementType":"labels",
				"stylers":[
					{"visibility":"off"}
				]
			}
		],
		zoom: 14,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		disableDefaultUI: true
	});



	$scope.desktopMarker;
	$scope.desktopMarkers = [];
	$scope.desktopInfoBoxes = [];

	$scope.infoboxOptions = {
		disableAutoPan: false,
		maxWidth: 0,
		pixelOffset: new google.maps.Size(-77.5, -220),
		zIndex: null,
		boxStyle: {
			padding: "0px 0px 0px 0px",
			width: "155px",
			height: "155px"
		},
		closeBoxURL : "",
		infoBoxClearance: new google.maps.Size(1, 1),
		isHidden: false,
		pane: "floatPane",
		enableEventPropagation: false
	}

	$scope.infobox = new InfoBox($scope.infoboxOptions);

	$scope.squareUrl = '/img/nool/map_squares/';
	$scope.thumbnailUrl = '/img/nool/sidebar_thumbs/';
	$scope.mapMarkerUrl = '/img/marker-secondary.png';
	$scope.myLocationMarker = '/img/my-location-marker.png';
	$scope.mapMarkerMyLocationUrl = '/img/marker-primary.png';

	




	/* PLOT MAP MARKERS */

	$scope.plotMapMarkers = function($collection) {
		if($state.is('tour') || $state.is('tour-mobile')) {
			$collection.forEach(function(v,i) {

				

				$scope.desktopMarker = new google.maps.Marker({
					position: new google.maps.LatLng(v.AltGeoLatitude, v.AltGeoLongitude),
					icon: $scope.mapMarkerUrl,
					index: i,
					zIndex: i,
					customLocation: v.CustomLocation,
					customLocationImage: v.AltThumbnail,
					title: v.AltTitle,
					locationID: v.LocationID,
					map: $scope.desktopMap,
					tour: location.href.split('/')[4] === 'tours'
				});
				$scope.desktopMarkers.push($scope.desktopMarker);
				var bounds = new google.maps.LatLngBounds();
				$scope.desktopMarkers.forEach(function(v,i) {
					if($scope.desktopMarkers[i].getVisible()) {
						bounds.extend( $scope.desktopMarkers[i].getPosition() );
					}
				})
				$scope.desktopMap.fitBounds(bounds);
				if(i === ($collection.length - 1)) {
					$scope.removeOverlay = true;
				}
			});
		}
		 else {
			$collection.forEach(function(v,i) {

				$scope.desktopMarker = new google.maps.Marker({
					position: new google.maps.LatLng(v.GeoLatitude, v.GeoLongitude),
					icon: $scope.mapMarkerUrl,
					index: i,
					zIndex: i,
					customLocation: v.CustomLocation,
					customLocationImage: (v.media && v.media.length) ? v.media[0].filename : v.ThumbnailImage,
					title: v.LocationName1,
					locationID: v.LocationID,
					majorCategory: v.Value,
					accommodations: v.Accommodations,
					restaurant: v.Restaurant,
					nightlife: v.NightlifeVenue,
					attractions: v.Attractions,
					shopping: v.Shopping,
					map: $scope.desktopMap,
					tour: location.href.split('/')[4] === 'tours'
				});
				$scope.desktopMarkers.push($scope.desktopMarker);

				var bounds = new google.maps.LatLngBounds();

				$scope.desktopMarkers.forEach(function(v,i) {
					if($scope.desktopMarkers[i].getVisible()) {
						bounds.extend( $scope.desktopMarkers[i].getPosition() );
					}
				})
				$scope.desktopMap.fitBounds(bounds);
				if(i === ($collection.length - 1)) {
					$scope.removeOverlay = true;
				}
			});

		 }

	}
	$scope.mouseoverMarker = function() {
		
		$scope.desktopMarkers.forEach(function(marker, index) {


			var className;			
			if(marker.majorCategory == 'Accommodations') { className = 'stay' }
			else if(marker.majorCategory == 'Restaurants') { className = 'eat' }
			else if(marker.majorCategory == 'Nightlife') { className = 'drink' }
			else if(marker.majorCategory == 'Attractions') { className = 'attractions' }
			else if(marker.majorCategory == 'Shopping') { className = 'shopping' }
			else { className = 'default' }

			if($state.is('list')|| $state.is('list-mobile')|| $state.is('search') || $state.is('map-custom') || $state.is('mynola')|| $state.is('mynola-mobile')) {
			
				if(marker.customLocation) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+', $event)">'+
										'<p>'+marker.title+'</p>'+
										'<img onerror="this.src=\'/img/square-default-'+className+'.png\'" src="/uploads/customlocations/thumbnails/'+marker.customLocationImage+'" />'+
										'<div class="triangle-bottom"></div>'+
										'<div class="infobox-marker">'+
											'<img src="/img/square-overlay-'+className+'.png" />'+
										'</div>'+
									'</div>';
				}
				else {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+', $event)">'+
								  		'<p>'+marker.title+'</p>'+
								  		'<img onerror="this.parentNode.classList.add(\'noIconImage\');this.src=\'/img/square-default-'+className+'.png\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" />'+
								  		'<div class="triangle-bottom"></div>'+
								  		'<div class="infobox-marker">'+
								  			'<img src="/img/square-overlay-'+className+'.png" />'+
								  		'</div>'+
								  	'</div>';
				}
			}
			else if($state.is('tour')) {
				var content =	'<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+', $event)">'+
									'<p>'+marker.title+'</p>'+
									'<img onerror="this.src=\'/img/square-default-'+className+'.png\'" src="'+marker.customLocationImage.replace('/thumbnails','/squares')+'" />'+
									'<div class="triangle-bottom"></div>'+
								'</div>';
			}
			else if($state.is('tour-mobile')) {
				var content =	'<div class="infobox" ng-click="showTourLocationDialog('+marker.locationID+', $event)">'+
									'<p>'+marker.title+'</p>'+
									'<img onerror="this.src=\'/img/square-default-'+className+'.png\'" src="'+marker.customLocationImage.replace('/thumbnails','/squares')+'" />'+
									'<div class="triangle-bottom"></div>'+
								'</div>';
			}
			else {
				var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+', $event)"><p>'+marker.title+'</p><img onerror="this.src=\'/img/square-default-'+className+'.png\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
			}

			var compiled = $compile(content)($scope);

			marker.addListener('mouseover', function() {
				marker.setOptions({ zIndex: index + ($scope.desktopMarkers.length + 1) });
				$scope.infobox.setContent(compiled[0]);
				$scope.infobox.open($scope.desktopMap, this);
				$scope.currentPopupIndex = this.index;
			});
			marker.addListener('mouseout', function() {
				marker.setOptions({ zIndex: index });
				$scope.infobox.setMap(null);
			});
			marker.addListener('click', function($event) {

				var $this = this;

				angular.element('li').removeClass('active');
				angular.element('li[data-locationid='+this.locationID+']').addClass('active');
				angular.element('li[data-locationid='+this.locationID+']').find('.arrow-left').addClass('active');

				//$scope.infobox.setMap(null);
				

				//if($state.is('tour') || $state.is('tour-mobile')) {
				if($state.is('tour-mobile')) {
					$scope.infobox.open($scope.desktopMap, $this);
					//$scope.showTourLocationDialog(this.locationID, $scope.listMeta.id, $event, this);
				}

				else if($state.is('tour')) {
					$scope.infobox.close();
					$scope.showTourLocationDialog(this.locationID, $scope.listMeta.id, $event, this);
				}
				
				
				else if($state.is('map-custom-mobile') || $state.is('list-mobile') || $state.is('mynola-mobile') || $state.is('search-mobile') || $state.is('eat-mobile') || $state.is('stay-mobile') || $state.is('drink-mobile') || $state.is('attractions-mobile') || $state.is('shopping-mobile')) {
					$scope.infobox.open($scope.desktopMap, $this);
					//$scope.showMobileLocationDialog(this.locationID, $event, this);
				}
				else {
					
					$scope.showLocationDialog(this.locationID, $event, this);
				}

			});
		});
	}

	$scope.mouseoverResult = function(lid, $event) {
		$scope.desktopMarkers.forEach(function(v,i) {
			if(v.locationID === lid) {
				google.maps.event.trigger($scope.desktopMarkers[i], 'mouseover');
			}

		})
	}

	$scope.mouseoutResult = function(lid, $event) {
		var result = $filter('filter')($scope.desktopMarkers, { locationID: lid });
		google.maps.event.trigger(result[0], 'mouseout');

	}

	$scope.mapControlNeighborhoodsActive = false;
	$scope.mapControlStreetcarsActive = false;
	$scope.mapControlAirportActive = false;

	$scope.hoverMapControlNeighborhoods = function(e) {
		/*
		if(Modernizr.touchevents) {
			return false;
		}
		*/
		$scope.mapControlNeighborhoodsActive = !$scope.mapControlNeighborhoodsActive;

	}

	$scope.hoverMapControlStreetcars = function(e) {
		if(Modernizr.touchevents) {
			return false;
		}
		$scope.mapControlStreetcarsActive = !$scope.mapControlStreetcarsActive;
	}

	$scope.hoverMapControlAirport = function(e) {
		if(Modernizr.touchevents) {
			return false;
		}
		$scope.mapControlAirportActive = !$scope.mapControlAirportActive;
	}


	$scope.currentZoomLevel = $scope.desktopMap.getZoom();

	$scope.desktopMapZoom = function(direction) {
		switch(direction) {
			case 'in':
			$scope.desktopMap.setZoom($scope.desktopMap.getZoom() + 1);
			$scope.currentZoomLevel = $scope.desktopMap.getZoom();
			break;
			case 'out':
			$scope.desktopMap.setZoom($scope.desktopMap.getZoom() - 1);
		}
	}


	$scope.desktopMapPan = function(direction) {
		switch(direction) {
			case 'up':
			$scope.desktopMap.panBy( 0, -100 );
			break;
			case 'down':
			$scope.desktopMap.panBy( 0, 100 );
			break;
			case 'left':
			$scope.desktopMap.panBy( -100, 0 );
			break;
			case 'right':
			$scope.desktopMap.panBy( 100, 0 );
			break;
		}
	}

	$scope.clearMapMarkers = function() {
		for (var i = 0; i < $scope.desktopMarkers.length; i++) {
			$scope.desktopMarkers[i].setMap(null);
		}
	}

	$scope.assignDefaultImage = function(locationType) {
		switch(locationType) {
			case 'stay':
			return '/img/icon-default-stay.png';
			break;

			case 'eat':
			return '/img/icon-default-eat.png';
			break;

			case 'drink':
			return '/img/icon-default-drink.png';
			break;

			case 'attractions':
			return '/img/icon-default-attractions.png';
			break;

			case 'shopping':
			return '/img/icon-default-shopping.png';
			break;

			default:
			return '/img/fluer-default.png';
		}
	}






	if($scope.data.locale) {
		$scope.userLat = $scope.data.locale.latitude;
		$scope.userLng = $scope.data.locale.longitude;
	}


	$scope.calculateDistance = function(geoLat, geoLng, unit) {
		var radlat1 = Math.PI * $scope.userLat / 180
		var radlat2 = Math.PI * geoLat / 180
		var radlon1 = Math.PI * $scope.userLng / 180
		var radlon2 = Math.PI * geoLng / 180
		var theta = $scope.userLng - geoLng
		var radtheta = Math.PI * theta / 180
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

		dist = Math.acos(dist)
		dist = dist * 180 / Math.PI
		dist = dist * 60 * 1.1515

		if (unit=="K") { dist = dist * 1.609344 }
		if (unit=="N") { dist = dist * 0.8684 }

		return dist.toFixed(2);

	}




	$scope.shareFacebook = function() {
		u = location.href;
		t = document.title;
		window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(u)+'&t='+encodeURIComponent(t),'sharer','toolbar=0,status=0,width=626,height=436');
		return false;
	}


	/* ===== TWITTER ===== */
	$scope.shareTwitter = function(resultsHeader) {
		var data = { status: resultsHeader };
		var share_url = "http://twitter.com/share?text=" + data.status+"&url="+location.href+"&hashtags=followyournola";
		window.open(share_url, 'twitter', 'toolbar=0,status=0,width=620,height=280');
		return false;
	}



	$scope.convertDate = function(dateString) {
		var newDate = new Date(dateString);
		return (newDate.getMonth() + 1)+'/'+newDate.getDate()+'/'+newDate.getFullYear();
	}





	// CHECKBOX FILTERS ON UNGROUPED RESULTS
	var tempAccommodationsArray = [];
	var tempAccommodationsMarkerArray = [];
	var tempRestaurantsArray = [];
	var tempRestaurantsMarkerArray = [];
	var tempNightlifeArray = [];
	var tempNightlifeMarkerArray = [];
	var tempAttractionsArray = [];
	var tempAttractionsMarkerArray = [];
	var tempShoppingArray = [];
	var tempShoppingMarkerArray = [];
	var tempOffersArray = [];
	var tempOffersMarkerArray = [];



	$scope.loadMobileListing = function(locationid) {
		$scope.mobileListingActive = true;
	}



	$scope.checkIfLiked = function(locationid) {
		if($scope.data.likes.length) {
			var f = $filter('filter')($scope.data.likes, { LocationID: locationid }, true);
			if(!f.length) {
				return false;
			}
			return true;
		}
	}

	/* Add Tips Functionality */
	$scope.tipInputShowing = false;

	$scope.showTipInput = function() {
		$scope.tipInputShowing = true;
	}
	$scope.hideTipInput = function() {
		$scope.tipInputShowing = false;
	}



	$scope.checkboxCount;

	$scope.filterAccommodations = function($event) {
		$scope.checkboxCount = $('.checkbox-filters input:checked').length;
		var i = $scope.data.results.locations.length;
		var checkboxState = $event.currentTarget.checked;

		if(!checkboxState) { // We just unchecked the box
			$scope.filtersActive = true;
			while(i--) {
				if($scope.data.results.locations[i].Accommodations) {
					$scope.clearMapMarkers();
					tempAccommodationsArray.push({ index: i, value: $scope.data.results.locations[i] });
					$scope.data.results.locations.splice(i, 1);
					$scope.plotMapMarkers($scope.data.results.locations);
				}
			}
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}
		}
		else {
			$scope.filtersActive = false;
			tempAccommodationsArray.forEach(function(v) {
				$scope.data.results.locations.splice(v.index, 0, v.value);
			})
			tempAccommodationsArray.length = [];
			$scope.plotMapMarkers($scope.data.results.locations);
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}
		}
		$scope.data.results.locations = $scope.data.results.locations;
	}



	$scope.filterRestaurants = function($event) {
		$scope.checkboxCount = $('.checkbox-filters input:checked').length;
		var i = $scope.data.results.locations.length;
		var checkboxState = $event.currentTarget.checked;

		if(!checkboxState) {
			$scope.filtersActive = true;
			while(i--) {
				if($scope.data.results.locations[i].Restaurant) {
					$scope.clearMapMarkers();
					tempRestaurantsArray.push({ index: i, value: $scope.data.results.locations[i] });
					$scope.data.results.locations.splice(i, 1);
					$scope.plotMapMarkers($scope.data.results.locations);
				}
			}
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}

		}
		else {
			$scope.filtersActive = false;
			tempRestaurantsArray.forEach(function(v) {
				$scope.data.results.locations.splice(v.index, 0, v.value);
			})
			tempRestaurantsArray.length = [];
			$scope.plotMapMarkers($scope.data.results.locations);
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}

		}
		$scope.data.results.locations = $scope.data.results.locations;
	}





	$scope.filterNightlife = function($event) {
		$scope.checkboxCount = $('.checkbox-filters input:checked').length;
		var i = $scope.data.results.locations.length;
		var checkboxState = $event.currentTarget.checked;

		if(!checkboxState) {
			$scope.filtersActive = true;
			while(i--) {
				if($scope.data.results.locations[i].NightlifeVenue) {
					$scope.clearMapMarkers();
					tempNightlifeArray.push({ index: i, value: $scope.data.results.locations[i] });
					$scope.data.results.locations.splice(i, 1);
					$scope.plotMapMarkers($scope.data.results.locations);
				}
			}
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}

		}
		else {
			$scope.filtersActive = false;
			tempNightlifeArray.forEach(function(v) {
				$scope.data.results.locations.splice(v.index, 0, v.value);
			})
			tempNightlifeArray.length = [];
			$scope.plotMapMarkers($scope.data.results.locations);
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}

		}
		$scope.data.results.locations = $scope.data.results.locations;
	}







	$scope.filterAttractions = function($event) {
		$scope.checkboxCount = $('.checkbox-filters input:checked').length;
		var i = $scope.data.results.locations.length;
		var checkboxState = $event.currentTarget.checked;

		if(!checkboxState) {
			$scope.filtersActive = true;
			while(i--) {
				if($scope.data.results.locations[i].Attractions) {
					$scope.clearMapMarkers();
					tempAttractionsArray.push({ index: i, value: $scope.data.results.locations[i] });
					$scope.data.results.locations.splice(i, 1);
					$scope.plotMapMarkers($scope.data.results.locations);
				}
			}
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}

		}
		else {
			$scope.filtersActive = false;
			tempAttractionsArray.forEach(function(v) {
				$scope.data.results.locations.splice(v.index, 0, v.value);
			})
			tempAttractionsArray.length = [];
			$scope.plotMapMarkers($scope.data.results.locations);
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}

		}
		$scope.data.results.locations = $scope.data.results.locations;
	}

	$scope.filterShopping = function($event) {
		$scope.checkboxCount = $('.checkbox-filters input:checked').length;
		var i = $scope.data.results.locations.length;
		var checkboxState = $event.currentTarget.checked;
		if(!checkboxState) {
			while(i--) {
				$scope.filtersActive = true;
				if($scope.data.results.locations[i].Shopping) {
					$scope.desktopMarkers[i].setMap(null);
					tempShoppingArray.push({ index: i, value: $scope.data.results.locations[i] });
					$scope.data.results.locations.splice(i, 1);
				}
			}
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}

		}
		else {
			$scope.filtersActive = false;
			tempShoppingArray.forEach(function(v) {
				$scope.data.results.locations.splice(v.index, 0, v.value);
			})
			tempShoppingArray.length = [];
			$scope.plotMapMarkers($scope.data.results.locations);
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}

		}
		$scope.data.results.locations = $scope.data.results.locations;
	}

	$scope.filterOffers = function($event) {
		$scope.checkboxCount = $('.checkbox-filters input:checked').length;
		var i = $scope.data.results.locations.length;
		var checkboxState = $event.currentTarget.checked;
		if(!checkboxState) {
			while(i--) {
				$scope.filtersActive = true;
				if($scope.data.results.locations[i].OfferID) {
					$scope.desktopMarkers[i].setMap(null);
					tempOffersArray.push({ index: i, value: $scope.data.results.locations[i] });
					$scope.data.results.locations.splice(i, 1);
				}
			}
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}

		}
		else {
			$scope.filtersActive = false;
			tempOffersArray.forEach(function(v) {
				$scope.data.results.locations.splice(v.index, 0, v.value);
			})
			tempOffersArray.length = [];
			$scope.plotMapMarkers($scope.data.results.locations);
			$scope.mouseoverMarker();
			if($scope.mobile) {
				$scope.desktopMarkers.forEach(function(marker) {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/nool/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
					var compiled = $compile(content)($scope);
					marker.addListener('click', function() {
						$scope.infobox.setContent(compiled[0]);
						$scope.infobox.open($scope.desktopMap, this);
					});
				});
				$scope.desktopMap.addListener('click', function() {
					$scope.infobox.close();
				})
			}

		}
		$scope.data.results.locations = $scope.data.results.locations;
	}









/*	$scope.clearFilters = function(form) {
		switch(form) {
			case 'stay':
				document.querySelector('.stay-form-name').value = '';
				document.querySelector('.stay-form-lodging-type').selectedIndex = 0;
				document.querySelector('.stay-form-neighborhood').selectedIndex = 0;
			break;

			case 'eat':
				document.querySelector('.eat-form-restaurant-name').value = '';
				document.querySelector('.eat-form-style').selectedIndex = 0;
				document.querySelector('.eat-form-price-range').selectedIndex = 0;
				document.querySelector('.eat-form-cuisine-type').selectedIndex = 0;
				document.querySelector('.eat-form-neighborhood').selectedIndex = 0;
				$scope.eat.restaurantName = undefined;
				$scope.eat.attire = 'any-attire';
				$scope.eat.pricerange = 'all-price-ranges';
				$scope.eat.cuisineTypes = 'all-cuisine-types';
				$scope.eat.neighborhood = 'all-neighborhoods';
				$('.whenBtn').each(function(i,v) {
					$(this).removeClass('active');
				});
				$('.anyTime').addClass('active');
				$scope.eat.when = 'any-time';
			break;

			case 'drink':
				document.querySelector('.drink-form-name').value = '';
				document.querySelector('.drink-form-neighborhood').selectedIndex = 0;
				document.querySelector('.drink-form-features').selectedIndex = 0;
			break;

			case 'attractions':
				document.querySelector('.attractions-form-name').value = '';
				document.querySelector('.attractions-form-neighborhood').selectedIndex = 0;
				document.querySelector('.attractions-form-features').selectedIndex = 0;
			break;

			case 'shopping':
				document.querySelector('.shopping-form-name').value = '';
				document.querySelector('.shopping-form-neighborhood').selectedIndex = 0;
				document.querySelector('.shopping-form-features').selectedIndex = 0;
			break;
		}
	}
*/
	$scope.myNolaSort = function($item,$part,$index) {
		var person = $state.params.id;
		MyNolaService.updateSort($part, person).then(function(response) {
			$scope.data.results.locations = $part;
			$scope.desktopMarkers.length = 0;
			$scope.plotMapMarkers($scope.data.results.locations);
			$scope.mouseoverMarker();

			toastr.success('Favorites Updated!', {
				iconClass: 'toast-blue'
			})
		})
	}


	$scope.shuffleArray = function(array) {
		var currentIndex = array.length, temporaryValue, randomIndex;
		// While there are elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element.
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}
		return array;
	}







	// STATES
	//
	//
	//
	//


	$scope.mapView.on = true;



	$scope.goToUrl = function(url) {

		$window.location.href = '/location/'+url;
	}





	if($state.is('map')) {
		$rootScope.pageTitle = 'Discover Your New Orleans: Customize your journey through the city';

		$scope.data.markers = [];

	}




	var resultsList = angular.element('.results-list');
	var resultsListHeight = resultsList.height();
	var resultsListItemHeight = 75;

	var visibleItemCount = Math.ceil(resultsListHeight / resultsListItemHeight);

	$scope.checkIsCustom = function() {
		if($state.is('map-custom')) {
			return true;
		}
	}

	if($state.is('map-form-custom')) {
		$scope.data.resultsHeader = 'Customize Your Journey';
		$scope.mapCustomActive = true;
	}
	if($state.is('map-form-stay')) {
		$scope.data.resultsHeader = 'Where to Stay';
		$scope.mapStayActive = true;
	}
	if($state.is('map-form-eat')) {
		$scope.data.resultsHeader = 'Where to Eat';
		$scope.mapEatActive = true;
	}
	if($state.is('map-form-drink')) {
		$scope.data.resultsHeader = 'Where to Drink';
		$scope.mapDrinkActive = true;
	}
	if($state.is('map-form-attractions')) {
		$scope.data.resultsHeader = 'Things to See and Do';
		$scope.mapAttractionsActive = true;
	}
	if($state.is('map-form-shopping')) {
		$scope.data.resultsHeader = 'Where to Shop';
		$scope.mapShoppingActive = true;
	}


	if($state.is('map-custom') || $state.is('map-custom-mobile')) {

		$scope.mapCustom = true;
		$scope.mapCustomActive = true;
		$scope.data.markers.length = 0;
		$scope.mapShowing.showing = true;
		$scope.data.resultsHeader = 'Your Custom Journey';
		$scope.data.results.locations = null;
		$scope.doNotShowDistance = true;
		$scope.tempBoundsArray = [];

		// put the tags back into an array
		var tags = $state.params.tags.split('+');

		if($state.is('map-custom-mobile')) {
			$scope.mobile = true;
			$scope.showCheckboxFilters = true;
			$rootScope.pageTitle = 'Your Custom Journey';
			ResultsService.getCustomList($state.params.tags).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;

				$scope.listview = true;
				$scope.isList = true;
				$scope.resultsListLoaded = true;
				$scope.customresults = true;
			});
		}
		if($state.is('map-custom')) {
			$rootScope.pageTitle = 'Your Custom Journey';
			ResultsService.getCustomList($state.params.tags).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$scope.calculateResultsListHeight();
			});
		}
	}




	$scope.calculateResultsListHeight = function() {
		var resultsHeadHeight = $('.map-left-panel .head').outerHeight();
		var resultsFootHeight = ($scope.showShareControlsInFooter ? $('.map-left-panel .foot').outerHeight() : 0);
		var sum = resultsHeadHeight + resultsFootHeight;
		if($state.is('tour')) {
			sum = sum + 190;

		}
		$('.results-list').css('height','calc(100% - '+ (sum) +'px)');
	}

	$scope.listview = true;
	$scope.mapview = false;

	$scope.loadListView = function() {
		angular.element('.listview').addClass('active');
		angular.element('.mapview').removeClass('active');
		$scope.listview = true;
		$scope.mapview = false;
	}




	$scope.loadMapView = function($event) {


		//var f = location.href.split('/')[5];
		

		angular.element('.mapview').addClass('active');
		angular.element('.listview').removeClass('active');
		
		$scope.listview = false;
		$scope.mapview = true;

		$timeout(function() {

			google.maps.event.trigger($scope.desktopMap, 'resize');

			if($state.is('tour') || $state.is('tour-mobile')) {
				
				$scope.isTour = true;

				$scope.data.results.locations = $scope.locations;
				
				var waypts = [];
				var markerWaypts = [];
				var directionsService = new google.maps.DirectionsService;
				var directionsDisplay = new google.maps.DirectionsRenderer({
					draggable: false,
					map: $scope.desktopMap,
					suppressMarkers: true,
					polylineOptions: {
						strokeColor: '#3498DB',
						strokeWeight: 6
					}
				});
				directionsDisplay.setMap($scope.desktopMap);
				calculateAndDisplayRoute(directionsService, directionsDisplay);
				function calculateAndDisplayRoute(directionsService, directionsDisplay) {
					$scope.data.results.locations.forEach(function(value, index) {
						if(index !== 0 || index !== $scope.data.results.locations[ $scope.data.results.locations.length -1 ]) {
							waypts.push({ location: { lat: $scope.data.results.locations[ index ].GeoLatitude, lng: $scope.data.results.locations[ index ].GeoLongitude }, stopover: true } );
						}
					});
					$scope.data.results.locations.forEach(function(value, index) {
						markerWaypts.push(
							{
								lat: value.AltGeoLatitude,
								lng: value.AltGeoLongitude,
								label: value.AltTitle,
								map_square: value.ThumbnailImage
							}
						);
					})

					directionsService.route({
						origin: new google.maps.LatLng($scope.data.results.locations[0].GeoLatitude, $scope.data.results.locations[0].GeoLongitude),
						destination: new google.maps.LatLng($scope.data.results.locations[$scope.data.results.locations.length -1].GeoLatitude, $scope.data.results.locations[$scope.data.results.locations.length -1].GeoLongitude),
						waypoints: waypts,
						optimizeWaypoints: true,
						travelMode: 'WALKING'
					},
					function(response, status) {
						if (status === 'OK') {
							directionsDisplay.setDirections(response);
							var route = response.routes[0];
							boxOptions = {
								disableAutoPan: false,
								maxWidth: 0,
								pixelOffset: new google.maps.Size(-77, -230),
								zIndex: null,
								isHidden: false,
								pane: "floatPane",
								enableEventPropogation: false,
								closeBoxMargin: "0",
								closeBoxURL: ""
							}
							infoWindow = new InfoBox(boxOptions);
							var $tourLocations = $('.tour-locations');
							$scope.mouseoverMarker();
						}
						else {
							window.alert('Directions request failed due to ' + status);
						}
					});
					google.maps.event.trigger($scope.desktopMap, 'resize');
				}
			}



			

			$scope.plotMapMarkers($scope.data.results.locations);
			$scope.mouseoverMarker();

			/*
			$scope.desktopMarkers.forEach(function(marker) {


				if(f === 'tours') {

					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img src="/uploads/customlocations/thumbnails/'+marker.customLocationImage+'" /><div class="triangle-bottom"></div></div>';
				}
				else {
					var content = '<div class="infobox" ng-click="showMobileLocationDialog('+marker.locationID+')"><p>'+marker.title+'</p><img onerror="this.src=\'https://www.followyournola.com/img/map_squares/13.jpg\'" src="'+$scope.squareUrl+marker.locationID+'.jpg" /><div class="triangle-bottom"></div></div>';
				}

				var compiled = $compile(content)($scope);
				marker.addListener('click', function() {
					$scope.infobox.setContent(compiled[0]);
					$scope.infobox.open($scope.desktopMap, this);
				});
			});
*/
			$scope.desktopMap.addListener('click', function() {
				$scope.infobox.close();
			})
		}, 100)

	}

	//$scope.currentPopupIndex = 0;
	$scope.nextLocation = function($event) {
		var markerLength = $scope.desktopMarkers.length - 1;
		$scope.desktopMarkers[$scope.currentPopupIndex].infobox.setMap(null);
		if($scope.currentPopupIndex === markerLength) {
			$scope.currentPopupIndex = -1;
		}
		$scope.currentPopupIndex += 1;
		$scope.desktopMarkers[$scope.currentPopupIndex].focus = true;
		var lat = parseFloat($scope.desktopMarkers[$scope.currentPopupIndex].position.lat());
		var lng = parseFloat($scope.desktopMarkers[$scope.currentPopupIndex].position.lng());
		$scope.desktopMap.panTo([lat,lng]);
	}
	$scope.previousLocation = function($event) {
		var markerLength = $scope.data.markers.length - 1;
		$scope.data.markers[$scope.currentPopupIndex].focus = false;
		if($scope.currentPopupIndex === 0) {
			$scope.currentPopupIndex = markerLength;
		}
		else {
			$scope.currentPopupIndex -= 1;
		}
		$scope.data.markers[$scope.currentPopupIndex].focus = true;
		var lat = $scope.data.markers[$scope.currentPopupIndex].lat;
		var lng = $scope.data.markers[$scope.currentPopupIndex].lng;
		mobilemap.panTo([lat,lng]);
	}

	$scope.zoomIn = function() {
		mobilemap.zoomIn();
	}
	$scope.zoomOut = function() {
		mobilemap.zoomOut();
	}



	$scope.locateMe = function() {
		var mylocation = new google.maps.Marker({
			position: {
				lat: parseFloat($scope.data.locale.latitude),
				lng: parseFloat($scope.data.locale.longitude)
			},
			icon: $scope.myLocationMarker,
			map: $scope.desktopMap
		})
		$interval(function() {
			mylocation.setPosition(new google.maps.LatLng($scope.data.locale.latitude,$scope.data.locale.longitude));
		}, 500);
	}



	if($state.is('mynola') || $state.is('mynola-mobile')) {

		$rootScope.location = $location.absUrl();
		$rootScope.pageTitle = 'My NOLA';
		//$scope.authGateway();
		$scope.mynola = true;
		$scope.data.resultsHeader = 'My NOLA';
		$scope.showShareControlsInFooter = true;

		if($scope.data.user.id === parseInt($state.params.id)) {
			$scope.isMyNola = true;
			$scope.isAnotherNola = false;
			$rootScope.pageTitle = 'My NOLA';
			$scope.data.resultsHeader = 'My NOLA';
		}
		else {
			$scope.isMyNola = false;
			$scope.isAnotherNola = true;
			$rootScope.pageTitle = 'Another great NOLA journey';
			$scope.data.resultsHeader = 'Another great NOLA journey';
		}


		if($state.is('mynola-mobile')) {

			$scope.mobile = true;
			$scope.showCheckboxFilters = true;

			//AuthService.getLoggedInUser().then(function(response) {
			MapService.getUserLikes($state.params.id).then(function(response) {

				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = $filter('orderBy')(response.data, 'sortOrder');
				//$scope.data.results.locations = $filter('orderBy')(response.data, 'sort');
				$scope.mouseoverMarker();
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.listview = true;
				$scope.resultsListLoaded = true;
				$scope.isList = true;

				$scope.mapview = false;
			});
		}
		if($state.is('mynola')) {
			if($scope.data.user.id === parseInt($state.params.id)) {
				$scope.isMyNola = true;
				$scope.isAnotherNola = false;
				$rootScope.pageTitle = 'My NOLA';
				$scope.data.resultsHeader = 'My NOLA';
			}
			else {
				$scope.isMyNola = false;
				$scope.isAnotherNola = true;
				$rootScope.pageTitle = 'Another great NOLA journey';
				$scope.data.resultsHeader = 'Another great NOLA journey';
			}

			MapService.getUserLikes($state.params.id).then(function(response) {
				$scope.data.results.locations = $filter('orderBy')(response.data, 'sortOrder');
				$scope.data.results.locations.forEach(function(v) {
					switch(v.PrimaryMajorCategory) {
						case 1:
						v.listingType = 'stay';
						break;
						case 2:
						v.listingType = 'eat';
						break;
						case 3:
						v.listingType = 'drink';
						break;
						case 4:
						v.listingType = 'attractions';
						break;
						case 5:
						v.listingType = 'shopping';
						break;
					}
				});
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.plotMapMarkers($scope.data.results.locations);
				$scope.mouseoverMarker();
				$scope.assignCheckboxFilters($scope.data.results.locations);


				$timeout(function() {
					$scope.calculateResultsListHeight();
					$scope.removeOverlay = true;
				}, 1000)

			});


/*			AuthService.getLoggedInUser().then(function(response) {
				$scope.data.likes = $filter('orderBy')(response.data.likes, 'sort');
				$scope.data.results.locations = $filter('orderBy')(response.data.likes, 'sort');
				$scope.data.results.locations.forEach(function(v) {
					switch(v.PrimaryMajorCategory) {
						case 1:
						v.listingType = 'stay';
						break;
						case 2:
						v.listingType = 'eat';
						break;
						case 3:
						v.listingType = 'drink';
						break;
						case 4:
						v.listingType = 'attractions';
						break;
						case 5:
						v.listingType = 'shopping';
						break;
					}
				})
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.plotMapMarkers($scope.data.likes);
				$scope.mouseoverMarker();
				$scope.assignCheckboxFilters($scope.data.results.locations);

				$scope.calculateResultsListHeight();
				$timeout(function() {
					$scope.removeOverlay = true;
				}, 1000)
			});
*/
		}
	}

	if($state.is('search') || $state.is('search-mobile')) {
		var term = $state.params.term;
		$rootScope.pageTitle = 'Search results for: '+term;

		$scope.mapSearch = true;
		$scope.data.markers = [];
		$scope.tempBoundsArray = [];
		$scope.data.resultsHeader = term;

		var params = {
			latitude: parseFloat($scope.data.locale.latitude) || undefined,
			longitude: parseFloat($scope.data.locale.longitude) || undefined
		}

		if($state.is('search-mobile')) {
			$scope.mobile = true;
			$scope.showCheckboxFilters = true;

			ResultsService.search(term, params).then(function(response) {
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.data.results.locations = response.data.results;

				// Add distance prop to  collection
				if($scope.locationKnown) {
					$scope.data.results.locations.forEach(function(value) {
						value.distance = $scope.calculateDistance(value.GeoLatitude, value.GeoLongitude, 'M')
					})
					// And then sort by it
					$scope.data.results.locations = $filter('orderBy')($scope.data.results.locations, 'distance')
				}


				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.plotMapMarkers($scope.data.results.locations);
				$scope.mouseoverMarker();
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.resultsloaded = true;
				$scope.resultsListLoaded = true;
				$scope.listview = true;
			});
		}

		if($state.is('search')) {

			ResultsService.broadSearch(term, params).then(function(response) {

				if(response.data.error || response.data.customerror) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					$scope.removeOverlay = true;
					$scope.resultsCount = 0;
					google.maps.event.trigger($scope.desktopMap, 'resize');
					return false;
				}
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.plotMapMarkers(response.data.results);
				$scope.mouseoverMarker();
				$scope.assignCheckboxFilters(response.data.results);
				$scope.data.results.locations = response.data.results;
				$scope.resultsCount = $scope.data.results.locations.length;
				$scope.data.results.locations.forEach(function(v) {
					switch(v.PrimaryMajorCategory) {
						case 1:
						v.listingType = 'stay';
						break;
						case 2:
						v.listingType = 'eat';
						break;
						case 3:
						v.listingType = 'drink';
						break;
						case 4:
						v.listingType = 'attractions';
						break;
						case 5:
						v.listingType = 'shopping';
						break;
					}
				})
				$scope.resultsloaded = true;
				$timeout(function() {
					$scope.calculateResultsListHeight();
				},100)
			});
		}
	}

	if($state.is('stay') || $state.is('stay-mobile')) {

		$rootScope.pageTitle = 'Discover Your New Orleans: Find Places To Stay';

		$scope.data.markers = [];
		$scope.tempBoundsArray = [];
		$scope.mapStayActive = true;
		$scope.data.resultsHeader = 'Accommodations';
		$scope.mapCategory = true;


		var params = {
			neighborhood: $state.params.neighborhood,
			lodgingtype: $state.params.lodgingtype,
			amenities: $state.params.amenities,
			name: $state.params.name || undefined
		}





		if($state.is('stay-mobile')) {
			$scope.mobile = true;
			if($scope.isLocal) {
				//params.city = $scope.data.locale.city;
				params.latitude = parseFloat($scope.data.locale.latitude);
				params.longitude = parseFloat($scope.data.locale.longitude);
			}

			ResultsService.accommodations(params).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;

				if($scope.data.locale.latitude) {
					$scope.data.results.locations.forEach(function(v) {
						v.distance = $scope.calculateDistance(v.GeoLatitude, v.GeoLongitude, 'M')
					});
					$scope.data.results.locations = $filter('orderBy')($scope.data.results.locations, 'distance');
				} else {

					$scope.locationUnknown = true;
				}



				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}

				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$scope.resultsListLoaded = true;
				$scope.listview = true;
			});
		}

		if($state.is('stay')) {
			ResultsService.accommodations(params).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;
				$scope.data.results.locations.forEach(function(v) {
					v.listingType = 'stay';
					v.Value = 'Accommodations';

				})
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$timeout(function() {
					$scope.calculateResultsListHeight();
				});
			});
		}
	}

	if($state.is('eat') || $state.is('eat-mobile')) {

		$rootScope.pageTitle = 'Discover Your New Orleans: Find Places To Eat';

		$scope.data.markers = [];
		$scope.tempBoundsArray = [];
		$scope.mapEatActive = true;
		$scope.data.resultsHeader = 'Restaurants';
		$scope.mapCategory = true;


		var params = {
			neighborhood: $state.params.neighborhood,
			cuisinetype: $state.params.cuisinetype,
			attire: $state.params.attire,
			pricerange: $state.params.pricerange,
			when: $state.params.when,
			name: $state.params.name || undefined
		}

		if($state.is('eat-mobile')) {

			$scope.mobile = true;
			if($scope.isLocal) {
				params.latitude = parseFloat($scope.data.locale.latitude);
				params.longitude = parseFloat($scope.data.locale.longitude);
			}
			ResultsService.eat(params).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;

				if($scope.data.locale.latitude) {
					$scope.data.results.locations.forEach(function(v) {
						v.distance = $scope.calculateDistance(v.GeoLatitude, v.GeoLongitude, 'M')
					});
					$scope.data.results.locations = $filter('orderBy')($scope.data.results.locations, 'distance');
				} else {
					$scope.locationUnknown = true;
				}
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$scope.resultsListLoaded = true;
				$scope.listview = true;
				$scope.calculateResultsListHeight();
			});
		}
		if($state.is('eat')) {
			ResultsService.eat(params).then(function(response) {

				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;
				$scope.data.results.locations.forEach(function(v) {
					v.listingType = 'eat';
					v.Value = 'Restaurants';
				})
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations);
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$timeout(function() {
					$scope.calculateResultsListHeight();
				});
			});
		}
	}

	if($state.is('drink') || $state.is('drink-mobile')) {

		$rootScope.pageTitle = 'Discover Your New Orleans: Find Nightlife';
		$scope.mapDrinkActive = true;
		$scope.data.resultsHeader = 'Nightlife';
		$scope.mapCategory = true;

		var params = {
			neighborhood: $state.params.neighborhood,
			features: $state.params.features,
			name: $state.params.name || undefined
		}

		if($state.is('drink-mobile')) {

			$scope.mobile = true;

			if($scope.isLocal) {
				params.latitude = parseFloat($scope.data.locale.latitude);
				params.longitude = parseFloat($scope.data.locale.longitude);
			}

			ResultsService.drink(params).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;

				if($scope.data.locale.latitude) {
					$scope.data.results.locations.forEach(function(v) {
						v.distance = $scope.calculateDistance(v.GeoLatitude, v.GeoLongitude, 'M')
					});
					$scope.data.results.locations = $filter('orderBy')($scope.data.results.locations, 'distance');
				} else {
					$scope.locationUnknown = true;
				}

				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$scope.calculateResultsListHeight();
				$scope.resultsListLoaded = true;
				$scope.listview = true;
			});
		}
		if($state.is('drink')) {
			ResultsService.drink(params).then(function(response) {

				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;
				$scope.data.results.locations.forEach(function(v) {
					v.listingType = 'drink';
					v.Value = 'Nightlife';
				});
				$scope.resultsloaded = true;
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$timeout(function() {
					$scope.calculateResultsListHeight();
				});
				$timeout(function() {
					$scope.removeOverlay = true;
				}, 1000)
			});
		}
	}

	if($state.is('attractions') || $state.is('attractions-mobile')) {

		$rootScope.pageTitle = 'Discover Your New Orleans: Find Things to See and Do';

		$scope.data.markers = [];
		$scope.tempBoundsArray = [];
		$scope.mapAttractionsActive = true;
		$scope.data.resultsHeader = 'Attractions';
		$scope.mapCategory = true;
		$scope.listingtype = 'attractions';


		var params = {
			neighborhood: $state.params.neighborhood,
			features: $state.params.features,
			name: $state.params.name || undefined
		}

		if($state.is('attractions-mobile')) {
			$scope.mobile = true;
			if($scope.isLocal) {
				//params.city = $scope.data.locale.city;
				params.latitude = parseFloat($scope.data.locale.latitude);
				params.longitude = parseFloat($scope.data.locale.longitude);
			}
			ResultsService.attractions(params).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;

				if($scope.data.locale.latitude) {
					$scope.data.results.locations.forEach(function(v) {
						v.distance = $scope.calculateDistance(v.GeoLatitude, v.GeoLongitude, 'M')
					});
					$scope.data.results.locations = $filter('orderBy')($scope.data.results.locations, 'distance');
				} else {
					$scope.locationUnknown = true;
				}

				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$scope.resultsListLoaded = true;
				$scope.listview = true;
			});
		}

		if($state.is('attractions')) {
			ResultsService.attractions(params).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;
				$scope.data.results.locations.forEach(function(v) {
					v.listingType = 'attractions';
					v.Value = 'Attractions';

				})
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$timeout(function() {
					$scope.calculateResultsListHeight();
				});
				$timeout(function() {
					$scope.removeOverlay = true;
				}, 1000);
			});
		}
	}

	if($state.is('shopping') || $state.is('shopping-mobile')) {
		$rootScope.pageTitle = 'Discover Your New Orleans: Find Places To Shop';
		$scope.data.markers = [];
		$scope.tempBoundsArray = [];
		$scope.mapShoppingActive = true;
		$scope.data.resultsHeader = 'Shopping';
		$scope.mapCategory = true;
		$scope.listingtype = 'shopping';

		var params = {
			neighborhood: $state.params.neighborhood,
			shoppingtypes: $state.params.shoppingtypes,
			name: $state.params.name || undefined
		}

		if($state.is('shopping-mobile')) {
			$scope.mobile = true;
			if($scope.isLocal) {
				params.city = $scope.data.locale.city;
				params.latitude = parseFloat($scope.data.locale.latitude);
				params.longitude = parseFloat($scope.data.locale.longitude);
			}

			ResultsService.shopping(params).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;

				if($scope.data.locale.latitude) {
					$scope.data.results.locations.forEach(function(v) {
						v.distance = $scope.calculateDistance(v.GeoLatitude, v.GeoLongitude, 'M')
					});
					$scope.data.results.locations = $filter('orderBy')($scope.data.results.locations, 'distance');
				} else {
					$scope.locationUnknown = true;
				}
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$scope.calculateResultsListHeight();
				$scope.resultsListLoaded = true;
				$scope.listview = true;
			});
		}
		if($state.is('shopping')) {
			ResultsService.shopping(params).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;
				$scope.data.results.locations.forEach(function(v) {
					v.listingType = 'shopping';
					v.Value = 'Shopping';
				});
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$timeout(function() {
					$scope.calculateResultsListHeight();
				});
				$timeout(function() {
					$scope.removeOverlay = true;
				}, 1000);


			});
		}
	}

	if($state.is('livemusic') || $state.is('livemusic-mobile')) {
		$rootScope.pageTitle = 'Discover Your New Orleans: Where to see Live Music';
		$scope.data.markers = [];
		$scope.tempBoundsArray = [];
		$scope.mapCategory = true;
		$scope.livemusic = true;
		$scope.data.resultsHeader = 'Live Music';
		var params = {
			neighborhood: $state.params.neighborhood,
		}
		if($state.is('livemusic-mobile')) {
			$scope.mobile = true;
			ResultsService.livemusic(params).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations);
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$scope.resultsListLoaded = true;
				$scope.listview = true;
				$scope.calculateResultsListHeight();
			});
		}
		if($state.is('livemusic')) {
			ResultsService.livemusic(params).then(function(response) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.data.results.locations = response.data;
				if(response.data.error) {
					$scope.resultsloaded = true;
					$scope.noresults = true;
					return false;
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$scope.plotMapMarkers($scope.data.results.locations)
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;
				$timeout(function() {
					$scope.calculateResultsListHeight();
				}, 250);

			});

		}
	}



	if($state.is('olduserlink')) {
		var userid = $state.params.id || undefined;

		if(!userid) {
			$state.go('home');
			return false;

		}
		AuthService.redirectOldUserLink(userid).then(function(response) {

			if(response.data.error) {
				$state.go('notFound');
			}
			else {
				$state.go('mynola', {id: response.data});
			}
		});
	}
	if($state.is('olduserlink-mobile')) {
		var userid = $state.params.id;
		AuthService.redirectOldUserLink(userid).then(function(response) {
			if(response.data.error) {
				$state.go('notFound');
			}
			else {
				$state.go('mynola-mobile', {id: response.data});
			}
		});
	}





	$scope.checkStatus = function($event) {
		angular.element($event.currentTarget).closest('label').toggleClass('active');
		if($event.currentTarget.checked) {
			// checkbox was not previously checked
		}
		else { }
	}

	$scope.makePossessive = function(string) {
		var str = string.slice(-1);
		var output = (str === 's' ? string+'\' NOLA' : string+'\'s NOLA');
		return output;
	}

	$scope.assignCheckboxFilters = function($collection) {
		$collection.forEach(function(value) {
			if(value.Accommodations) {
				$scope.hasAccommodations = true;
				value.listingtype = 'stay';
			}
			if(value.Restaurant) {
				$scope.hasRestaurants = true;
				value.listingtype = 'eat';
			}
			if(value.NightlifeVenue) {
				$scope.hasNightlife = true;
				value.listingtype = 'drink';
			}
			if(value.Attractions) {
				$scope.hasAttractions = true;
				value.listingtype = 'attractions';
			}
			if(value.Shopping) {
				$scope.hasShopping = true;
				value.listingtype = 'shopping';
			}
			if(value.OfferID) {
				$scope.hasOffer = true;
			}
		});
	}

	$scope.showCategories = false;

	$scope.toggleCheckboxCategories = function() {
		$scope.showCategories = !$scope.showCategories;
	}








	if($state.is('tour') || $state.is('tour-mobile')) {

		$scope.resultsLoaded = false;
		$scope.tempBoundsArray = [];
		$scope.data.markers = [];
		var currentCategory = 'tours';
		var slug = $state.params.slug;
		var listid = $state.params.listid;
		$scope.mapList = true;
		$scope.showShareControlsInFooter = true;
		$scope.mapView.on = true;
		$scope.showMobileOverview = false;

		$scope.audioPaused = true;

		if($state.is('tour-mobile')) {
			$scope.tourMobile = true;

		}

		ResultsService.getList(currentCategory,listid, slug).then(function(res) {

			$scope.listMeta = res.data.list[0];

			/*

			if($window.localStorage.getItem('showTourOverview-'+$scope.listMeta.id) == 'false') {
				$scope.showOverview = false;
			}
			else {
				$scope.showOverview = true;
			}
			*/
			$scope.showOverview = true;


			$scope.locations = $filter('orderBy')(res.data.locations, 'sort');

			$scope.resultsLoaded = true;

			google.maps.event.trigger($scope.desktopMap, 'resize');
			$scope.list = true;
			$scope.locations.forEach(function(v) {
				if(v.PrimaryMajorCategory === 1) { v.listingType = 'stay'; }
				if(v.PrimaryMajorCategory === 2) { v.listingType = 'eat'; }
				if(v.PrimaryMajorCategory === 3) { v.listingType = 'drink'; }
				if(v.PrimaryMajorCategory === 4) { v.listingType = 'attractions'; }
				if(v.PrimaryMajorCategory === 5) { v.listingType = 'shopping'; }
			});
			$scope.category_title = $state.params.category;
			$scope.data.resultsHeader = $scope.listMeta.list_title;
			$rootScope.pageTitle = $scope.data.resultsHeader;
			$scope.locationinfo = res.data.locations;
			var waypts = [];
			var markerWaypts = [];
			var directionsService = new google.maps.DirectionsService;
			var directionsDisplay = new google.maps.DirectionsRenderer({
				draggable: false,
				map: $scope.desktopMap,
				suppressMarkers: true,
				polylineOptions: {
					strokeColor: '#3498DB',
					strokeWeight: 6
				}
			});
			directionsDisplay.setMap($scope.desktopMap);
			calculateAndDisplayRoute(directionsService, directionsDisplay);


			function calculateAndDisplayRoute(directionsService, directionsDisplay) {
				$scope.locations.forEach(function(value, index) {
					if(index !== 0 || index !== $scope.locations[ $scope.locations.length -1 ]) {
						waypts.push({
							location: {
								lat: parseFloat($scope.locations[ index ].AltGeoLatitude),
								lng: parseFloat($scope.locations[ index ].AltGeoLongitude)
							}, stopover: true
						});
					}
				});

				$scope.locations.forEach(function(value, index) {
					markerWaypts.push(
						{
							lat: value.AltGeoLatitude,
							lng: value.AltGeoLongitude,
							label: value.AltTitle,
							map_square: value.ThumbnailImage
						}
					);
				})
				directionsService.route({
					origin: new google.maps.LatLng($scope.locations[0].GeoLatitude, $scope.locations[0].GeoLongitude),
					destination: new google.maps.LatLng($scope.locations[$scope.locations.length -1].GeoLatitude, $scope.locations[$scope.locations.length -1].GeoLongitude),
					waypoints: waypts,
					optimizeWaypoints: true,
					travelMode: 'WALKING'
				},
				function(response, status) {
					if (status === 'OK') {
						directionsDisplay.setDirections(response);
						var route = response.routes[0];
						boxOptions = {
							disableAutoPan: false,
							maxWidth: 0,
							pixelOffset: new google.maps.Size(-77, -230),
							zIndex: null,
							isHidden: false,
							pane: "floatPane",
							enableEventPropogation: false,
							closeBoxMargin: "0",
							closeBoxURL: ""
						}
						infoWindow = new InfoBox(boxOptions);
						var $tourLocations = $('.tour-locations');
						$scope.plotMapMarkers($scope.locations);
						$scope.mouseoverMarker();
					}
					else {
						window.alert('Directions request failed due to ' + status);
					}
				});
				google.maps.event.trigger($scope.desktopMap, 'resize');
			}
			$scope.resultsloaded = true;
			$timeout(function() {
				$scope.calculateResultsListHeight();
			}, 250);
		});
	}


	if($state.is('list') || $state.is('list-mobile')) {

		$scope.tempBoundsArray = [];
		$scope.data.markers = [];

		var currentCategory = $state.params.category;
		var listid = $state.params.listid;
		var slug = $state.params.slug;

		
		$scope.mapList = true;
		$scope.showShareControlsInFooter = true;

		if($state.is('list-mobile')) {

			$scope.mobile = true;
			$scope.showCheckboxFilters = true;

			if($state.params.category === 'tours') {
				$scope.isTour = true;
				$scope.showCheckboxFilters = false;
			}

			ResultsService.getList(currentCategory,listid,slug).then(function(response) {
				$scope.list = true;
				$scope.data.results.locations = $filter('orderBy')(response.data.locations, 'sort');

				$scope.listinfo = response.data.list;
				$scope.category_title = $state.params.category;
				$scope.data.resultsHeader = $scope.listinfo[0].list_title;

				if(currentCategory === 'celebrity') {
					$scope.celebrity = true;
					$scope.data.resultsHeader = $scope.makePossessive( $scope.listinfo[0].list_title );
				}

				$scope.assignCheckboxFilters($scope.data.results.locations);
				$rootScope.pageTitle = $scope.data.resultsHeader;
				$scope.locationinfo = response.data.locations;

				$scope.resultsloaded = true;
				$scope.data.resultsDescription = $scope.listinfo.list_short_description;
				$scope.listview = true;
				$scope.isList = true;
				$scope.resultsListLoaded = true;
				$scope.inspired = true;
				$rootScope.$on('$stateChangeSuccess', function() {
					$rootScope.pageTitle = $scope.data.resultsHeader;
				});
			});
		}

		if($state.is('list')) {

			ResultsService.getList(currentCategory,listid,slug).then(function(res) {
				google.maps.event.trigger($scope.desktopMap, 'resize');
				$scope.list = true;
				$scope.data.results.locations = $filter('orderBy')(res.data.locations, 'sort');
				$scope.data.results.locations.forEach(function(v) {
					if(v.PrimaryMajorCategory === 1) { v.listingType = 'stay'; }
					if(v.PrimaryMajorCategory === 2) { v.listingType = 'eat'; }
					if(v.PrimaryMajorCategory === 3) { v.listingType = 'drink'; }
					if(v.PrimaryMajorCategory === 4) { v.listingType = 'attractions'; }
					if(v.PrimaryMajorCategory === 5) { v.listingType = 'shopping'; }
				})
				$scope.listinfo = res.data.list;
				$scope.category_title = $state.params.category;
				$scope.data.resultsHeader = $scope.listinfo[0].list_title;
				if(currentCategory === 'celebrity') {
					$scope.celebrity = true;
					$scope.data.resultsHeader = $scope.makePossessive( $scope.listinfo[0].list_title );
				}
				// CALL UP THE DIRECTIONS SERVICE IF WE ARE ON A TOUR
				if(currentCategory === 'tours') {
					$scope.isTour = true;
					var waypts = [];
					var markerWaypts = [];
					var directionsService = new google.maps.DirectionsService;
					var directionsDisplay = new google.maps.DirectionsRenderer({
						draggable: false,
						map: $scope.desktopMap,
						suppressMarkers: true,
						polylineOptions: {
							strokeColor: '#3498DB',
							strokeWeight: 6
						}
					});
					directionsDisplay.setMap($scope.desktopMap);
					calculateAndDisplayRoute(directionsService, directionsDisplay);

					function calculateAndDisplayRoute(directionsService, directionsDisplay) {
						$scope.data.results.locations.forEach(function(value, index) {
							if(index !== 0 || index !== $scope.data.results.locations[ $scope.data.results.locations.length -1 ]) {
								waypts.push({ location: { lat: $scope.data.results.locations[ index ].GeoLatitude, lng: $scope.data.results.locations[ index ].GeoLongitude }, stopover: true } );
							}
						});

						$scope.data.results.locations.forEach(function(value, index) {
							markerWaypts.push(
								{
									lat: value.GeoLatitude,
									lng: value.GeoLongitude,
									label: value.LocationName1,
									map_square: value.AltThumbnail
								}
							);
						})


						directionsService.route({
							origin: new google.maps.LatLng($scope.data.results.locations[0].GeoLatitude, $scope.data.results.locations[0].GeoLongitude),
							destination: new google.maps.LatLng($scope.data.results.locations[$scope.data.results.locations.length -1].GeoLatitude, $scope.data.results.locations[$scope.data.results.locations.length -1].GeoLongitude),
							waypoints: waypts,
							optimizeWaypoints: true,
							travelMode: 'WALKING'
						},
						function(response, status) {
							if (status === 'OK') {
								directionsDisplay.setDirections(response);
								var route = response.routes[0];
								boxOptions = {
									disableAutoPan: false,
									maxWidth: 0,
									pixelOffset: new google.maps.Size(-77, -230),
									zIndex: null,
									isHidden: false,
									pane: "floatPane",
									enableEventPropogation: false,
									closeBoxMargin: "0",
									closeBoxURL: ""
								}
								infoWindow = new InfoBox(boxOptions);
								var $tourLocations = $('.tour-locations');
								$scope.mouseoverMarker();
							}
							else {
								window.alert('Directions request failed due to ' + status);
							}
						});
						google.maps.event.trigger($scope.desktopMap, 'resize');
					}
				}
				$scope.assignCheckboxFilters($scope.data.results.locations);
				$rootScope.pageTitle = $scope.data.resultsHeader;
				$scope.locationinfo = res.data.locations;
				$scope.plotMapMarkers($scope.data.results.locations);
				$scope.mouseoverMarker();
				$scope.resultsloaded = true;

				$timeout(function() {
					$scope.calculateResultsListHeight();
				}, 250);
			});
		}
	}





	/* ===== NEIGHBORHOOD AND STREETCAR OVERLAYS ===== */


	/* ===== CBD DOWNTOWN ===== */
	var testCbdPolyCoords = [
		{ lat: 29.96063231890648, lng: -90.07789722577434 },
		{ lat: 29.95740707518653, lng: -90.08093550766877 },
		{ lat: 29.95558415420749, lng: -90.08162507188678 },
		{ lat: 29.9545872081625, lng: -90.08157799036232 },
		{ lat: 29.95129600358227, lng: -90.084520620302 },
		{ lat: 29.94934536768721, lng: -90.08597574042999 },
		{ lat: 29.94219438705494, lng: -90.07307205522629 },
		{ lat: 29.94288915936064, lng: -90.07260239472727 },
		{ lat: 29.94592283006658, lng: -90.0717135002091 },
		{ lat: 29.94358595188115, lng: -90.06145636904479 },
		{ lat: 29.94361028539949, lng: -90.06145538440073 },
		{ lat: 29.94987018312532, lng: -90.06238467687594 },
		{ lat: 29.94976120309878, lng: -90.06337286373862 },
		{ lat: 29.95097776163141, lng: -90.06362773142182 },
		{ lat: 29.96063231890648, lng: -90.07789722577434 }
	];
	var testCbdPoly = new google.maps.Polygon({
		paths: testCbdPolyCoords,
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#FF0000',
		fillOpacity: 0.35
	});
	testCbdPoly.setMap(null);

	var testCbdIcon = new google.maps.Marker({
		position: { lat: 29.950398, lng: -90.077776 },
		map: null,
		icon: '/img/neighborhood-labels/cbd.gif'
	});





	/* ===== ALGIERS ===== */
	var testAlgiersPolyCoords = [
		{ lat: 29.89271584457289, lng: -90.00236905948717 },
		{ lat: 29.89757254077795, lng: -90.00210073979646 },
		{ lat: 29.88946382732583, lng: -89.98905403974692 },
		{ lat: 29.90494246291216, lng: -89.98728866550903 },
		{ lat: 29.9037552785248, lng: -89.98311517338158 },
		{ lat: 29.9018524642474, lng: -89.97816250712899 },
		{ lat: 29.90253583472927, lng: -89.97663403232777 },
		{ lat: 29.90419499714695, lng: -89.97272024715083 },
		{ lat: 29.90251448267571, lng: -89.95860950195925 },
		{ lat: 29.89303184029469, lng: -89.93532556918666 },
		{ lat: 29.86787456085162, lng: -89.91818323628019 },
		{ lat: 29.86957067462164, lng: -89.91241515014332 },
		{ lat: 29.87404920631223, lng: -89.90887030309938 },
		{ lat: 29.88419565102867, lng: -89.90753250674311 },
		{ lat: 29.89922567425647, lng: -89.91308473649403 },
		{ lat: 29.90543515506567, lng: -89.91453524746733 },
		{ lat: 29.91373810027337, lng: -89.92087455288934 },
		{ lat: 29.91736089364618, lng: -89.92749113174719 },
		{ lat: 29.9209473299685, lng: -89.9440306881281 },
		{ lat: 29.91926753470534, lng: -89.9634909985394 },
		{ lat: 29.92315641696124, lng: -89.97797060768311 },
		{ lat: 29.93242771921744, lng: -89.99658620605675 },
		{ lat: 29.94571696205425, lng: -90.01586094414755 },
		{ lat: 29.95459865047863, lng: -90.03889639467334 },
		{ lat: 29.95591441848994, lng: -90.04424980577187 },
		{ lat: 29.95622331772837, lng: -90.05352784668293 },
		{ lat: 29.95547224110052, lng: -90.05525112566053 },
		{ lat: 29.95242259787682, lng: -90.05615423297314 },
		{ lat: 29.94156662959859, lng: -90.05529091780124 },
		{ lat: 29.89676602927854, lng: -90.00792402840476 },
		{ lat: 29.8954358637463, lng: -90.00787956083569 },
		{ lat: 29.89271584457289, lng: -90.00236905948717 }
	];

	var testAlgiersPoly = new google.maps.Polygon({
		paths: testAlgiersPolyCoords,
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#8ecd16',
		fillOpacity: 0.5
	});
	testAlgiersPoly.setMap(null);

	var testAlgiersIcon = new google.maps.Marker({
		position: { lat: 29.946233, lng: -90.043959 },
		map: null,
		icon: '/img/neighborhood-labels/algiers.gif'

	});






	/* ===== WAREHOUSE DISTRICT ===== */
	var testWarehousePolyCoords = [
		{ lat: 29.93966668241344, lng: -90.06860085843194 },
		{ lat: 29.93887940898024, lng: -90.06578491413501 },
		{ lat: 29.93872992525185, lng: -90.06488992388974 },
		{ lat: 29.93871126235963, lng: -90.06423856102687 },
		{ lat: 29.9388720128353, lng: -90.06099594489281 },
		{ lat: 29.94112649626771, lng: -90.06107679656398 },
		{ lat: 29.9435524562193, lng: -90.06145347962641 },
		{ lat: 29.94589758102395, lng: -90.0716536996356 },
		{ lat: 29.94290500153539, lng: -90.07257376923015 },
		{ lat: 29.9421833145577, lng: -90.07299958852329 },
		{ lat: 29.93966668241344, lng: -90.06860085843194 }
	];
	var testWarehousePoly = new google.maps.Polygon({
		paths: testWarehousePolyCoords,
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#e77801',
		fillOpacity: 0.5
	});
	testWarehousePoly.setMap(null);

	var testWarehouseIcon = new google.maps.Marker({
		position: { lat: 29.9422, lng: -90.0674 },
		map: null,
		icon: '/img/neighborhood-labels/warehouse-district.gif'
	});





	/* ===== FRENCH QUARTER ===== */
	var testFrenchQuarterPolyCoords = [
		{ lat: 29.96580447994329, lng: -90.06355967479858 },
		{ lat: 29.95653940661443, lng: -90.07187031858283 },
		{ lat: 29.95098835881808, lng: -90.06351509213198 },
		{ lat: 29.94981645411567, lng: -90.06337459318122 },
		{ lat: 29.94991908041809, lng: -90.06242813846251 },
		{ lat: 29.95143276817801, lng: -90.06264102697564 },
		{ lat: 29.95264901809434, lng: -90.06264652973593 },
		{ lat: 29.95391808153578, lng: -90.06252293519933 },
		{ lat: 29.95567367014557, lng: -90.06217678687958 },
		{ lat: 29.95859243762294, lng: -90.05954516610872 },
		{ lat: 29.95840416934192, lng: -90.05936951868132 },
		{ lat: 29.96037953385809, lng: -90.05628460807859 },
		{ lat: 29.96053131288171, lng: -90.05588209872178 },
		{ lat: 29.96580447994329, lng: -90.06355967479858 }
	];
	var testFrenchQuarterPoly = new google.maps.Polygon({
		paths: testFrenchQuarterPolyCoords,
		strokeColor: '',
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#20c2ff',
		fillOpacity: 0.5
	});
	testFrenchQuarterPoly.setMap(null);

	var testFrenchQuarterIcon = new google.maps.Marker({
		position: { lat: 29.959248, lng: -90.066017 },
		map: null,
		icon: '/img/neighborhood-labels/french-quarter.gif'
	});






	/* ===== ESPLANADE RIDGE ===== */
	var testEsplanadePolyCoords = [
		{ lat: 29.97587873108258, lng: -90.09040866427155 },
		{ lat: 29.97070387637073, lng: -90.08273129159572 },
		{ lat: 29.97592299589864, lng: -90.07811813243349 },
		{ lat: 29.96591734095266, lng: -90.06367427161689 },
		{ lat: 29.96728584901723, lng: -90.06245521551803 },
		{ lat: 29.97490400841427, lng: -90.06709096434504 },
		{ lat: 29.98193193003252, lng: -90.07725089694986 },
		{ lat: 29.98133193509347, lng: -90.07736229574951 },
		{ lat: 29.98166854667107, lng: -90.08543610224025 },
		{ lat: 29.982762135054, lng: -90.08511946714961 },
		{ lat: 29.98314974187154, lng: -90.08529510327786 },
		{ lat: 29.98398352877284, lng: -90.08503123240622 },
		{ lat: 29.98436465881727, lng: -90.08630232275439 },
		{ lat: 29.9885256913208, lng: -90.08466082716086 },
		{ lat: 29.98857794053034, lng: -90.08598540146632 },
		{ lat: 29.98814945878434, lng: -90.08614207378278 },
		{ lat: 29.98854868416941, lng: -90.08789318390053 },
		{ lat: 29.98835105535866, lng: -90.08803492973628 },
		{ lat: 29.98783975596271, lng: -90.08804928557076 },
		{ lat: 29.98721199762341, lng: -90.08791989561964 },
		{ lat: 29.9868430679608, lng: -90.08815151188719 },
		{ lat: 29.98609082614162, lng: -90.08892253018888 },
		{ lat: 29.98570193322558, lng: -90.08924391908248 },
		{ lat: 29.98464389473772, lng: -90.08924290960394 },
		{ lat: 29.98372323381197, lng: -90.08959337234423 },
		{ lat: 29.98331634089624, lng: -90.090031279684 },
		{ lat: 29.98315624131426, lng: -90.09050803926293 },
		{ lat: 29.98283737656589, lng: -90.09088562385499 },
		{ lat: 29.98194903327342, lng: -90.09035126814013 },
		{ lat: 29.9800598247558, lng: -90.08799995276215 },
		{ lat: 29.97974447146945, lng: -90.08790983683745 },
		{ lat: 29.9781918878173, lng: -90.08876846821251 },
		{ lat: 29.97720046242307, lng: -90.0899388584358 },
		{ lat: 29.97658837316541, lng: -90.0902652404049 },
		{ lat: 29.97633943652421, lng: -90.09040268784848 },
		{ lat: 29.97587873108258, lng: -90.09040866427155 }
	];
	var testEsplanadePoly = new google.maps.Polygon({
		paths: testEsplanadePolyCoords,
		strokeColor: '',
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#70d5e2',
		fillOpacity: 0.5
	});
	testEsplanadePoly.setMap(null);

	var testEsplanadeIcon = new google.maps.Marker({
		position: { lat: 29.975934, lng: -90.084597 },
		map: null,
		icon: '/img/neighborhood-labels/esplanade.gif'
	});





	/* ===== UPTOWN ===== */
	var testUptownPolyCoords = [
		{ lat: 29.93880990021989, lng: -90.06103637775991 },
		{ lat: 29.93870471002833, lng: -90.0641698307813 },
		{ lat: 29.93872625451208, lng: -90.06486719357064 },
		{ lat: 29.93887966815739, lng: -90.06576924837727 },
		{ lat: 29.93967496071224, lng: -90.06856959260195 },
		{ lat: 29.94217093627614, lng: -90.07308110780556 },
		{ lat: 29.9455239232827, lng: -90.07918713898472 },
		{ lat: 29.94933598502286, lng: -90.08594596444142 },
		{ lat: 29.95739864494795, lng: -90.10049942347014 },
		{ lat: 29.95749423534361, lng: -90.1008365620888 },
		{ lat: 29.9575553135803, lng: -90.10114977088615 },
		{ lat: 29.95756483878211, lng: -90.10155656586026 },
		{ lat: 29.95689386683756, lng: -90.10505519754362 },
		{ lat: 29.95688757521901, lng: -90.10633955986621 },
		{ lat: 29.95697581413269, lng: -90.10688815748424 },
		{ lat: 29.9571610098693, lng: -90.10755682562328 },
		{ lat: 29.95962143122851, lng: -90.11197324583375 },
		{ lat: 29.96004979914788, lng: -90.11300400431357 },
		{ lat: 29.96107463710795, lng: -90.11482963187949 },
		{ lat: 29.96273671430868, lng: -90.11733332536178 },
		{ lat: 29.96351670157513, lng: -90.11883958749192 },
		{ lat: 29.96437983949728, lng: -90.12093342741443 },
		{ lat: 29.96507148614061, lng: -90.12317994803898 },
		{ lat: 29.96547842948596, lng: -90.12519472621378 },
		{ lat: 29.96572464106013, lng: -90.12591613935373 },
		{ lat: 29.96596036754376, lng: -90.12639233858145 },
		{ lat: 29.96733123806688, lng: -90.1282136756507 },
		{ lat: 29.96390508808705, lng: -90.13022392303402 },
		{ lat: 29.9640433116629, lng: -90.13062035478521 },
		{ lat: 29.95138713643087, lng: -90.13790806782538 },
		{ lat: 29.94570685621715, lng: -90.13632554369369 },
		{ lat: 29.93995174809857, lng: -90.13591291658447 },
		{ lat: 29.93911294431168, lng: -90.13539296522397 },
		{ lat: 29.92369469536092, lng: -90.13546405136786 },
		{ lat: 29.91987186722719, lng: -90.13495358247039 },
		{ lat: 29.91705891153558, lng: -90.13444867872917 },
		{ lat: 29.91597070756691, lng: -90.13298054216007 },
		{ lat: 29.91386869524835, lng: -90.12892194733065 },
		{ lat: 29.91196133420903, lng: -90.11898156086856 },
		{ lat: 29.91173269930714, lng: -90.10719659403797 },
		{ lat: 29.91307166390998, lng: -90.09795642789165 },
		{ lat: 29.91358232486025, lng: -90.09813905979894 },
		{ lat: 29.91399980710864, lng: -90.0960113199293 },
		{ lat: 29.91691742335327, lng: -90.08559843257827 },
		{ lat: 29.92096350971346, lng: -90.07483659163943 },
		{ lat: 29.92074622014193, lng: -90.07460133842614 },
		{ lat: 29.92258284789288, lng: -90.07073851655078 },
		{ lat: 29.92602499322633, lng: -90.06606226367809 },
		{ lat: 29.92803751854791, lng: -90.0638846344897 },
		{ lat: 29.93179969691433, lng: -90.0617962394617 },
		{ lat: 29.93617667347273, lng: -90.06083503064301 },
		{ lat: 29.93880990021989, lng: -90.06103637775991 }
	];
	var testUptownPoly = new google.maps.Polygon({
		paths: testUptownPolyCoords,
		strokeColor: '',
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#ef2546',
		fillOpacity: 0.5
	});
	testUptownPoly.setMap(null);
	var testUptownIcon = new google.maps.Marker({
		position: { lat: 29.944002, lng: -90.126785 },
		map: null,
		icon: '/img/neighborhood-labels/uptown.gif'
	});
	var testGardenDistrictIcon = new google.maps.Marker({
		position: { lat: 29.928234, lng: -90.085151 },
		map: null,
		icon: '/img/neighborhood-labels/garden-district.gif'
	});

	var testCentralCityIcon = new google.maps.Marker({
		position: { lat: 29.939348, lng: -90.089876 },
		map: null,
		icon: '/img/neighborhood-labels/central-city.gif'
	});





	/* ===== MID CITY ===== */
	var testMidCityPolyCoords = [
		{ lat: 29.95880721239415, lng: -90.06983707379956 },
		{ lat: 29.96872075253813, lng: -90.0844752001278 },
		{ lat: 29.96914870331865, lng: -90.08412676048984 },
		{ lat: 29.97070425725107, lng: -90.08274480101592 },
		{ lat: 29.98395847303739, lng: -90.10241195556131 },
		{ lat: 29.98402582111141, lng: -90.1036962921429 },
		{ lat: 29.98293599262027, lng: -90.1079480930899 },
		{ lat: 29.98237875138634, lng: -90.10976706201284 },
		{ lat: 29.98111157325806, lng: -90.11348555485233 },
		{ lat: 29.980719367837, lng: -90.11482855596623 },
		{ lat: 29.97963397329218, lng: -90.12223515354386 },
		{ lat: 29.97924860657982, lng: -90.12400337676748 },
		{ lat: 29.97920041248934, lng: -90.12472135361142 },
		{ lat: 29.9761922977326, lng: -90.125038433718 },
		{ lat: 29.97607373639949, lng: -90.12484244346462 },
		{ lat: 29.97373267757459, lng: -90.1250172245261 },
		{ lat: 29.97180393835934, lng: -90.12605972150838 },
		{ lat: 29.97024287472533, lng: -90.12653558607308 },
		{ lat: 29.96733895592727, lng: -90.12821108618343 },
		{ lat: 29.96592081369718, lng: -90.12630172804401 },
		{ lat: 29.9654880303049, lng: -90.12521393272614 },
		{ lat: 29.96509635184062, lng: -90.1230257207576 },
		{ lat: 29.96437150753819, lng: -90.12091877495078 },
		{ lat: 29.96352834398251, lng: -90.11882366369625 },
		{ lat: 29.96272335538323, lng: -90.11730308206914 },
		{ lat: 29.96107932582419, lng: -90.11481362239785 },
		{ lat: 29.96006916047147, lng: -90.11299457594618 },
		{ lat: 29.95962252398044, lng: -90.111958293276 },
		{ lat: 29.95717762397286, lng: -90.10754934243757 },
		{ lat: 29.95700876256867, lng: -90.10706842168676 },
		{ lat: 29.95688789270384, lng: -90.10632655966462 },
		{ lat: 29.95690172977268, lng: -90.10510696020978 },
		{ lat: 29.95753252662165, lng: -90.10176419053076 },
		{ lat: 29.95755767502165, lng: -90.1010866560507 },
		{ lat: 29.95741584853575, lng: -90.10054095694579 },
		{ lat: 29.9493850577596, lng: -90.08596341573613 },
		{ lat: 29.94986029597747, lng: -90.08561986177583 },
		{ lat: 29.95463000787501, lng: -90.0815928945637 },
		{ lat: 29.95552805388209, lng: -90.08166670804124 },
		{ lat: 29.95736514198837, lng: -90.08098173229007 },
		{ lat: 29.96065669827646, lng: -90.07793528757172 },
		{ lat: 29.95654959146256, lng: -90.07186580262274 },
		{ lat: 29.95880721239415, lng: -90.06983707379956 }
	];
	var testMidCityPoly = new google.maps.Polygon({
		paths: testMidCityPolyCoords,
		strokeColor: '',
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#5181fb',
		fillOpacity: 0.5
	});
	testMidCityPoly.setMap(null);

	var testMidCityIcon = new google.maps.Marker({
		position: { lat: 29.969955, lng: -90.096501 },
		map: null,
		icon: '/img/neighborhood-labels/mid-city.gif'
	});





	/* ===== MARIGNY BYWATER ===== */
	var testMarignyBywaterPolyCoords = [
		{ lat: 29.96588956587293, lng: -90.06370745648539 },
		{ lat: 29.96054297412236, lng: -90.0558750077209 },
		{ lat: 29.9610062300974, lng: -90.05456869327996 },
		{ lat: 29.96144757325631, lng: -90.05107051429064 },
		{ lat: 29.96161997014, lng: -90.05110928986279 },
		{ lat: 29.96173055532449, lng: -90.04946530003336 },
		{ lat: 29.96168606319305, lng: -90.04856616701548 },
		{ lat: 29.96124768809554, lng: -90.04637620377335 },
		{ lat: 29.96120866661745, lng: -90.045930423578 },
		{ lat: 29.96091533247725, lng: -90.04491579892954 },
		{ lat: 29.96071711606746, lng: -90.04486062660138 },
		{ lat: 29.96042769327914, lng: -90.04329814695699 },
		{ lat: 29.96056943949478, lng: -90.04322862030561 },
		{ lat: 29.96043055878409, lng: -90.04116770812618 },
		{ lat: 29.96030995958063, lng: -90.04027812811474 },
		{ lat: 29.96019543726146, lng: -90.04018307899027 },
		{ lat: 29.96004686411202, lng: -90.03942077031151 },
		{ lat: 29.96010732287792, lng: -90.03930181929469 },
		{ lat: 29.96002502025262, lng: -90.03883464756265 },
		{ lat: 29.95988793151577, lng: -90.03889149735757 },
		{ lat: 29.95974375011839, lng: -90.03875830023159 },
		{ lat: 29.95761147266329, lng: -90.03049660337518 },
		{ lat: 29.95784546222627, lng: -90.03034509764643 },
		{ lat: 29.9578779714086, lng: -90.02995840290944 },
		{ lat: 29.95761159438224, lng: -90.02900332515061 },
		{ lat: 29.95763529360146, lng: -90.02859746612228 },
		{ lat: 29.95775793441783, lng: -90.02850511082595 },
		{ lat: 29.95797681502043, lng: -90.02853078110755 },
		{ lat: 29.95845600794915, lng: -90.02881491528824 },
		{ lat: 29.95868729601698, lng: -90.02891319809015 },
		{ lat: 29.95944169303823, lng: -90.02948853604879 },
		{ lat: 29.96005322639203, lng: -90.02947046353611 },
		{ lat: 29.9605083374027, lng: -90.02924151131852 },
		{ lat: 29.96269410505929, lng: -90.02856446921751 },
		{ lat: 29.96401326678035, lng: -90.02769088673033 },
		{ lat: 29.96913807196705, lng: -90.05023444146673 },
		{ lat: 29.96859833326396, lng: -90.05814280380301 },
		{ lat: 29.96840172079218, lng: -90.05924657611466 },
		{ lat: 29.96828392518062, lng: -90.06092579822501 },
		{ lat: 29.96794049027047, lng: -90.06172407782994 },
		{ lat: 29.96732530622015, lng: -90.06244727994326 },
		{ lat: 29.96588956587293, lng: -90.06370745648539 }
	];

	var testMarignyBywaterPoly = new google.maps.Polygon({
		paths: testMarignyBywaterPolyCoords,
		strokeColor: '',
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#ddd201',
		fillOpacity: 0.5
	});
	testMarignyBywaterPoly.setMap(null);

	var testMarignyIcon = new google.maps.Marker({
		position: { lat: 29.965196, lng: -90.054859 },
		map: null,
		icon: '/img/neighborhood-labels/marigny.gif'
	});

	var testBywaterIcon = new google.maps.Marker({
		position: { lat: 29.961776, lng: -90.035891 },
		map: null,
		icon: '/img/neighborhood-labels/bywater.gif'
	});







	/* ===== LAKEVIEW ===== */
	var testLakeViewPolyCoords = [
		{ lat: 29.97924803550865, lng: -90.1240052451321 },
		{ lat: 29.9796805345304, lng: -90.12212171672576 },
		{ lat: 29.98073607099537, lng: -90.11478713951395 },
		{ lat: 29.98277012637172, lng: -90.10873050117762 },
		{ lat: 29.9837369393431, lng: -90.10501307966987 },
		{ lat: 29.98400772255224, lng: -90.10365802220612 },
		{ lat: 29.98399090888902, lng: -90.10241761272559 },
		{ lat: 29.97590640440782, lng: -90.09042159879982 },
		{ lat: 29.97634354138982, lng: -90.09039809327383 },
		{ lat: 29.97720429789644, lng: -90.08994132833135 },
		{ lat: 29.9774853563464, lng: -90.08959726240695 },
		{ lat: 29.97819593408071, lng: -90.08876240976386 },
		{ lat: 29.97974741187296, lng: -90.08791475582721 },
		{ lat: 29.98007161725601, lng: -90.08799943625257 },
		{ lat: 29.98195312749408, lng: -90.09036028796224 },
		{ lat: 29.98282631865433, lng: -90.09088513732327 },
		{ lat: 29.98315798888118, lng: -90.09051088996544 },
		{ lat: 29.98332074372555, lng: -90.09002737243107 },
		{ lat: 29.98373120983602, lng: -90.08958019347882 },
		{ lat: 29.9846488235973, lng: -90.08924810508536 },
		{ lat: 29.98570855045238, lng: -90.08924251285575 },
		{ lat: 29.9861028276881, lng: -90.08891637584524 },
		{ lat: 29.98685930231828, lng: -90.08814350857905 },
		{ lat: 29.98722400704325, lng: -90.08792433244028 },
		{ lat: 29.987845029451, lng: -90.0880605623923 },
		{ lat: 29.98834594043458, lng: -90.08804152722487 },
		{ lat: 29.98920662725483, lng: -90.08747268732743 },
		{ lat: 29.99081012977432, lng: -90.08716590892067 },
		{ lat: 29.9919190071832, lng: -90.08658770278069 },
		{ lat: 29.99250900546528, lng: -90.086533370029 },
		{ lat: 29.9932654326119, lng: -90.08627580205712 },
		{ lat: 29.99373226586495, lng: -90.08632398209427 },
		{ lat: 29.99584797363043, lng: -90.08573669690192 },
		{ lat: 29.99675311103513, lng: -90.08528527526806 },
		{ lat: 29.99756588937787, lng: -90.08562711294883 },
		{ lat: 29.9995250398207, lng: -90.0857233422979 },
		{ lat: 30.00253969202335, lng: -90.08581937287562 },
		{ lat: 30.00530321020864, lng: -90.08587520128688 },
		{ lat: 30.01139156167615, lng: -90.08582845985441 },
		{ lat: 30.0120750970196, lng: -90.08560853031629 },
		{ lat: 30.01278993133283, lng: -90.08533027749144 },
		{ lat: 30.01321642159919, lng: -90.08544873962296 },
		{ lat: 30.01760951910097, lng: -90.08474194062235 },
		{ lat: 30.01913586178602, lng: -90.08426205531617 },
		{ lat: 30.02142741711693, lng: -90.08290847746901 },
		{ lat: 30.02181529665066, lng: -90.08282781726962 },
		{ lat: 30.02420099134402, lng: -90.08302491287711 },
		{ lat: 30.02529442425549, lng: -90.08286956398369 },
		{ lat: 30.02677394436677, lng: -90.08282811191349 },
		{ lat: 30.02725686340667, lng: -90.0834756796483 },
		{ lat: 30.02742318453479, lng: -90.08403227985006 },
		{ lat: 30.02743928690945, lng: -90.0871757937891 },
		{ lat: 30.02787874787955, lng: -90.08838391522957 },
		{ lat: 30.02951669806328, lng: -90.08977559998284 },
		{ lat: 30.02985623136915, lng: -90.09019067208503 },
		{ lat: 30.03009141413255, lng: -90.09106023646615 },
		{ lat: 30.03010116517755, lng: -90.09178779281459 },
		{ lat: 30.02991524213978, lng: -90.09251156028148 },
		{ lat: 30.02910028764463, lng: -90.09380374997949 },
		{ lat: 30.02915533146587, lng: -90.09585190309436 },
		{ lat: 30.02869052071235, lng: -90.09809265835045 },
		{ lat: 30.02825423901902, lng: -90.1001720683454 },
		{ lat: 30.02854285324818, lng: -90.10161775420201 },
		{ lat: 30.02741179525857, lng: -90.10338464136271 },
		{ lat: 30.02779973116347, lng: -90.10846066641857 },
		{ lat: 30.02679807405118, lng: -90.11297602610364 },
		{ lat: 30.02773520064493, lng: -90.11364763324917 },
		{ lat: 30.02798934392739, lng: -90.11465643139046 },
		{ lat: 30.02767262172097, lng: -90.1149596682401 },
		{ lat: 30.02695574829448, lng: -90.11491625499257 },
		{ lat: 30.02668615749876, lng: -90.1144521679533 },
		{ lat: 30.02564453159877, lng: -90.11460068990549 },
		{ lat: 30.02537670861378, lng: -90.11970247616516 },
		{ lat: 30.02797460691492, lng: -90.11958389005784 },
		{ lat: 30.02794634569752, lng: -90.11947074024069 },
		{ lat: 30.0282947093659, lng: -90.11540914209503 },
		{ lat: 30.02853635801702, lng: -90.11533881967365 },
		{ lat: 30.02867405287859, lng: -90.11349853387186 },
		{ lat: 30.02828892304133, lng: -90.11295340590444 },
		{ lat: 30.02833854535849, lng: -90.1126354844304 },
		{ lat: 30.02860780534375, lng: -90.11258684159475 },
		{ lat: 30.02891364126679, lng: -90.11316664664642 },
		{ lat: 30.02894493567631, lng: -90.11551728142376 },
		{ lat: 30.02980154664624, lng: -90.1182994831433 },
		{ lat: 30.02925154185299, lng: -90.12015628628183 },
		{ lat: 30.01988607801057, lng: -90.12117122959933 },
		{ lat: 29.97915698512291, lng: -90.12459347934248 },
		{ lat: 29.97924803550865, lng: -90.1240052451321 }
	];
	var testLakeViewPoly = new google.maps.Polygon({
		paths: testLakeViewPolyCoords,
		strokeColor: '',
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#f36623',
		fillOpacity: 0.5
	});
	testLakeViewPoly.setMap(null);

	var testLakeViewIcon = new google.maps.Marker({
		position: { lat: 29.988579, lng: -90.114805 },
		map: null,
		icon: '/img/neighborhood-labels/lakeview.gif'
	});





	/* ===== TREME ===== */
	var testTremePolyCoords = [
		{ lat: 29.96576443135787, lng: -90.0635915401899 },
		{ lat: 29.97585071667307, lng: -90.0781706330385 },
		{ lat: 29.96872909077642, lng: -90.0844645061869 },
		{ lat: 29.95883401948096, lng: -90.06984456437046 },
		{ lat: 29.96576443135787, lng: -90.0635915401899 }
	];
	var testTremePoly = new google.maps.Polygon({
		paths: testTremePolyCoords,
		strokeColor: '',
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#3de47c',
		fillOpacity: 0.5
	});
	testTremePoly.setMap(null);

	var testTremeIcon = new google.maps.Marker({
		position: { lat: 29.968319, lng: -90.07666 },
		map: null,
		icon: '/img/neighborhood-labels/treme.gif'
	});





	/* ===== GENTILLY ===== */
	var testGentillyPolyCoords = [
		{ lat: 29.98856977806948, lng: -90.08789280337486 },
		{ lat: 29.9881415595507, lng: -90.08617537982239 },
		{ lat: 29.9885780836766, lng: -90.08600154331245 },
		{ lat: 29.98851853494381, lng: -90.08460710328629 },
		{ lat: 29.98442416883385, lng: -90.08626880885116 },
		{ lat: 29.98397532471786, lng: -90.08500015944995 },
		{ lat: 29.98316377891586, lng: -90.08525532268818 },
		{ lat: 29.98277305869098, lng: -90.08512323742022 },
		{ lat: 29.98169337235422, lng: -90.0854106909252 },
		{ lat: 29.98135222738696, lng: -90.07739742899629 },
		{ lat: 29.98192038904351, lng: -90.07726795939729 },
		{ lat: 29.97485407676512, lng: -90.06700532276149 },
		{ lat: 29.96735947358075, lng: -90.06246760875197 },
		{ lat: 29.9679517232913, lng: -90.06172614433274 },
		{ lat: 29.96827879645685, lng: -90.06091650368016 },
		{ lat: 29.96840876250644, lng: -90.05924788377122 },
		{ lat: 29.96859611218934, lng: -90.05815180799306 },
		{ lat: 29.96872560568768, lng: -90.05671316030129 },
		{ lat: 29.96914853875411, lng: -90.05025378671289 },
		{ lat: 29.96394292885668, lng: -90.02736300922231 },
		{ lat: 29.96311376387009, lng: -90.02717164108168 },
		{ lat: 29.96074893010314, lng: -90.0279295807175 },
		{ lat: 29.95960623008279, lng: -90.02788095267717 },
		{ lat: 29.95842108728611, lng: -90.02744181125226 },
		{ lat: 29.95750256249221, lng: -90.02677556932841 },
		{ lat: 29.95713831764602, lng: -90.02667188343466 },
		{ lat: 29.95683454596891, lng: -90.02633639491357 },
		{ lat: 29.95610496483885, lng: -90.02478101809814 },
		{ lat: 29.95393835433211, lng: -90.01984166121005 },
		{ lat: 29.94925989774748, lng: -90.01069744546497 },
		{ lat: 29.97385148414017, lng: -89.99882196658655 },
		{ lat: 29.97269530217552, lng: -89.99553525658045 },
		{ lat: 29.98064839310931, lng: -89.99199683595701 },
		{ lat: 29.98314441804333, lng: -89.99264323335635 },
		{ lat: 29.98949723776196, lng: -89.98982210405681 },
		{ lat: 29.98711710864912, lng: -89.98392866450041 },
		{ lat: 29.98800371663793, lng: -89.98107907512704 },
		{ lat: 29.99054203871744, lng: -89.98015208186895 },
		{ lat: 29.99448652427393, lng: -89.97462486165343 },
		{ lat: 29.99443445026093, lng: -89.97190896009792 },
		{ lat: 29.99175835355985, lng: -89.97054444464142 },
		{ lat: 29.9861385270037, lng: -89.95973986128227 },
		{ lat: 29.98674054272205, lng: -89.95743854968531 },
		{ lat: 29.98624288482228, lng: -89.95525766339183 },
		{ lat: 29.98310030366208, lng: -89.95484071481447 },
		{ lat: 29.98127790403439, lng: -89.95313883793222 },
		{ lat: 29.98216011918222, lng: -89.94542935596817 },
		{ lat: 29.98028295699226, lng: -89.93927837937734 },
		{ lat: 29.98212308844808, lng: -89.93790844816874 },
		{ lat: 29.984676120455, lng: -89.93751905780572 },
		{ lat: 29.98755889161298, lng: -89.93150609079552 },
		{ lat: 29.98766118909595, lng: -89.9270605515234 },
		{ lat: 29.99170495623339, lng: -89.92352048646231 },
		{ lat: 29.99422461287227, lng: -89.91859091439446 },
		{ lat: 29.99820281382932, lng: -89.91559292463226 },
		{ lat: 30.00147668220569, lng: -89.90465233075628 },
		{ lat: 30.00267152963712, lng: -89.90408185212263 },
		{ lat: 30.00067620172882, lng: -89.8971564805429 },
		{ lat: 30.0020885584118, lng: -89.89438414087039 },
		{ lat: 29.99980713847325, lng: -89.89008399135635 },
		{ lat: 29.99728330416835, lng: -89.88818530501247 },
		{ lat: 29.99633019010165, lng: -89.88558272847614 },
		{ lat: 29.99309883121329, lng: -89.8847749311781 },
		{ lat: 29.99107534433161, lng: -89.88519776291102 },
		{ lat: 29.98870610429553, lng: -89.88275076889762 },
		{ lat: 29.98507123594663, lng: -89.87968374844603 },
		{ lat: 29.98675340480289, lng: -89.87620674571198 },
		{ lat: 29.99322475514151, lng: -89.87056247057568 },
		{ lat: 29.99204252422439, lng: -89.86836132793012 },
		{ lat: 29.98878547820122, lng: -89.86700960713147 },
		{ lat: 29.98765864941894, lng: -89.86364157370868 },
		{ lat: 29.99081196208927, lng: -89.85879694901145 },
		{ lat: 29.9924067599503, lng: -89.85965085653785 },
		{ lat: 29.99621116192134, lng: -89.86486610905021 },
		{ lat: 29.99776025377802, lng: -89.86512383050844 },
		{ lat: 29.9992287642518, lng: -89.86195650455062 },
		{ lat: 30.00229230442415, lng: -89.85984100412122 },
		{ lat: 30.00459295580113, lng: -89.86000796721947 },
		{ lat: 30.00789783556862, lng: -89.85770770281606 },
		{ lat: 30.04533908413707, lng: -89.81587054126648 },
		{ lat: 30.09326991218305, lng: -89.7626704179545 },
		{ lat: 30.10554839299307, lng: -89.7566021282701 },
		{ lat: 30.13087815258369, lng: -89.75607919092364 },
		{ lat: 30.150512964822, lng: -89.73312540027465 },
		{ lat: 30.15767936234958, lng: -89.73089892864746 },
		{ lat: 30.17372849687969, lng: -89.73995525929702 },
		{ lat: 30.17203502032113, lng: -89.74549284144894 },
		{ lat: 30.15132226542619, lng: -89.74350640331383 },
		{ lat: 30.11154888199332, lng: -89.789004189399 },
		{ lat: 30.10111285594931, lng: -89.79902652061247 },
		{ lat: 30.09616018455557, lng: -89.82979062718576 },
		{ lat: 30.13245780519737, lng: -89.85803073063407 },
		{ lat: 30.15003675327916, lng: -89.85482972683741 },
		{ lat: 30.15190011906852, lng: -89.86709474866154 },
		{ lat: 30.14588535129346, lng: -89.87008513536627 },
		{ lat: 30.14774275006955, lng: -89.88236278121586 },
		{ lat: 30.09611784187849, lng: -89.92088273713505 },
		{ lat: 30.07530815426035, lng: -89.94390515971087 },
		{ lat: 30.05240828720978, lng: -89.97917348848945 },
		{ lat: 30.03949537819243, lng: -90.01042667582439 },
		{ lat: 30.04179041626082, lng: -90.01353114366887 },
		{ lat: 30.038709266202, lng: -90.01703221026665 },
		{ lat: 30.04363606690126, lng: -90.0229343191467 },
		{ lat: 30.05069194033273, lng: -90.0237189999344 },
		{ lat: 30.05089624138345, lng: -90.02755323274042 },
		{ lat: 30.05309944610225, lng: -90.02792503441572 },
		{ lat: 30.05241646111138, lng: -90.03041371128219 },
		{ lat: 30.03386375483055, lng: -90.03142330821355 },
		{ lat: 30.03171848644861, lng: -90.03787273114196 },
		{ lat: 30.03334145427391, lng: -90.03966547867442 },
		{ lat: 30.03521778755151, lng: -90.04485757424119 },
		{ lat: 30.03182409683709, lng: -90.07752926527149 },
		{ lat: 30.03171602550282, lng: -90.07859488758538 },
		{ lat: 30.02868034962378, lng: -90.08037822721904 },
		{ lat: 30.02684309060886, lng: -90.08272494407378 },
		{ lat: 30.02416562760574, lng: -90.0829707027516 },
		{ lat: 30.02163434956405, lng: -90.08268041644402 },
		{ lat: 30.01920640726595, lng: -90.08423826856907 },
		{ lat: 30.01758576397864, lng: -90.08479006136736 },
		{ lat: 30.01332754575042, lng: -90.08542079353485 },
		{ lat: 30.01289783983359, lng: -90.08533438665185 },
		{ lat: 30.01121556225488, lng: -90.08588969594604 },
		{ lat: 30.0030962340565, lng: -90.08587152618212 },
		{ lat: 29.99874047373308, lng: -90.08566155695591 },
		{ lat: 29.99760043446592, lng: -90.08562567796598 },
		{ lat: 29.99676270139768, lng: -90.08525128265494 },
		{ lat: 29.99580286976563, lng: -90.08575986753355 },
		{ lat: 29.99375894160439, lng: -90.08631783264309 },
		{ lat: 29.99334980084904, lng: -90.08625427471795 },
		{ lat: 29.99256941132214, lng: -90.08651489457175 },
		{ lat: 29.99185713099801, lng: -90.08659736477449 },
		{ lat: 29.99086054786867, lng: -90.08715668134497 },
		{ lat: 29.98943355877891, lng: -90.0874339499706 },
		{ lat: 29.98920994624289, lng: -90.087466531225 },
		{ lat: 29.98856977806948, lng: -90.08789280337486 }
	];
	var testGentillyPoly = new google.maps.Polygon({
		paths: testGentillyPolyCoords,
		strokeColor: '',
		strokeColor: '',
		strokeOpacity: 0.8,
		strokeWeight: 0,
		fillColor: '#b4b7b7',
		fillOpacity: 0.5
	});
	testGentillyPoly.setMap(null);

	var testGentillyIcon = new google.maps.Marker({
		position: { lat: 29.992259, lng: -90.049452 },
		map: null,
		icon: '/img/neighborhood-labels/gentilly.gif'
	});


	$scope.neighborhoodOverlaysShowing = false;
	$scope.toggleNeighborhoodOverlays = function() {
		var n = [
			testCbdPoly,
			testCbdIcon,
			testWarehousePoly,
			testWarehouseIcon,
			testUptownPoly,
			testUptownIcon,
			testGardenDistrictIcon,
			testCentralCityIcon,
			testMidCityPoly,
			testMidCityIcon,
			testLakeViewPoly,
			testLakeViewIcon,
			testEsplanadePoly,
			testEsplanadeIcon,
			testTremePoly,
			testTremeIcon,
			testFrenchQuarterPoly,
			testFrenchQuarterIcon,
			testMarignyBywaterPoly,
			testMarignyIcon,
			testBywaterIcon,
			testGentillyPoly,
			testGentillyIcon,
			testAlgiersPoly,
			testAlgiersIcon
		];
		if(!$scope.neighborhoodOverlaysShowing) {
			n.forEach(function(v,i) {
				n[i].setMap($scope.desktopMap);
			});
			$scope.neighborhoodOverlaysShowing = true;
			angular.element('.map-neighborhoods').css('background-position','-34px 0')
		}
		else {
			n.forEach(function(v,i) {
				n[i].setMap(null);
			});
			$scope.neighborhoodOverlaysShowing = false;
			angular.element('.map-neighborhoods').css('background-position','0 0')
		}
	}









	/* ===== STREETCAR LINES ===== */
	var lineArr1Coords = [
		{ lat: 29.94550897337769, lng: -90.07836155682659 },
		{ lat: 29.94544086391261, lng: -90.07807558239664 },
		{ lat: 29.94544092535701, lng: -90.07773608257918 },
		{ lat: 29.94562434616401, lng: -90.07728848366442 },
		{ lat: 29.94606181008436, lng: -90.07700323401828 },
		{ lat: 29.94713929508177, lng: -90.07675800007579 },
		{ lat: 29.95085353288903, lng: -90.07567387831044 },
		{ lat: 29.95140832888552, lng: -90.0756972404879 },
		{ lat: 29.95214963739879, lng: -90.07568795907586 },
		{ lat: 29.95352752053138, lng: -90.07525192690953 },
		{ lat: 29.95427519943905, lng: -90.07498350750544 },
		{ lat: 29.95459919878837, lng: -90.07479082489162 },
		{ lat: 29.95603584121646, lng: -90.07349415501429 },
		{ lat: 29.95558057142821, lng: -90.07284579882317 },
		{ lat: 29.9543093058994, lng: -90.07096036841905 },
		{ lat: 29.95371055426573, lng: -90.07005491946735 },
		{ lat: 29.95309364271721, lng: -90.06915143961388 },
		{ lat: 29.95249786682122, lng: -90.06826046734378 },
		{ lat: 29.95180547366068, lng: -90.06726158594196 },
		{ lat: 29.95104975336925, lng: -90.06613642514891 },
		{ lat: 29.94986378574554, lng: -90.06435609790535 },
		{ lat: 29.94978506740574, lng: -90.06420932717367 },
		{ lat: 29.94977303668517, lng: -90.06407209531504 },
		{ lat: 29.94981725673774, lng: -90.06380546722099 },
		{ lat: 29.94991009496815, lng: -90.06362572836102 },
		{ lat: 29.94999533710407, lng: -90.06357565071146 },
		{ lat: 29.9502347318853, lng: -90.06362147510558 },
		{ lat: 29.95183932298065, lng: -90.06379525415507 },
		{ lat: 29.95245373329763, lng: -90.06382806426319 },
		{ lat: 29.95272051479341, lng: -90.06381611505631 },
		{ lat: 29.95290081517404, lng: -90.06379498448767 },
		{ lat: 29.95319345835341, lng: -90.06374290718568 },
		{ lat: 29.95352933307049, lng: -90.06368006086569 },
		{ lat: 29.95424031869226, lng: -90.06355071438564 },
		{ lat: 29.95440053156229, lng: -90.06351067333326 },
		{ lat: 29.95462090460032, lng: -90.06343013913468 },
		{ lat: 29.95508827803539, lng: -90.06324231246629 },
		{ lat: 29.95536221806294, lng: -90.06311116516612 },
		{ lat: 29.95549873611338, lng: -90.06301809826536 },
		{ lat: 29.95575421978079, lng: -90.06280094972675 },
		{ lat: 29.95801600764805, lng: -90.06079391648926 },
		{ lat: 29.95901411738806, lng: -90.0598771674117 },
		{ lat: 29.95920688170435, lng: -90.05967217442135 },
		{ lat: 29.95931263457719, lng: -90.05954766281593 },
		{ lat: 29.95938494975204, lng: -90.0594452366979 },
		{ lat: 29.95965582473827, lng: -90.05902101210646 },
		{ lat: 29.96081000846618, lng: -90.05727532499154 }
	];
	var lineArr1 = new google.maps.Polyline({
		path: lineArr1Coords,
		geodesic: true,
		strokeColor: '#f2af23',
		strokeOpacity: 0.5,
		strokeWeight: 5
	});
	lineArr1.setMap(null);

	// Riverfront
	var lineArr2Coords = [
		{ lat: 29.96072576318117, lng: -90.05721092558527 },
		{ lat: 29.95949404866021, lng: -90.05908127535825 },
		{ lat: 29.95930274554306, lng: -90.05938329129141 },
		{ lat: 29.95920891533251, lng: -90.05950179855772 },
		{ lat: 29.95905926650196, lng: -90.05967865534119 },
		{ lat: 29.95887584645028, lng: -90.05986105506456 },
		{ lat: 29.9579362729748, lng: -90.0607296910562 },
		{ lat: 29.95565949612008, lng: -90.06274785540462 },
		{ lat: 29.95545498380253, lng: -90.06291935444132 },
		{ lat: 29.95531397117128, lng: -90.06301002877824 },
		{ lat: 29.95511490939908, lng: -90.06311308047174 },
		{ lat: 29.95460529422011, lng: -90.06332333173084 },
		{ lat: 29.95436564666695, lng: -90.06339204490463 },
		{ lat: 29.95418668110415, lng: -90.06342921883099 },
		{ lat: 29.95348950828043, lng: -90.06355597919827 },
		{ lat: 29.95316157149701, lng: -90.06362179252882 },
		{ lat: 29.95290772152499, lng: -90.06366201519478 },
		{ lat: 29.952698824976, lng: -90.06369196219687 },
		{ lat: 29.9523973315426, lng: -90.06369537237278 },
		{ lat: 29.95191295347231, lng: -90.06368727336972 },
		{ lat: 29.9502486429941, lng: -90.06351793031277 },
		{ lat: 29.95003398593667, lng: -90.06345515337324 },
		{ lat: 29.94974565006404, lng: -90.06343173988108 },
		{ lat: 29.94838124870267, lng: -90.0633167516058 },
		{ lat: 29.94679545462635, lng: -90.06304627633564 },
		{ lat: 29.94616442824967, lng: -90.06290364641684 },
		{ lat: 29.94531982177028, lng: -90.06268934687837 },
		{ lat: 29.94376975298902, lng: -90.06250103324308 },
		{ lat: 29.94124129905854, lng: -90.06225179018138 }
	];
	var lineArr2 = new google.maps.Polyline({
		path: lineArr2Coords,
		geodesic: true,
		strokeColor: '#163f73',
		strokeOpacity: 0.5,
		strokeWeight: 5
	});
	lineArr2.setMap(null);

	// St Charles
	var lineArr3Coords = [
		{ lat: 29.95588451594215, lng: -90.12048769652046 },
		{ lat: 29.95435077740343, lng: -90.12213963160848 },
		{ lat: 29.95293974776969, lng: -90.12363619844793 },
		{ lat: 29.95151637241469, lng: -90.12517516787439 },
		{ lat: 29.94942473204766, lng: -90.12739539383047 },
		{ lat: 29.94870523231114, lng: -90.12818172612565 },
		{ lat: 29.94727379453779, lng: -90.12970242465094 },
		{ lat: 29.94447555047532, lng: -90.13271614867099 },
		{ lat: 29.9458675318723, lng: -90.13119952957347 },
		{ lat: 29.94308706558925, lng: -90.1341717881714 },
		{ lat: 29.9417683829399, lng: -90.13254994747733 },
		{ lat: 29.93987054928687, lng: -90.1301851373704 },
		{ lat: 29.93832540749651, lng: -90.12826952158851 },
		{ lat: 29.93725702825096, lng: -90.12681961837662 },
		{ lat: 29.93626117891848, lng: -90.1254064552934 },
		{ lat: 29.93516759404773, lng: -90.12386725491912 },
		{ lat: 29.93420851508006, lng: -90.12250410113474 },
		{ lat: 29.93354950492687, lng: -90.12159895330305 },
		{ lat: 29.93290924265887, lng: -90.12068720056757 },
		{ lat: 29.93190114908945, lng: -90.11925921220595 },
		{ lat: 29.93109675681673, lng: -90.11808651278508 },
		{ lat: 29.93023847011816, lng: -90.11659121965124 },
		{ lat: 29.9294151857037, lng: -90.11486672723657 },
		{ lat: 29.92851516886373, lng: -90.11297612534477 },
		{ lat: 29.92783402659291, lng: -90.11151379510609 },
		{ lat: 29.92744547074515, lng: -90.11066164757158 },
		{ lat: 29.92653943668065, lng: -90.10877603575534 },
		{ lat: 29.92633599663944, lng: -90.10828549112635 },
		{ lat: 29.92615863243919, lng: -90.10769455797627 },
		{ lat: 29.9261084383308, lng: -90.10718114381916 },
		{ lat: 29.9261350495093, lng: -90.10669377853793 },
		{ lat: 29.92629585400237, lng: -90.10441710581139 },
		{ lat: 29.92648350671618, lng: -90.10206114348401 },
		{ lat: 29.9266583507807, lng: -90.09968463427157 },
		{ lat: 29.92683341925037, lng: -90.09743740865127 },
		{ lat: 29.92699647956913, lng: -90.09555036235172 },
		{ lat: 29.92713776541798, lng: -90.09465734930399 },
		{ lat: 29.92745130876439, lng: -90.09369387533822 },
		{ lat: 29.92824839230759, lng: -90.09142779429911 },
		{ lat: 29.92870912769659, lng: -90.09018071657597 },
		{ lat: 29.92901771620352, lng: -90.08948245443199 },
		{ lat: 29.92928321854136, lng: -90.08898168006826 },
		{ lat: 29.93017064989797, lng: -90.08733939003975 },
		{ lat: 29.93093086817603, lng: -90.08599226187377 },
		{ lat: 29.93187635727396, lng: -90.08431391051032 },
		{ lat: 29.93278259227111, lng: -90.08270166572204 },
		{ lat: 29.93411675129675, lng: -90.08059497075385 },
		{ lat: 29.9347597588692, lng: -90.07963952875625 },
		{ lat: 29.93531530870986, lng: -90.07878203456114 },
		{ lat: 29.93627693059542, lng: -90.0775788749191 },
		{ lat: 29.93768691630412, lng: -90.0765139931224 },
		{ lat: 29.93910916005002, lng: -90.07547574466771 },
		{ lat: 29.94048810533097, lng: -90.07443528995067 },
		{ lat: 29.94278892567705, lng: -90.07276513946211 },
		{ lat: 29.94292943619148, lng: -90.0726641313202 },
		{ lat: 29.9428852332346, lng: -90.07253691693246 },
		{ lat: 29.94290353015723, lng: -90.07228307813375 },
		{ lat: 29.94301699748393, lng: -90.07208910982558 },
		{ lat: 29.94322825734276, lng: -90.07194956238361 },
		{ lat: 29.94352018163309, lng: -90.07199184126726 },
		{ lat: 29.94368501301834, lng: -90.07212447370624 },
		{ lat: 29.94378613740544, lng: -90.07245246396261 },
		{ lat: 29.94377422658048, lng: -90.07260866519252 },
		{ lat: 29.94369111746159, lng: -90.0727856385796 },
		{ lat: 29.94359296252009, lng: -90.07288975585318 },
		{ lat: 29.94374505940982, lng: -90.07313568766348 },
		{ lat: 29.9440238238152, lng: -90.07360030839665 },
		{ lat: 29.94621501133091, lng: -90.07293099768452 },
		{ lat: 29.94766400978025, lng: -90.07249293420827 },
		{ lat: 29.94986947784997, lng: -90.07184213646244 },
		{ lat: 29.95192738802691, lng: -90.07120924538299 },
		{ lat: 29.95274585433219, lng: -90.07093862515617 },
		{ lat: 29.95360281080447, lng: -90.07014894958691 },
		{ lat: 29.95299412767473, lng: -90.06923401681733 },
		{ lat: 29.95219390589763, lng: -90.06977668445174 },
		{ lat: 29.95103387712275, lng: -90.07017123529757 },
		{ lat: 29.94956860716009, lng: -90.07058906173475 },
		{ lat: 29.94829015802473, lng: -90.07096317120406 },
		{ lat: 29.94734576643449, lng: -90.07126088466031 },
		{ lat: 29.94591921367382, lng: -90.0716577085921 },
		{ lat: 29.94435643454505, lng: -90.07216103955018 },
		{ lat: 29.9437533973309, lng: -90.07233979415085 },
		{ lat: 29.94378613740544, lng: -90.07245246396261 },
		{ lat: 29.94377422658048, lng: -90.07260866519252 },
		{ lat: 29.94369111746159, lng: -90.0727856385796 },
		{ lat: 29.94359296252009, lng: -90.07288975585318 },
		{ lat: 29.94350684173629, lng: -90.07294773180891 },
		{ lat: 29.94326648947834, lng: -90.07295812343071 },
		{ lat: 29.94309326988916, lng: -90.07290234961492 },
		{ lat: 29.94296401494861, lng: -90.07277224421165 },
		{ lat: 29.94292943619148, lng: -90.0726641313202 }
	];
	var lineArr3 = new google.maps.Polyline({
		path: lineArr3Coords,
		geodesic: true,
		strokeColor: '#3c3918',
		strokeOpacity: 0.5,
		strokeWeight: 5
	});
	lineArr3.setMap(null);

	// Canal (City Park)
	var lineArr4Coords = [
		{ lat: 29.9501303972444, lng: -90.0643779050596 },
		{ lat: 29.95128136441888, lng: -90.0660701263275 },
		{ lat: 29.95198472387319, lng: -90.06710940487405 },
		{ lat: 29.95268821775225, lng: -90.06812765151705 },
		{ lat: 29.95326023762656, lng: -90.06900671143208 },
		{ lat: 29.95387370449223, lng: -90.06990827076311 },
		{ lat: 29.95449089988801, lng: -90.07080488665255 },
		{ lat: 29.95575060571595, lng: -90.07267953984079 },
		{ lat: 29.95622050890125, lng: -90.07336260448774 },
		{ lat: 29.95750698725508, lng: -90.07524909282742 },
		{ lat: 29.95907505815425, lng: -90.0775723444706 },
		{ lat: 29.96099867551288, lng: -90.08043191014932 },
		{ lat: 29.96238762467981, lng: -90.08248006231948 },
		{ lat: 29.96338665431713, lng: -90.08393821017901 },
		{ lat: 29.96469723989904, lng: -90.08585005604665 },
		{ lat: 29.96558887716524, lng: -90.08721033590682 },
		{ lat: 29.96666068096762, lng: -90.08873262725726 },
		{ lat: 29.96836300570542, lng: -90.09125095913659 },
		{ lat: 29.9688042038205, lng: -90.0918939206245 },
		{ lat: 29.97003969731323, lng: -90.09373804300508 },
		{ lat: 29.97093738167001, lng: -90.09505180639401 },
		{ lat: 29.97204292040036, lng: -90.09668968590177 },
		{ lat: 29.97324860004867, lng: -90.09848335943248 },
		{ lat: 29.97461593101117, lng: -90.10050578931502 },
		{ lat: 29.97609211651731, lng: -90.09891565446154 },
		{ lat: 29.97752120884931, lng: -90.09741423179391 },
		{ lat: 29.97936381763191, lng: -90.09545458104653 },
		{ lat: 29.98059318250704, lng: -90.09412824286045 },
		{ lat: 29.98329864472697, lng: -90.09121374078163 },
		{ lat: 29.98361248754386, lng: -90.09045321995168 },
		{ lat: 29.98388516531299, lng: -90.09004941334231 }
	];
	var lineArr4 = new google.maps.Polyline({
		path: lineArr4Coords,
		geodesic: true,
		strokeColor: '#5a9014',
		strokeOpacity: 0.5,
		strokeWeight: 5
	});
	lineArr4.setMap(null);

	// Canal (Cemeteries)
	var lineArr5Coords = [
		{ lat: 29.94997216604273, lng: -90.0643068093885 },
		{ lat: 29.9511630450713, lng: -90.06609969205175 },
		{ lat: 29.95189348424505, lng: -90.06718906713711 },
		{ lat: 29.95259126292639, lng: -90.06820446589416 },
		{ lat: 29.95317541355566, lng: -90.06906882976521 },
		{ lat: 29.95379569847655, lng: -90.06998628228075 },
		{ lat: 29.95440307115056, lng: -90.0708828120477 },
		{ lat: 29.95566367078477, lng: -90.07276264923014 },
		{ lat: 29.95611553422269, lng: -90.07340515552497 },
		{ lat: 29.9574128042422, lng: -90.07534012632557 },
		{ lat: 29.95896142812788, lng: -90.07758615297136 },
		{ lat: 29.96093001529502, lng: -90.08051764801833 },
		{ lat: 29.9623481296401, lng: -90.08261186902304 },
		{ lat: 29.96329853743434, lng: -90.08400195069588 },
		{ lat: 29.9645864749207, lng: -90.08590864621061 },
		{ lat: 29.96558090875053, lng: -90.08741624544564 },
		{ lat: 29.96655406033803, lng: -90.08881499637481 },
		{ lat: 29.9682566938828, lng: -90.09133176170241 },
		{ lat: 29.96869153053613, lng: -90.09197526162224 },
		{ lat: 29.96991847718482, lng: -90.09379423853152 },
		{ lat: 29.97083833303487, lng: -90.09514323174663 },
		{ lat: 29.97194103333752, lng: -90.09677368006317 },
		{ lat: 29.97315241354768, lng: -90.09856408599114 },
		{ lat: 29.97444729595652, lng: -90.10047267336452 },
		{ lat: 29.97630074041632, lng: -90.10320536175807 },
		{ lat: 29.97754226300301, lng: -90.10503388104428 },
		{ lat: 29.97877598861882, lng: -90.10687969642973 },
		{ lat: 29.98002652484633, lng: -90.10873468634115 },
		{ lat: 29.98170884487135, lng: -90.111200950438 }
	];
	var lineArr5 = new google.maps.Polyline({
		path: lineArr5Coords,
		geodesic: true,
		strokeColor: '#9e1711',
		strokeOpacity: 0.5,
		strokeWeight: 5
	});
	lineArr5.setMap(null);






	/* ===== MINOR STOPS ICONS ===== */
	var minorStopsArr1Coords = [
		{ lat: 29.94550897337769, lng: -90.07836155682659 },
		{ lat: 29.94713929508177, lng: -90.07675800007579 },
		{ lat: 29.95085353288903, lng: -90.07567387831044 },
		{ lat: 29.95459919878837, lng: -90.07479082489162 },
		{ lat: 29.95558057142821, lng: -90.07284579882317 },
		{ lat: 29.9543093058994, lng: -90.07096036841905 },
		{ lat: 29.95371055426573, lng: -90.07005491946735 },
		{ lat: 29.95309364271721, lng: -90.06915143961388 },
		{ lat: 29.95249786682122, lng: -90.06826046734378 },
		{ lat: 29.95180547366068, lng: -90.06726158594196 },
		{ lat: 29.95104975336925, lng: -90.06613642514891 },
		{ lat: 29.94978506740574, lng: -90.06420932717367 },
		{ lat: 29.9502347318853, lng: -90.06362147510558 },
		{ lat: 29.95272051479341, lng: -90.06381611505631 },
		{ lat: 29.95575421978079, lng: -90.06280094972675 },
		{ lat: 29.95801600764805, lng: -90.06079391648926 },
		{ lat: 29.95965582473827, lng: -90.05902101210646 },
		{ lat: 29.96081000846618, lng: -90.05727532499154 }
	];


	// Riverfront
	var minorStopsArr2Coords = [
		{ lat: 29.96072576318117, lng: -90.05721092558527 },
		{ lat: 29.95949404866021, lng: -90.05908127535825 },
		{ lat: 29.9579362729748, lng: -90.0607296910562 },
		{ lat: 29.95565949612008, lng: -90.06274785540462 },
		{ lat: 29.952698824976, lng: -90.06369196219687 },
		{ lat: 29.9502486429941, lng: -90.06351793031277 },
		{ lat: 29.94838124870267, lng: -90.0633167516058 },
		{ lat: 29.94376975298902, lng: -90.06250103324308 },
		{ lat: 29.94124129905854, lng: -90.06225179018138 }
	];


	// St Charles
	var minorStopsArr3Coords = [
		{ lat: 29.95588451594215, lng: -90.12048769652046 },
		{ lat: 29.95435077740343, lng: -90.12213963160848 },
		{ lat: 29.95293974776969, lng: -90.12363619844793 },
		{ lat: 29.95151637241469, lng: -90.12517516787439 },
		{ lat: 29.94942473204766, lng: -90.12739539383047 },
		{ lat: 29.94870523231114, lng: -90.12818172612565 },
		{ lat: 29.94727379453779, lng: -90.12970242465094 },
		{ lat: 29.94447555047532, lng: -90.13271614867099 },
		{ lat: 29.9458675318723, lng: -90.13119952957347 },
		{ lat: 29.94308706558925, lng: -90.1341717881714 },
		{ lat: 29.9417683829399, lng: -90.13254994747733 },
		{ lat: 29.93987054928687, lng: -90.1301851373704 },
		{ lat: 29.93832540749651, lng: -90.12826952158851 },
		{ lat: 29.93725702825096, lng: -90.12681961837662 },
		{ lat: 29.93626117891848, lng: -90.1254064552934 },
		{ lat: 29.93516759404773, lng: -90.12386725491912 },
		{ lat: 29.93420851508006, lng: -90.12250410113474 },
		{ lat: 29.93354950492687, lng: -90.12159895330305 },
		{ lat: 29.93290924265887, lng: -90.12068720056757 },
		{ lat: 29.93190114908945, lng: -90.11925921220595 },
		{ lat: 29.93109675681673, lng: -90.11808651278508 },
		{ lat: 29.93023847011816, lng: -90.11659121965124 },
		{ lat: 29.9294151857037, lng: -90.11486672723657 },
		{ lat: 29.92851516886373, lng: -90.11297612534477 },
		{ lat: 29.92783402659291, lng: -90.11151379510609 },
		{ lat: 29.92744547074515, lng: -90.11066164757158 },
		{ lat: 29.92653943668065, lng: -90.10877603575534 },
		{ lat: 29.9261350495093, lng: -90.10669377853793 },
		{ lat: 29.92629585400237, lng: -90.10441710581139 },
		{ lat: 29.92648350671618, lng: -90.10206114348401 },
		{ lat: 29.9266583507807, lng: -90.09968463427157 },
		{ lat: 29.92683341925037, lng: -90.09743740865127 },
		{ lat: 29.92699647956913, lng: -90.09555036235172 },
		{ lat: 29.92745130876439, lng: -90.09369387533822 },
		{ lat: 29.92824839230759, lng: -90.09142779429911 },
		{ lat: 29.92928321854136, lng: -90.08898168006826 },
		{ lat: 29.93017064989797, lng: -90.08733939003975 },
		{ lat: 29.93093086817603, lng: -90.08599226187377 },
		{ lat: 29.93187635727396, lng: -90.08431391051032 },
		{ lat: 29.93278259227111, lng: -90.08270166572204 },
		{ lat: 29.93411675129675, lng: -90.08059497075385 },
		{ lat: 29.9347597588692, lng: -90.07963952875625 },
		{ lat: 29.93531530870986, lng: -90.07878203456114 },
		{ lat: 29.93627693059542, lng: -90.0775788749191 },
		{ lat: 29.93768691630412, lng: -90.0765139931224 },
		{ lat: 29.93910916005002, lng: -90.07547574466771 },
		{ lat: 29.94048810533097, lng: -90.07443528995067 },
		{ lat: 29.94278892567705, lng: -90.07276513946211 },
		{ lat: 29.94374505940982, lng: -90.07313568766348 },
		{ lat: 29.94621501133091, lng: -90.07293099768452 },
		{ lat: 29.94766400978025, lng: -90.07249293420827 },
		{ lat: 29.94986947784997, lng: -90.07184213646244 },
		{ lat: 29.95192738802691, lng: -90.07120924538299 },
		{ lat: 29.95360281080447, lng: -90.07014894958691 },
		{ lat: 29.95299412767473, lng: -90.06923401681733 },
		{ lat: 29.95219390589763, lng: -90.06977668445174 },
		{ lat: 29.95103387712275, lng: -90.07017123529757 },
		{ lat: 29.94956860716009, lng: -90.07058906173475 },
		{ lat: 29.94829015802473, lng: -90.07096317120406 },
		{ lat: 29.94734576643449, lng: -90.07126088466031 },
		{ lat: 29.94591921367382, lng: -90.0716577085921 },
		{ lat: 29.94435643454505, lng: -90.07216103955018 }
	];

	// Canal (City Park)
	var minorStopsArr4Coords = [
		{ lat: 29.9501303972444, lng: -90.0643779050596 },
		{ lat: 29.95128136441888, lng: -90.0660701263275 },
		{ lat: 29.95198472387319, lng: -90.06710940487405 },
		{ lat: 29.95268821775225, lng: -90.06812765151705 },
		{ lat: 29.95326023762656, lng: -90.06900671143208 },
		{ lat: 29.95387370449223, lng: -90.06990827076311 },
		{ lat: 29.95449089988801, lng: -90.07080488665255 },
		{ lat: 29.95575060571595, lng: -90.07267953984079 },
		{ lat: 29.95622050890125, lng: -90.07336260448774 },
		{ lat: 29.95750698725508, lng: -90.07524909282742 },
		{ lat: 29.95907505815425, lng: -90.0775723444706 },
		{ lat: 29.96099867551288, lng: -90.08043191014932 },
		{ lat: 29.96238762467981, lng: -90.08248006231948 },
		{ lat: 29.96338665431713, lng: -90.08393821017901 },
		{ lat: 29.96469723989904, lng: -90.08585005604665 },
		{ lat: 29.96558887716524, lng: -90.08721033590682 },
		{ lat: 29.96666068096762, lng: -90.08873262725726 },
		{ lat: 29.96836300570542, lng: -90.09125095913659 },
		{ lat: 29.9688042038205, lng: -90.0918939206245 },
		{ lat: 29.97003969731323, lng: -90.09373804300508 },
		{ lat: 29.97093738167001, lng: -90.09505180639401 },
		{ lat: 29.97204292040036, lng: -90.09668968590177 },
		{ lat: 29.97324860004867, lng: -90.09848335943248 },
		{ lat: 29.97461593101117, lng: -90.10050578931502 },
		{ lat: 29.97609211651731, lng: -90.09891565446154 },
		{ lat: 29.97752120884931, lng: -90.09741423179391 },
		{ lat: 29.97936381763191, lng: -90.09545458104653 },
		{ lat: 29.98059318250704, lng: -90.09412824286045 },
		{ lat: 29.98329864472697, lng: -90.09121374078163 },
		{ lat: 29.98388516531299, lng: -90.09004941334231 }
	];

	// Canal (Cemeteries)
	var minorStopsArr5Coords = [
		{ lat: 29.94997216604273, lng: -90.0643068093885 },
		{ lat: 29.9511630450713, lng: -90.06609969205175 },
		{ lat: 29.95189348424505, lng: -90.06718906713711 },
		{ lat: 29.95259126292639, lng: -90.06820446589416 },
		{ lat: 29.95317541355566, lng: -90.06906882976521 },
		{ lat: 29.95379569847655, lng: -90.06998628228075 },
		{ lat: 29.95440307115056, lng: -90.0708828120477 },
		{ lat: 29.95566367078477, lng: -90.07276264923014 },
		{ lat: 29.95611553422269, lng: -90.07340515552497 },
		{ lat: 29.9574128042422, lng: -90.07534012632557 },
		{ lat: 29.95896142812788, lng: -90.07758615297136 },
		{ lat: 29.96093001529502, lng: -90.08051764801833 },
		{ lat: 29.9623481296401, lng: -90.08261186902304 },
		{ lat: 29.96329853743434, lng: -90.08400195069588 },
		{ lat: 29.9645864749207, lng: -90.08590864621061 },
		{ lat: 29.96558090875053, lng: -90.08741624544564 },
		{ lat: 29.96655406033803, lng: -90.08881499637481 },
		{ lat: 29.9682566938828, lng: -90.09133176170241 },
		{ lat: 29.96869153053613, lng: -90.09197526162224 },
		{ lat: 29.96991847718482, lng: -90.09379423853152 },
		{ lat: 29.97083833303487, lng: -90.09514323174663 },
		{ lat: 29.97194103333752, lng: -90.09677368006317 },
		{ lat: 29.97315241354768, lng: -90.09856408599114 },
		{ lat: 29.97444729595652, lng: -90.10047267336452 },
		{ lat: 29.97630074041632, lng: -90.10320536175807 },
		{ lat: 29.97754226300301, lng: -90.10503388104428 },
		{ lat: 29.97877598861882, lng: -90.10687969642973 },
		{ lat: 29.98002652484633, lng: -90.10873468634115 },
		{ lat: 29.98170884487135, lng: -90.111200950438 }
	];

	var majorStopsArrCoords = [
		{ lat: 29.95379569847655, lng: -90.06998628228075 },
		{ lat: 29.95896142812788, lng: -90.07758615297136 },
		{ lat: 29.96558090875053, lng: -90.08741624544564 },
		{ lat: 29.96991847718482, lng: -90.09379423853152 },
		{ lat: 29.97444729595652, lng: -90.10047267336452 },
		{ lat: 29.98170884487135, lng: -90.111200950438 },
		{ lat: 29.98388516531299, lng: -90.09004941334231 },
		{ lat: 29.94550897337769, lng: -90.07836155682659 },
		{ lat: 29.95085353288903, lng: -90.07567387831044 },
		{ lat: 29.94981725673774, lng: -90.06380546722099 },
		{ lat: 29.96072576318117, lng: -90.05721092558527 },
		{ lat: 29.94124129905854, lng: -90.06225179018138 },
		{ lat: 29.95588451594215, lng: -90.12048769652046 },
		{ lat: 29.94727379453779, lng: -90.12970242465094 },
		{ lat: 29.94308706558925, lng: -90.1341717881714 },
		{ lat: 29.93725702825096, lng: -90.12681961837662 },
		{ lat: 29.93516759404773, lng: -90.12386725491912 },
		{ lat: 29.92851516886373, lng: -90.11297612534477 },
		{ lat: 29.92648350671618, lng: -90.10206114348401 },
		{ lat: 29.92824839230759, lng: -90.09142779429911 },
		{ lat: 29.93093086817603, lng: -90.08599226187377 },
		{ lat: 29.93411675129675, lng: -90.08059497075385 },
		{ lat: 29.94048810533097, lng: -90.07443528995067 },
		{ lat: 29.94278892567705, lng: -90.07276513946211 }
	];



	var stCharlesLabel = {
		url: '/img/streetcars/st-charles.gif',
		size: new google.maps.Size(101,26),
		origin: new google.maps.Point(100,100),
		anchor: new google.maps.Point(0,26)
	}
	var majorStopLabelStCharles = new google.maps.Marker({
		position: { lat: 29.956401, lng: -90.120505 },
		map: null,
		icon: stCharlesLabel
		//size: new google.maps.Size(101,26),
		//anchor: new google.maps.Point(0,200)
	});
	var majorStopLabelLoyola = new google.maps.Marker({
		position: { lat: 29.945230, lng: -90.078277 },
		map: null,
		icon: '/img/streetcars/loyola.gif',
		size: new google.maps.Size(101,26),
	});
	var majorStopLabelRiverfront = new google.maps.Marker({
		position: { lat: 29.961070, lng: -90.056887 },
		map: null,
		icon: '/img/streetcars/riverfront.gif',
		size: new google.maps.Size(101,26)
	});
	var majorStopLabelCityPark = new google.maps.Marker({
		position: { lat: 29.983913, lng: -90.089941 },
		map: null,
		icon: '/img/streetcars/canal-city-park.gif',
		size: new google.maps.Size(101,26)
	});
	var majorStopLabelCemeteries = new google.maps.Marker({
		position: { lat: 29.982084, lng: -90.111591 },
		map: null,
		icon: '/img/streetcars/canal-cemeteries.gif',
		size: new google.maps.Size(101,26)
	});




	$scope.streetcarsShowing = false;
	var lines = [ lineArr1, lineArr2, lineArr3, lineArr4, lineArr5 ];
	var minorStops = [];
	var majorStops = [];

	$scope.toggleStreetcars = function() {
		if(!$scope.streetcarsShowing) {

			angular.element('.map-transportation').css('background-position','-34px 0');

			lineArr1.setMap($scope.desktopMap);
			lineArr2.setMap($scope.desktopMap);
			lineArr3.setMap($scope.desktopMap);
			lineArr4.setMap($scope.desktopMap);
			lineArr5.setMap($scope.desktopMap);

			minorStopsArr1Coords.forEach(function(value, index) {
				var minorStopMarker = new google.maps.Marker({
					position: value,
					map: $scope.desktopMap,
					icon: '/img/streetcars/streetcar-stop-minor.png',
					size: new google.maps.Size(2, 2),
				});
				minorStops.push(minorStopMarker);
			});
			minorStopsArr2Coords.forEach(function(value, index) {
				var minorStopMarker = new google.maps.Marker({
					position: value,
					map: $scope.desktopMap,
					icon: '/img/streetcars/streetcar-stop-minor.png',
					size: new google.maps.Size(2, 2),
				});
				minorStops.push(minorStopMarker);
			});
			minorStopsArr3Coords.forEach(function(value, index) {
				var minorStopMarker = new google.maps.Marker({
					position: value,
					map: $scope.desktopMap,
					icon: '/img/streetcars/streetcar-stop-minor.png',
					size: new google.maps.Size(2,2),
				});
				minorStops.push(minorStopMarker);
			});
			minorStopsArr4Coords.forEach(function(value, index) {
				var minorStopMarker = new google.maps.Marker({
					position: value,
					map: $scope.desktopMap,
					icon: '/img/streetcars/streetcar-stop-minor.png',
					size: new google.maps.Size(2,2),
				});
				minorStops.push(minorStopMarker);
			});
			minorStopsArr5Coords.forEach(function(value, index) {
				var minorStopMarker = new google.maps.Marker({
					position: value,
					map: $scope.desktopMap,
					icon: {
						url: '/img/streetcars/streetcar-stop-minor.png',
						size: new google.maps.Size(2,2)
					}
				});
				minorStops.push(minorStopMarker);
			});

			majorStopsArrCoords.forEach(function(value, index) {
				var majorStopMarker = new google.maps.Marker({
					position: value,
					map: $scope.desktopMap,
					icon: {
						url: '/img/streetcars/streetcar-stop-major.png',
						size: new google.maps.Size(26,26),
						origin: new google.maps.Point(0,0),
						anchor: new google.maps.Point(13,13)
					}

				});
				majorStops.push(majorStopMarker);
			});




			var majorStopLabelStCharles = new google.maps.Marker({
				position: { lat: 29.956401, lng: -90.120505 },
				map: $scope.desktopMap,
				icon: {
					url: '/img/streetcars/st-charles.gif',
					size: new google.maps.Size(101,26),
					origin: new google.maps.Point(0,0),
					anchor: new google.maps.Point(101,42)
				},
				zIndex: 999
			});
			majorStops.push(majorStopLabelStCharles);


			var majorStopLabelLoyola = new google.maps.Marker({
				position: { lat: 29.945230, lng: -90.078277 },
				map: $scope.desktopMap,
				icon: {
					url: '/img/streetcars/loyola.gif',
					size: new google.maps.Size(108,26),
					origin: new google.maps.Point(0,0),
					anchor: new google.maps.Point(108, 52)
				}
			});
			majorStops.push(majorStopLabelLoyola);


			var majorStopLabelRiverfront = new google.maps.Marker({
				position: { lat: 29.961070, lng: -90.056887 },
				map: $scope.desktopMap,
				icon: {
					url: '/img/streetcars/riverfront.gif',
					size: new google.maps.Size(108,26),
					origin: new google.maps.Point(0,0),
					anchor: new google.maps.Point(108,52)
				}

			});
			majorStops.push(majorStopLabelRiverfront);


			var majorStopLabelCityPark = new google.maps.Marker({
				position: { lat: 29.983913, lng: -90.089941 },
				map: $scope.desktopMap,
				icon: {
					url: '/img/streetcars/canal-city-park.gif',
					size: new google.maps.Size(145,25),
					origin: new google.maps.Point(0,0),
					anchor: new google.maps.Point(101,55)
				}
			});
			majorStops.push(majorStopLabelCityPark);


			var majorStopLabelCemeteries = new google.maps.Marker({
				position: { lat: 29.982084, lng: -90.111591 },
				map: $scope.desktopMap,
				icon: {
					url: '/img/streetcars/canal-cemeteries.gif',
					size: new google.maps.Size(149,26),
					origin: new google.maps.Point(0,0),
					anchor: new google.maps.Point(105, 52)
				}
			});
			majorStops.push(majorStopLabelCemeteries);


			$scope.streetcarsShowing = true;



		}
		else {

			angular.element('.map-transportation').css('background-position','0 0');
			lineArr1.setMap(null);
			lineArr2.setMap(null);
			lineArr3.setMap(null);
			lineArr4.setMap(null);
			lineArr5.setMap(null);
			minorStops.forEach(function(value, index) {
				minorStops[index].setMap(null);
			});
			minorStops = [];
			majorStops.forEach(function(value, index) {
				majorStops[index].setMap(null);
			});
			majorStops = [];


			$scope.streetcarsShowing = false;
		}
	}







/*	var neighborhoodLabelArray = [
		uptownMarker,
		cbdMarker,
		esplanadeMarker,
		algiersMarker,
		bywaterMarker,
		frenchQuarterMarker,
		gardenDistrictMarker,
		midCityMarker,
		lakeViewMarker,
		warehouseDistrictMarker,
		tremeMarker,
		gentillyMarker,
		marignyMarker,
		centralCityMarker
	];
	var neighborhoodArray = [
		cbdPoly,
		algiersPoly,
		warehousePoly,
		frenchQuarterPoly,
		esplanadePoly,
		uptownPoly,
		midCityPoly,
		marignyBywaterPoly,
		lakeViewPoly,
		tremePoly,
		gentillyPoly
	];

	$scope.layersOn = false;
    $scope.toggleNeighborhoodOverlay = function() {


    	leafletData.getMap('resultsList').then(function(map) {
	    	if(!$scope.layersOn) {
	    		neighborhoodArray.forEach(function(value) {
	    			value.addTo(map);
	    		});
	    		neighborhoodLabelArray.forEach(function(value) {
	    			value.addTo(map);
	    		});
	    		$scope.layersOn = true;
	    	}
	    	else {
	    		neighborhoodArray.forEach(function(value) {
	    			map.removeLayer(value);
	    		});
	    		neighborhoodLabelArray.forEach(function(value) {
	    			map.removeLayer(value);
	    		});
	    		$scope.layersOn = false;
		    }
		});
    };


*/


/*    $scope.streetcarsOn = false;

   	var allLines = [];
   	var linesArr = [lineArr1,lineArr2,lineArr3,lineArr4,lineArr5];
   	var line = [];
   	var allMinorStops = [];
   	var allMajorStops = [];
   	var stopIconClass = 'hideIcon';
   	var allSLabels = [];


	leafletData.getMap('resultsList').then(function(map) {
		$.each(linesArr, function(index, val) {
			$.each(val, function(index2, val2) {
				var point = new L.LatLng( val2[0], val2[1] );
				line.push(point);
			});
			var colorVal = '#fff';
			switch ( index ) {
					// Loyola
				case 0:
					colorVal = '#f2af23';
				break;

				// Riverfront
				case 1:
					colorVal = '#163f73';
				break;
				// St Charles
				case 2:
					colorVal = '#3c3918';
				break;
				// Canal (City Park)
				case 3:
					colorVal = '#5a9014';
				break;
				// Canal (Cemeteries)
				case 4:
					colorVal = '#9e1711';
				break;
				default: '#ccc';
			}

			var polyline = L.polyline(line, {
				color: colorVal,
				opacity: 0,
				clickable: false
			}).addTo(map);

			allLines.push(polyline);
			line = [];

		});

		var minorStopsArr = [minorStopsArr1,minorStopsArr2,minorStopsArr3,minorStopsArr4,minorStopsArr5];
    	var minorStops = [];
    	var minorIconSmall = L.icon({
    		iconUrl: '/img/streetcars/streetcar-stop-minor.png',
    		iconSize: [3, 3],
    		className: stopIconClass
    	});
    	$.each(minorStopsArr, function(index, val) {
    		$.each(val, function(index2, val2) {
    			var point = new L.LatLng( val2[0], val2[1] );
    			var marker = L.marker(point,{
    				icon: minorIconSmall,
    				clickable: false,
    				keyboard: false
    			}).addTo(map);

    			allMinorStops.push(marker);
    		});
    	});

    	var majorIcon = L.icon({
    		iconUrl: '/img/streetcars/streetcar-stop-major.png',
    		iconSize: [20, 20],
    		className: stopIconClass + ' majorStop'
    	});

    	$.each(majorStopsArr, function(index2, val2) {
    		var point = new L.LatLng( val2[0], val2[1] );
    		var marker = L.marker(point,{
    			icon: majorIcon,
    			clickable: false,
    			keyboard: false
    		}).addTo(map);

    		allMajorStops.push(marker);
    	});

    	var sIconStCharles = L.icon({
    		iconUrl: '/img/streetcars/st-charles.gif',
    		iconSize: [101, 26],
    		iconAnchor: [50, 30],
    		labelAnchor: [0, 0],
    		className: 'sLabel'
    	});
    	stCharlesMarkerS = L.marker([29.956401, -90.120505], {
    		icon: sIconStCharles,
    		clickable: false
    	});

    	var sIconLoyola = L.icon({
    		iconUrl: '/img/streetcars/loyola.gif',
    		iconSize: [101, 26],
    		iconAnchor: [110, 10],
    		labelAnchor: [0, 0],
    		className: 'sLabel'
    	});

    	loyolaMarkerS = L.marker([29.945230, -90.078277], {
    		icon: sIconLoyola,
    		clickable: false
    	});

    	var sIconRiverfront = L.icon({
    		iconUrl: '/img/streetcars/riverfront.gif',
    		iconSize: [108, 26],
    		iconAnchor: [-5, 10],
    		labelAnchor: [0, 0],
    		className: 'sLabel'
    	});
    	riverfrontMarkerS = L.marker([29.961070, -90.056887], {
    		icon: sIconRiverfront,
    		clickable: false
    	});

    	var sIconCanalCityPark = L.icon({
    		iconUrl: '/img/streetcars/canal-city-park.gif',
    		iconSize: [145, 26],
    		iconAnchor: [-4, 10],
    		labelAnchor: [0, 0],
    		className: 'sLabel'
    	});
    	canalCityParkMarkerS = L.marker([29.983913, -90.089941], {
    		icon: sIconCanalCityPark,
    		clickable: false
    	});

    	var sIconCanalCemeteries = L.icon({
    		iconUrl: '/img/streetcars/canal-cemeteries.gif',
    		iconSize: [149, 26],
    		iconAnchor: [153, 10],
    		labelAnchor: [0, 0],
    		className: 'sLabel'
    	});
    	canalCemeteriesMarkerS = L.marker([29.982084, -90.111591], {
    		icon: sIconCanalCemeteries,
    		clickable: false
    	});

    	allSLabels.push( stCharlesMarkerS, loyolaMarkerS, riverfrontMarkerS, canalCityParkMarkerS, canalCemeteriesMarkerS );


    	// createStreetcars
    	var addLabels = function () {
    		$.each(allSLabels, function(i, val) {
    			val.addTo(map);
    		});
    	}
    	var showSLabels = function () {
    		$.each(allSLabels, function(index, val) {
    			val.setOpacity(1);
    		});
    	}
    	var hideSLabels = function () {
    		$.each(allSLabels, function(index, val) {
    			val.setOpacity(0);
    		});
    	}
    	var showStreetcars = function () {
    		$.data(document, 'MyStreetcars');
    		$.each(allLines, function(index, val) {
    			val.setStyle({
    				opacity: 1
    			});
    		});
    		stopIconClass = 'showIcon';
    		checkLineWidthVal( map.getZoom() );
    		showSLabels();
    	}
    	var hideStreetcars = function () {
    		$.data(document, 'MyStreetcars');
    		$.each(allLines, function(index, val) {
    			val.setStyle({
    				opacity: 0
    			});
    		});
    		stopIconClass = 'hideIcon';
    		checkLineWidthVal( map.getZoom() );
    		hideSLabels();
    	}
    	var checkLineWidthVal = function (zoomLevel) {
    		var lineWidth = 1;
    		switch ( zoomLevel ){
    			case 17:
					lineWidth = 10;
					iconSize = 3;
					break;
				case 16:
					lineWidth = 8;
					iconSize = 3;
					break;
				case 15:
					lineWidth = 5;
					iconSize = 2;
					break;
				case 14:
					lineWidth = 3;
					iconSize = 1;
					break;
				case 13:
					lineWidth = 3;
					iconSize = 1;
					break;
				default:
					lineWidth = 3;
					iconSize = 1;
			}
			setLineWidth( lineWidth );
			setIconSize( iconSize );
		}

		var setLineWidth = function (lineWidth) {
			$.each(allLines, function(index, val) {
				val.setStyle({
					weight: lineWidth
				});
			});
		}

		var setIconSize = function (iconSize) {
			var minorIconSmall = L.icon({
				iconUrl: '/img/streetcars/streetcar-stop-minor.png',
				iconSize: [3, 3],
				className: stopIconClass + ' minorStop'
			});

			var minorIconMedium = L.icon({
				iconUrl: '/img/streetcars/streetcar-stop-minor-medium.png',
				iconSize: [6, 6],
				className: stopIconClass + ' minorStop'
			});

			var minorIconBig = L.icon({
				iconUrl: '/img/streetcars/streetcar-stop-minor-big.png',
				iconSize: [10, 10],
				className: stopIconClass + ' minorStop'
			});

			var majorIconSmall = L.icon({
				iconUrl: '/img/streetcars/streetcar-stop-major.png',
				iconSize: [14, 14],
				className: stopIconClass + ' majorStop'
			});

			var majorIconMedium = L.icon({
				iconUrl: '/img/streetcars/streetcar-stop-major.png',
				iconSize: [18, 18],
				className: stopIconClass + ' majorStop'
			});

			var majorIconBig = L.icon({
				iconUrl: '/img/streetcars/streetcar-stop-major.png',
				iconSize: [26, 26],
				className: stopIconClass + ' majorStop'
			});

			var minorIconVal = minorIconSmall;
			var majorIconVal = majorIconSmall;

			switch ( iconSize ){
				case 1:
					minorIconVal = minorIconSmall;
					majorIconVal = majorIconSmall;
					break;
				case 2:
					minorIconVal = minorIconMedium;
					majorIconVal = majorIconMedium;
					break;
				case 3:
					minorIconVal = minorIconBig;
					majorIconVal = majorIconBig;
					break;
				default:
					minorIconVal = minorIconSmall;
					majorIconVal = majorIconSmall;
			}

			$.each(allMinorStops, function(index, val) {
				val.setIcon( minorIconVal );
			});
			$.each(allMajorStops, function(index, val) {
				val.setIcon( majorIconVal );
			});
		}

		var watchZoom = function () {
			settings.map.on('zoomend', function() {
				checkLineWidthVal( map.getZoom() );
			});
		}
		addLabels();



		$scope.toggleStreetcarOverlay = function() {
			if(!$scope.streetcarsOn) {
				showStreetcars();
				$scope.streetcarsOn = true;
			}
			else {
				hideStreetcars();
				$scope.streetcarsOn = false;
			}


		}

	});
*/




    $scope.liked = false;
  	$scope.addNola = function(lid, mobile) {
  		var mobile = mobile || undefined;
  		if(!$auth.isAuthenticated()) {
  			if(mobile) {
  				$scope.openLoginModal();
  			}
  			else {
  				$scope.closeLocationDialog();
  				$scope.openLoginModal();
  			}
  		}
  		else {
	    	AuthService.addLike(lid).then(function(response) {
	    		response.data.location[0].sort = 0;
	    		response.data.location[0].userid = $auth.getPayload().sub;
	    		$scope.data.likes.push(response.data.location[0]);
	    		toastr.info(response.data.location[0].LocationName1+' has been added to your NOLA', {
	    			iconClass: 'toast-blue'
	    		});

	    	})
	    	$scope.liked = true;

  		}
    }


    $scope.removeNolaMobile = function(lid) {
    	AuthService.removeLike(lid).then(function(response) {
    		for(var i = 0; i < $scope.data.likes.length; i++) {
    			if($scope.data.likes[i].LocationID === lid) {
    				toastr.info($scope.data.likes[i].LocationName1+' has been removed from your NOLA', {
    					iconClass: 'toast-blue'
    				});
  					$scope.data.likes.splice(i, 1);
    			}
    		}
    		$scope.liked = false;
    	});
    	if($state.is('mynola-mobile')) {
    		$scope.desktopMarkers.forEach(function(marker, index) {
    			if(marker.locationID === lid) {
    				$scope.infobox.setMap(null);
    				marker.setMap(null);
    			}

    		});
    		$scope.data.results.locations.forEach(function(value, index) {
    			if(value.LocationID === lid) {
    				$scope.data.results.locations.splice(index, 1);
    			}
    		})
    	}
    }




    $scope.removeNola = function(lid) {

    	AuthService.removeLike(lid).then(function(response) {

    		var locationname = response.data.details[0].LocationName1;
    		$scope.closeLocationDialog();
    		// remove item from likes collection
    		$scope.data.likes.forEach(function(value, index) {
    			if(value.LocationID === lid) {
    				toastr.info(locationname+' has been removed from your NOLA', {
    					iconClass: 'toast-blue'
    				});
    				$scope.data.likes.splice(index, 1);
    			}

    		})

    		// remove item from locations array if also in mynola view
    		// Also make the checkboxes respond as well
    		if($state.is('mynola')) {
    			$scope.data.results.locations.forEach(function(value, index) {
    				if(value.LocationID === lid) {
    					$scope.data.results.locations.splice(index, 1);
    					$scope.infobox.setMap(null);
    				}
    			});
    			$scope.desktopMarkers.forEach(function(value) {
    				value.setMap(null);
    			})

				//$scope.desktopMarkers.length = 0;
	    		$scope.plotMapMarkers($scope.data.results.locations);
	    		$scope.mouseoverMarker();

	    		$scope.hasAccommodations = false;
				$scope.hasRestaurants = false;
				$scope.hasNightlife = false;
				$scope.hasAttractions = false;
				$scope.hasShopping = false;
				$scope.assignCheckboxFilters($scope.data.likes);
				$scope.resultsloaded = true;
			}
    		$scope.liked = false;
    	});

    }


    $scope.limitResultsTitleLength = function(title) {
    	if(title.length > 50) {
    		return $filter('limitTo')(title, 55)+'...';
    	}
    	else {
    		return title;
    	}

    }




    $scope.airportDialog = false;
    $scope.toggleAirportDialog = function() {

    	if(!$scope.airportDialog) {
    		angular.element('html.touchevents .map-airport').css('background-position','-34px 0');
    		$scope.airportDialog = true;
    	}
    	else {
    		angular.element('html.touchevents .map-airport').css('background-position','0 0');
    		$scope.airportDialog = false;
    	}



    	//$scope.airportDialog = !$scope.airportDialog;

    }

    $scope.closeAirportDialog = function() {
    	angular.element('html.touchevents .map-airport').css('background-position','0 0');
    	$scope.airportDialog = false;
    }



    $scope.addTip = function($event, locationid) {
    	var postdata = {
    		userid: $auth.getPayload().sub,
    		locationid: locationid,
    		tip: $event.currentTarget[0].value,
    		isPrivate: $event.currentTarget[1].checked
    	}
    	MapService.addTip(postdata).then(function(response) {
   			$scope.tips.push(response.data.tip);
    		toastr.success(response.data.message, {
	    		iconClass: 'toast-blue'
	    	});
    	});
    	// reset the form
    	$event.currentTarget.reset();
    }

	$scope.deleteTipFromLocation = function(tipId, lid) {
		var c = confirm('Are you sure you want to delete this tip? This action cannot be undone.');
		if(c) {
			ResultsService.deleteTipFromLocation(tipId, lid).then(function(res) {
				$scope.tips = res.data;
				toastr.success('Tip deleted', {
					iconClass: 'toast-blue'
				});
			})
		}
		return false;
	}



	/* TOUCH EVENTS STUFF */


	function fadeIn(elem) {

		angular.element(elem).fadeIn();

	}

	$scope.setDoNotShowTourOverview = function(tour) {
		$window.localStorage.setItem('showTourOverview-'+tour, false);
	}


	$scope.toggleTourOverview = function() {

		$scope.hideTourLocationDialog();
		angular.element('.overview').toggleClass('fa-angle-down fa-angle-up');
		angular.element('.tourOverviewPanel').toggleClass('active');
		angular.element('.tourOverviewDialog').toggleClass('active');
	}
	$scope.hideTourOverview = function() {
		angular.element('.overview').removeClass('fa-angle-up').addClass('fa-angle-down');
		angular.element('.tourOverviewPanel').removeClass('active');
		angular.element('.tourOverviewDialog').removeClass('active');
	}



	$scope.hasAudio = false;






	var sliderPosition = 0;

	$scope.gotoSlide = function($index) {
		sliderPosition = $index;
		$scope.advanceSlide();
	}
	$scope.advanceSlide = function() {

		var h = angular.element('.slideWrapper').find('img:nth-child('+(sliderPosition+1)+')').height();

		angular.element('.slideWrapper')
		.css({
			'transform': 'translateX(-'+ sliderPosition * $window.screen.width +'px)',
			'height': h+'px'
		});
		angular.element('.pagerDot').removeClass('active');
		angular.element('.pagerDot:nth-child('+(sliderPosition+1)+')').addClass('active');

	}
	$scope.incrementSlider = function() {

		if(sliderPosition === $scope.numBanners) {
			return false;
		}
		else {
			sliderPosition = sliderPosition + 1;
			$scope.advanceSlide();
		}
	}
	$scope.decrementSlider = function() {
		
		if(sliderPosition === 0) {
			return false;
		}
		else {
			sliderPosition = sliderPosition - 1;
			$scope.advanceSlide();
		}
	}




	$scope.stopPropagation = function(e) {
		e.stopPropagation();

	}

	var sa = [];

	if($state.is('location-mobile')) {

		var locationid = $state.params.locationid;

		ResultsService.getLocation(locationid).then(function(response) {

			ResultsService.getLocationSlides(locationid).then(function(slides) {

				$scope.location = response.data;
				$scope.slides = slides.data;
				$scope.longDescription = $sce.trustAsHtml($scope.location.location.LongDescription);
				$scope.shortDescription = $sce.trustAsHtml($scope.location.location.ShortDescription);
				$scope.GeoLatitude = $scope.location.location.GeoLatitude;
				$scope.GeoLongitude = $scope.location.location.GeoLongitude;

				
				$scope.slides.thumbnails.forEach(function(v) {
					sa.push(v.filename);
				});




				var ll = { lat: $scope.GeoLatitude, lng: $scope.GeoLongitude }
				var c = new google.maps.Map(document.getElementById('dMap'), {
					zoom: 15,
					center: ll,
					styles: [
						{
							"featureType":"landscape",
							"stylers": [
								{"hue":"#FFBB00"},
								{"saturation":43.400000000000006},
								{"lightness":37.599999999999994},
								{"gamma":1}
							]
						},
						{
							"featureType":"road.highway",
							"stylers":[
								{"hue":"#FFC200"},
								{"saturation":-61.8},
								{"lightness":45.599999999999994},
								{"gamma":1}
							]
						},
						{
							"featureType":"road.arterial",
							"stylers":[
								{"hue":"#FF0300"},
								{"saturation":-100},
								{"lightness":51.19999999999999},
								{"gamma":1}
							]
						},
						{
							"featureType":"road.local",
							"stylers":[
								{"hue":"#FF0300"},
								{"saturation":-100},
								{"lightness":52},
								{"gamma":1}
							]
						},
						{
							"featureType":"water",
							"stylers":[
								{"hue":"#0078FF"},
								{"saturation":-13.200000000000003},
								{"lightness":2.4000000000000057},
								{"gamma":1}
							]
						},
						{
							"featureType":"poi",
							"elementType":"labels",
							"stylers":[
								{"visibility":"off"}
							]
						}
					],
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					disableDefaultUI: true
				});
				var marker = new google.maps.Marker({
					position: ll,
					map: c,
					icon: $scope.mapMarkerUrl,
				});

			});
			
		});

	}


	$scope.scrollToMap = function() {
		$location.hash('dMap');
		$anchorScroll.yOffset = 69;
		$anchorScroll();
	}



	var audio;


	//$scope.tourLocationIndex = 0;
	$scope.setTourLocationIndex = function($index) {
		$scope.tourLocationIndex = $index + 1;	
	}



	$scope.showTourLocationDialog = function(locationid, $event) {

		angular.element('.tourDialog').removeClass('active ');


		if(audio) {
			audio.pause();

		}
		

		angular.element('html').addClass('lock');
		angular.element('.mobileListing').css({
			'-webkit-overflow-scrolling':'touch',
			'-webkit-transform':'translateZ(0px)',
			'-webkit-transform':'translate3d(0,0,0)',
			'-webkit-perspective':1000
		});

		sliderPosition = 0;		

		angular.element('.slideWrapper').css('transform','translateX(0)');		

		$scope.tla = undefined;
		$scope.hasAudio = false;
		$scope.ml = false;
		$scope.stld = false;
		$scope.audioPaused = true; // Toggle the play/pause button
		$scope.hideTourOverview();

		angular.element('.map-left-panel .results-list ul li').removeClass('active');
		angular.element('.arrow-left').removeClass('active');

		angular.element('li[data-locationid='+locationid+']').closest('li').addClass('active');
		angular.element('li[data-locationid='+locationid+']').closest('li').find('.arrow-left').addClass('active')

		$scope.tourLocationBanners = [];
		var listid = $scope.locations[0].list_id;

		ResultsService.getTourLocation(locationid, listid).then(function(response) {
			$scope.tourLocation = response.data[0];
			console.log($scope.tourLocation);
			$scope.altDescription = $sce.trustAsHtml($scope.tourLocation.AltDescription);
			
			ResultsService.getTourMedia($scope.tourLocation.list_id, $scope.tourLocation.location_id).then(function(res) {


				var audioFile = [];
				res.data.forEach(function(v) {
					if(v.mediaTypeID == 4) {
						audioFile.push(v.filename);
						$scope.hasAudio = true;
					}					
				});
				
				if($scope.hasAudio) {
					audio = document.createElement('audio');
					audio.type = 'audio/mp3';
					$scope.activeLocation = '';

					//var audioProgress = document.getElementById('progress');

				    var playButton = document.getElementById('button');
				    var seekBar = document.getElementById('slider');
				    var progressbar = document.getElementById('progress');
				    var duration = document.getElementById('duration');
				    var playClass = 'fa-play';
				    var pauseClass = 'fa-pause';
				    var minutes, seconds, runtime, time, value;
				    progressbar.value = 0;
				    
				    function play() {
				    	audio.play();
				    	$(playButton).find('.fa').removeClass(playClass).addClass(pauseClass);
				    }
				    function pause() {
				    	audio.pause();
				    	$(playButton).find('.fa').removeClass(pauseClass).addClass(playClass);
				    }
				    playButton.addEventListener('click', function() {
				    	audio.paused ? play() : pause();
				    });
				    audio.addEventListener('loadedmetadata', function() {
				    	minutes = Math.floor(audio.duration / 60);
				    	seconds = Math.floor(audio.duration % 60);
				    	runtime = minutes+':'+seconds;
				    	duration.innerHTML = runtime;
				    	seekBar.addEventListener('change', function() {                    
				    		time = audio.duration * (seekBar.value / 100);
				    		play();
				    		audio.currentTime = time;
				    	});
				    	audio.addEventListener('timeupdate', function() {
				    		if(audio.currentTime == audio.duration) {
				    			pause();
				    			seekBar.value = 0;
				    			progressbar.value = 0;
				    		}
				    		else {
				    			value = (100 / audio.duration) * audio.currentTime;
				    			seekBar.value = parseFloat(value);
				    			progressbar.value = parseFloat(value);
				    		}
				    	});
				    	seekBar.addEventListener('mousedown', function() {
				    		pause();
				    	});
				    	seekBar.addEventListener('mouseup', function() {
				    	});
				    });
				}


				
				
				$scope.tourLocationBanners = $filter('filter')(res.data, { mediaTypeID: 2 });

				$scope.numBanners = $scope.tourLocationBanners.length -1;
				
				if($scope.numBanners > 0) {
					$scope.showArrows = true;
				}
				else {
					$scope.showArrows = false;
				}

				// Slider stuff
				var screenWidth = $window.screen.width;
				var slideCount = $scope.tourLocationBanners.length;
				var slideWrapperWidth = screenWidth * slideCount;
				$scope.screenWidth = screenWidth;
				angular.element('.slideWrapper').css('width', slideWrapperWidth+'px');

				if(audioFile.length) {
					$scope.tla = $sce.trustAsResourceUrl('/uploads/audio/'+audioFile[0]);
				}


/*				$scope.tourLocationAudio = $filter('filter')(res.data, { mediaTypeID: 4 });
				if($scope.tourLocationAudio) {
					$scope.tla = $sce.trustAsResourceUrl('/uploads/audio/'+$scope.tourLocationAudio[0].filename);
				}
*/				
				$scope.foo = true;
				$scope.resultsLoaded = true;
				$scope.contentLoaded = true;

				angular.element('.tourDialog').addClass('active ');
				
				$scope.ml = true;
				$scope.stld = true;

				$scope.currentTime = '0:00';

				audio.setAttribute('src', $scope.tla);

				var playbutton = document.getElementById('customPlayerButton');
				var progressInner = document.getElementById('progress-inner');




				angular.element('#customPlayerButton').off('click').on('click', function() {
					if(audio.paused) {
						$scope.audioPaused = false;
						audio.play();						
					}
					else {
						$scope.audioPaused = true;
						audio.pause();						
					}
				});



				audio.addEventListener('timeupdate', function() {
					var minutes = Math.floor(audio.currentTime / 60);
					var seconds = Math.floor(audio.currentTime % 60);
					if (seconds < 10) {
						seconds = '0' + String(seconds);
					}
					$scope.$apply(function() {
						$scope.currentTime = minutes + ':' + seconds;
					});

					if(audio.currentTime === audio.duration) {
						$scope.audioPaused = true;
					}

				});
				audio.addEventListener('canplaythrough', function() {
					var totalMinutes = Math.floor(audio.duration / 60);
					var totalSeconds = Math.floor(audio.duration % 60);
					$scope.$apply(function() {
						$scope.duration = totalMinutes+':'+totalSeconds;
					});
				});


				audio.addEventListener('timeupdate', function() {
					progressbar.setAttribute('value', (audio.currentTime / audio.duration));
				});
			});

		});
	}

	$scope.hideTourLocationDialog = function() {

		angular.element('html').removeClass('lock');

		angular.element('.tourDialog').removeClass('active');
		angular.element('.map-left-panel .results-list ul li').removeClass('active');
		angular.element('.arrow-left').removeClass('active');
		if(audio) {

			
			$scope.audioPaused = true;
			audio.pause();
			//audio.currentTime = 0;
			
			//audio.src = undefined;
		}
		

	}

    $scope.showLocationDialog = function(locationid, marker, $index, $event) {

    	angular.element('li').removeClass('active');
    	angular.element('li[data-locationid='+locationid+']').addClass('active');

    	angular.element('.location-information').addClass('loading');
    	angular.element('.location-meta').addClass('loading');
    	$scope.slides = [];

    	var marker = marker || undefined;
    	$scope.tips = [];
    	$scope.mapDialog = true;
    	$scope.dialogLodging = false;
    	$scope.dialogRestaurant = false;
    	$scope.dialogBarClub = false;
    	$scope.dialogAttraction = false;
    	$scope.dialogShopping = false;
    	var lid = locationid;
    	if($state.is('mynola')) {
    		$scope.liked = true;
    	}
    	else {
    		if($scope.data.likes.length > 0) {
    			var i = 0;
    			var len = $scope.data.likes.length;
    			for(; i < len; i++) {
		    		var match = $scope.data.likes[i].LocationID === lid;
		    		if(match) {
		    			$scope.liked = true;
		    			break;
		    		}
		    		else {
		    			$scope.liked = false;
		    		}
		    	}
		    }
		    else {}
		}
    	ResultsService.getLocation(lid).then(function(response) {

    		$scope.audioFile = undefined;
    		$scope.vimeoId = undefined;

    		$scope.location = response.data.location;

    		console.log($scope.location);
   		
    		

    		if($scope.location.CustomLocation) {

    			$scope.customLocationDescription = $sce.trustAsHtml($scope.location.Description);


    		}

    		if(!$scope.location.CustomLocation) {
    			$scope.neighborhood = response.data.neighborhood;
	    		$scope.majorCategory = response.data.majorCategory;
	    		$scope.majorCategoryDetails = response.data.majorCategoryDetails;
	    		$scope.minorCategories = response.data.minorCategories;
	    		$scope.minorCategoryDetails = response.data.minorCategoryDetails;
	    		$scope.hasDeal = response.data.deals.length !== 0;
	    		if($scope.hasDeal) {
	    			$scope.deals = response.data.deals;
	    		}
	    		if(response.data.tips.length) {
	    			$scope.tips = response.data.tips;
	    		}
    			$scope.majorCategoryDetails.rating = parseInt(response.data.majorCategoryDetails.rating);
    		}
    		


    		if($scope.location.LongDescription) {
   				$scope.description = $sce.trustAsHtml($scope.location.LongDescription);
    		}
    		else {
    			$scope.description = $scope.location.ShortDescription;
    		}
    		/*
    		if(response.data.location[0].Audio) {
    			$scope.audioFile = $sce.trustAsResourceUrl('/uploads/audio/'+response.data.location[0].Audio);
    		}
    		if(response.data.location[0].VimeoID) {
    			$scope.vimeoId = $sce.trustAsResourceUrl('https://player.vimeo.com/video/'+response.data.location[0].VimeoID+'?title=0&byline=0&portrait=0');
    			$scope.audioFile = $sce.trustAsResourceUrl('/uploads/audio/'+response.data.location[0].Audio);
    		}
    		*/
    		
    		ResultsService.getLocationSlides(lid, $scope.location.CustomLocation).then(function(res) {

    			console.log(res.data);

    			$scope.slides = res.data;

	    		$scope.mapDialogContent = true;
	    		angular.element('.location-information').removeClass('loading');
	    		angular.element('.location-meta').removeClass('loading');
	    	})
	    });
    }



    $scope.closeMobileLocationDialog = function() {
    	if($scope.hasAudio) {
	    	audio.pause();
	    	$scope.tla = [];

    	}
    	$scope.foo = false;
    	$scope.mobileListingActive = false;
    	$timeout(function() {
    		$scope.contentLoaded = false;
	    	$scope.dialogData = [];
	    	$scope.slides = null;
			$scope.video = null;
			$scope.banners = null;

    	}, 500)
    	$scope.unlockContent();
    }

    $scope.loadPrevLocationDialog = function(index) {

    	var $f = angular.element('.results-list ul li')[index];

    }


    $scope.scrollPosition = 0;

    $scope.showMobileLocationDialog = function(locationid, $event) {

		sliderPosition = 0;
		angular.element('.slideWrapper').css('transform','translateX(0)');
    	$scope.foo = true;
    	if($state.is('mynola')) {
    		$scope.liked = true;
    	}
    	else {
	    	if($scope.data.likes.length > 0) {
	    		var i = 0;
	    		var len = $scope.data.likes.length;
		    	for(; i < len; i++) {
		    		var match = $scope.data.likes[i].LocationID === locationid;
		    		if(match) {
		    			$scope.liked = true;
		    			break;
		    		}
		    		else {
		    			$scope.liked = false;
		    		}
		    	}
		    }
		    else {}
		}
    	ResultsService.getLocation(locationid).then(function(response) {


    		console.log(response);

    		
    		if(response.data.deals) {
    			$scope.hasDeal = response.data.deals.length !== 0;
    			$scope.deals = response.data.deals;
    		}

    		ResultsService.getLocationSlides(locationid).then(function(res) {

    			var e = res.data.hasOwnProperty('error');

	    		if(!res.data.error) {
	    			$scope.slides = res.data.thumbnails;
	    		}    		
    			



	    		$timeout(function() {
	    			$scope.ldata = response.data;

	    			
		    		$scope.dialogData = response.data.location;
		    		$scope.neighborhood = response.data.neighborhood;

		    		if($scope.dialogData.Accommodations) { $scope.locationType = 'Lodging'; }
		    		if($scope.dialogData.Restaurant) { $scope.locationType = 'Restaurant'; }
		    		if($scope.dialogData.NightlifeVenue) { $scope.locationType = 'Bar/Club'; }
		    		if($scope.dialogData.Attractions) { $scope.locationType = 'Attraction'; }
		    		if($scope.dialogData.Shopping) { $scope.locationType = 'Shopping'; }




					//var screenWidth = $window.screen.width;
					//var slideCount = $scope.slides.length;
					//var slideWrapperWidth = screenWidth * slideCount;
					//$scope.screenWidth = screenWidth;
					//angular.element('.slideWrapper').css('width', slideWrapperWidth+'px');

					//$scope.numBanners = $scope.slides.length -1;
					
/*					if($scope.numBanners > 0) {
						$scope.showArrows = true;
					}
					else {
						$scope.showArrows = false;
					}
*/		    		$scope.mapDialogContent = true;
		    		$scope.contentLoaded = true;

	    		}, 500);

    		})
    	});
    	$scope.mobileListingActive = true;
   	
    	$scope.lockContent();
    }





    $scope.closeLocationDialog = function() {

    	angular.element('li').removeClass('active');

    	$scope.activeLocation = '';
		$scope.mapDialog = false;
		$scope.mapDialogContent = false;
		$scope.liked = false;

		$scope.tipInputShowing = false;
		$scope.unlockContent();

    }

	var headerHeight = angular.element('header').outerHeight();
	var footerHeight = angular.element('footer').outerHeight();

	$('.map-container').css('height', (window.innerHeight - 175)+'px');
	$('#dMap').css('height', (window.innerHeight - 175)+'px');
	angular.element($window).bind('resize', function() {
		$timeout(function() {
			angular.element('.map-container').css('height', (window.innerHeight - 175)+'px');
			$('#dMap').css('height', (window.innerHeight - 175)+'px');
		}, 100)
	});



});
